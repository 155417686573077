<template>
    <div class="bg-zinc-850 px-2 py-1 rounded-md h-full">
      <p class="text-zinc-300 whitespace-nowrap text-sm mb-0.5">Session State</p>
      <div class="dropdown" ref="dropdown">
        <div class="dropdown-toggle" @click="toggleDropdown">
          <font-awesome-icon v-if="selectedOption.icon" :icon="['fas', selectedOption.icon]" class="h-4 mr-1 text-orange-600" />
          {{ $t(`sessionsList.${selectedOption.label}`) }}
          <font-awesome-icon :icon="['fas', 'sort-down']" class="absolute right-2 top-1.5"/>
        </div>
        <ul v-if="isOpen" class="dropdown-menu">
          <li 
            v-for="option in options" 
            :key="option.value" 
            @click="selectOption(option)"
            :class="['pl-2.5 pr-6 capitalize', {selected: option.value === modelValue }]"
            >
            <!-- <font-awesome-icon v-if="option.icon" :icon="['fas', option.icon]" class="h-4 mr-1 text-white max-w-2" /> -->
            {{ $t(`sessionsList.${option.label}`) }}
          </li>
        </ul>
      </div>
   </div>
  </template>
  
  <script>
  export default {
    name: 'FilterSessionState',
    props: {
      modelValue: {
        type: [Boolean, null],
        required: true
      }
    },
    
    data() {
      return {
        options: [
          {
            icon: 'folder-closed',
            label: 'closed',
            value: false
          },
          {
            icon: 'hourglass-half',
            label: 'open',
            value: true
          },
          {
            icon: 'bars-staggered',
            label: 'all',
            value: null
          },

        ],
        isOpen: false
      };
    },
    computed: {
      selectedOption() {
        const selected = this.options.find(opt => opt.value === this.modelValue);
        return selected ? selected : this.options[0];
      }
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      selectOption(option) {
        this.$emit("update:modelValue", option.value);
        this.isOpen = false;
      },
      handleClickOutside(event) {
        if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
          this.isOpen = false;
        }
      }
    },
    created() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener("click", this.handleClickOutside);
    }
  };
  </script>
  
  <style lang="postcss" scoped>
  .dropdown {
    /* border-top: 1px solid rgb(234,88,12);
    border-bottom: 1px solid #2d2d2d;
    border-left: 1px solid #2d2d2d;
    border-right: 1px solid #2d2d2d; */
    border: 1px solid #2d2d2d;
    @apply w-32 relative h-9 roboto rounded-md;
  }
  
  .dropdown-toggle {
    @apply cursor-pointer h-full bg-[#18181b] capitalize flex items-center gap-x-1 pr-6 pl-2.5 pt-0.5 rounded-md;
  }
  
  .dropdown-menu {
    position: absolute;
    width: 100%;
    border: 1px solid #2d2d2d;
    /* margin-top: 5px; */
    /* list-style: none; */
    /* padding: 0; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    @apply  bg-[#18181b] z-[1];
  }
  
  .dropdown-menu li {
    /* padding: 10px; */
    cursor: pointer;
  }
  
  .dropdown-menu li:hover,
  .dropdown-menu li.selected {
    @apply  bg-zinc-800;
  }
  </style>