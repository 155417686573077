<template>
  <div v-if="ready" class="w-full h-full bg-zinc-800 rounded-b-md flex flex-col gap-2 p-2">
    <div class="flex flex-col md:flex-row justify-between items-center relative py-2 px-2 bg-zinc-950 rounded-md">
      <div class="flex items-center justify-start gap-3 w-full">
        <h4 class="text-white fugaz uppercase pl-1.5">{{ $t('manageGames.game') }}</h4>
        <div class="w-full md:w-[250px] max-w-full border-zinc-800 rounded-sm">
          <Multiselect
            mode="single"
            v-model="selectedGameId"
            :options="allGames"
            :close-on-select="true"
            :searchable="true"
            label="name"
            value-prop="id"
            :placeholder="$t('manageGames.selectGame')"
            ref="multiselect"
          />
        </div>
      </div>
      <div v-show="hasChanges" class="w-full flex justify-center md:justify-end pt-2.5 md:pt-0">
        <button v-if="!saving" @click="saveChanges" class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-opacity-50 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50">
          {{ $t('manageGames.saveChanges') }}
        </button>
        <div v-else class="text-white fugaz uppercase px-3">
          {{ $t('manageGames.saving') }}
        </div>
      </div>
    </div>
    <div v-if="selectedGameId" class="flex flex-col xl:flex-row gap-1.5 xl:gap-0 items-center justify-between h-full w-full">
      <div 
        class="h-full w-full flex flex-col items-center rounded-md xl:rounded-none xl:rounded-l-md bg-zinc-950 pb-3">
        <div class="w-full flex justify-center">
          <h3 class="text-white fugaz uppercase py-2 xl:pb-1">{{ $t('manageGames.notVisible') }}</h3>\
        </div>
        <div v-if="notVisibleIntegrations.length > 0" class="flex flex-col gap-1.5 text-white px-2.5 h-full w-full max-h-[585px] overflow-y-auto">
          <div v-for="integration in notVisibleIntegrations" class="py-2 px-4 bg-zinc-800 fugaz uppercase cursor-default text-center relative">
            <button @click="addIntegration(integration)" class="group px-4 py-1 rounded-sm bg-green-600 xl:hover:bg-green-500 absolute top-0 bottom-0 my-auto right-0 uppercase">
              <font-awesome-icon :icon="['fas', 'right-long']" class="hidden xl:block" />
              <font-awesome-icon :icon="['fas', 'down-long']" class="block xl:hidden" />
            </button>
            {{ integration.name }}
          </div>
        </div>
        <div v-if="notVisibleIntegrations.length === 0 && selectedGameId !== null" class="text-white">
          {{ $t('manageGames.empty') }}
        </div>
      </div>
      <div class="h-full w-full flex flex-col items-center bg-zinc-950 rounded-md xl:rounded-none pb-3 xl:border-x border-zinc-800">
        <div class="w-full flex justify-center">
          <h3 class="text-white fugaz uppercase py-2 xl:pb-1">{{ $t('manageGames.viewing') }}</h3>
        </div>
        <div v-if="visibleIntegrations.length > 0" class="flex flex-col gap-1.5 text-white px-2.5 h-full w-full max-h-[585px] overflow-y-auto">
          <div v-for="integration in visibleIntegrations" class="py-2 px-4 bg-zinc-800 fugaz uppercase cursor-default text-center relative">
            <button @click="removeIntegration(integration)" class="group px-4 py-1 rounded-sm bg-red-600 xl:hover:bg-red-500 absolute top-0 bottom-0 my-auto right-0 xl:right-auto xl:left-0 uppercase">
              <font-awesome-icon :icon="['fas', 'left-long']" class="hidden xl:block" />
              <font-awesome-icon :icon="['fas', 'up-long']" class="block xl:hidden" />
            </button>
            <span v-if="integration.toAdd" class="text-green-500 absolute top-0 left-1 xl:left-auto right-auto xl:right-1 text-xs">
              {{ $t('manageGames.new') }}
            </span>
            <span v-if="integration.toAdd" class="text-white flex items-center gap-1 absolute bottom-0 left-1 xl:left-auto xl:right-1 text-xs">
              {{ $t('manageGames.notify') }}
              <button 
                @click="addIntegrationToNotify(integration.id)" 
                class="fugaz uppercase"
                :class="integrationsToNotify.includes(integration.id) ? 'text-green-500' : 'text-zinc-500'"
              >
                {{ $t('manageGames.yes') }}
              </button>
              <button 
                @click="removeIntegrationToNotify(integration.id)" 
                class="fugaz uppercase"
                :class="!integrationsToNotify.includes(integration.id) ? 'text-red-500' : 'text-zinc-500'"
              >
                {{ $t('manageGames.no') }}
              </button>
            </span>
            {{ integration.name }}
          </div>
        </div>
        <div v-if="visibleIntegrations.length === 0 && selectedGameId !== null" class="text-white">
          {{ $t('manageGames.empty') }}
        </div>
      </div>
      <div class="h-full w-full flex flex-col items-center rounded-r-md bg-zinc-950 pb-3">
        <div class="w-full flex justify-center">
          <h3 class="text-white fugaz uppercase py-2 xl:pb-1">{{ $t('manageGames.owned') }}</h3>
          <!-- <span class="text-sm fugaz uppercase text-white">(active)</span> -->
        </div>
        <div v-if="activeIntegrations.length > 0" class="flex flex-col gap-1.5 text-white px-2.5 h-full w-full max-h-[585px] overflow-y-auto">
          <div v-for="integration in activeIntegrations" class="py-2 px-4 bg-zinc-800 fugaz uppercase cursor-default text-center">
            {{ integration.name }}
          </div>
        </div>
        <div v-if="activeIntegrations.length === 0 && selectedGameId !== null" class="text-white">
          {{ $t('manageGames.empty') }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="w-full h-full flex justify-center items-center gap-3 text-zinc-600 dark:text-zinc-400"
  >
    <img v-if="!serverError" src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-16 w-auto animate-pulse">
    <img
      v-if="!serverError"
      src="../../assets/loading2.png"
      alt="loading icon"
      class="h-12 animate-spin mb-2"
    />
    <div v-else>
      {{ $t('serverError') }}
      <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
      {{ $t('tryAgain') }}
    </div>
  </div>
</template>
<script>
  import request from '@/services/axios'
  import Multiselect from '@vueform/multiselect'

  export default {
    data() {
      return {
        selectedGameId: null,
        selectedGame: null,
        notVisibleIntegrations: [],
        visibleIntegrations: [],
        activeIntegrations: [],
        integrationsToAdd: [],
        integrationsToRemove: [],
        integrationsToNotify: [],
        ready: false,
        serverError: false,
        saving: false,
      }
    },
    props: {
      allGames: Array,
      allIntegrations: Array,
      sortByName: Function,
    },
    components: {
      Multiselect,
    },
    async mounted() {
      this.ready = true
    },
    watch: {
      selectedGameId: {
        async handler() {
          this.updateIntegrations()
          this.$refs.multiselect.blur()
        },
      },
    },
    computed: {
      hasChanges() {
        return this.integrationsToAdd.length > 0 || this.integrationsToRemove.length > 0
      },
    },
    methods: {
      addIntegrationToNotify(platId) {
        this.integrationsToNotify.push(platId)
      },
      removeIntegrationToNotify(platId) {
        this.integrationsToNotify = this.integrationsToNotify.filter(id => id !== platId)
      },
      addIntegration(integration) {
        if(integration.toRemove) {
          const index = this.integrationsToRemove.indexOf(integration.id)
          this.integrationsToRemove.splice(index, 1)
          this.visibleIntegrations.push(integration)
        } else {
          this.integrationsToAdd.push(integration.id)
          this.visibleIntegrations.push({
            ...integration,
            toAdd: true
          })
        }
        const index = this.notVisibleIntegrations.map(g => g.id).indexOf(integration.id)
        this.notVisibleIntegrations.splice(index, 1)
        
        this.notVisibleIntegrations.sort(this.sortByName)
        this.visibleIntegrations.sort(this.sortByName)
      },
      removeIntegration(integration) {
        if(integration.toAdd) {
          const index = this.integrationsToAdd.indexOf(integration.id)
          this.integrationsToAdd.splice(index, 1)
          this.notVisibleIntegrations.push(integration)
        } else {
          this.integrationsToRemove.push(integration.id)
          this.notVisibleIntegrations.push({
            ...integration,
            toRemove: true
          })
        }
        const index = this.visibleIntegrations.map(g => g.id).indexOf(integration.id)
        this.visibleIntegrations.splice(index, 1)
        this.notVisibleIntegrations.sort(this.sortByName)
        this.visibleIntegrations.sort(this.sortByName)
      },
      resetInfo() {
        this.selectedGame = null
        this.integrationsToNotify = []
        this.activeIntegrations = []
        this.visibleIntegrations = []
        this.notVisibleIntegrations = []
        this.integrationsToAdd = []
        this.integrationsToRemove = []
      },
      updateIntegrations() {
        if (this.selectedGameId) {
          this.resetInfo()
          this.selectedGame = this.allGames.find(g => g.id == this.selectedGameId)
          this.activeIntegrations = this.selectedGame.integrations.filter(integration => integration.integration_games.is_active !== null)
          this.visibleIntegrations = this.selectedGame.integrations.filter(integration => integration.integration_games.is_active === null)
          this.notVisibleIntegrations = this.allIntegrations.filter(integration => {
            const activeIds = this.activeIntegrations.map(plat => plat.id)
            const visibleIds = this.visibleIntegrations.map(plat => plat.id)
            if (!activeIds.includes(integration.id) && !visibleIds.includes(integration.id)) return true
            else return false
          })
          this.notVisibleIntegrations.sort(this.sortByName)
          this.visibleIntegrations.sort(this.sortByName)
        } else {
          this.resetInfo()
        }
      },
      async sendGameEmails() {
        try {
          const response = await request.post(`/notify-game-integrations/${this.selectedGameId}`, { integrationIds: this.integrationsToNotify })
          this.$toast.success(response.data)
        } catch (e) {
          console.log(e)
          this.$toast.error("Error sending emails")
        }
      },
      async saveChanges() {
        this.saving = true
        try {
          const url = this.selectedGame.providerId
            ? `/games/update/${this.selectedGameId}?providerName=gamestorage`
            : `/games/update/${this.selectedGameId}`;
            
          await request.put(`/games/update/${this.selectedGameId}?providerName=gamestorage`, {
            ...this.selectedGame,
            integrationsToAdd: this.integrationsToAdd,
            integrationsToRemove: this.integrationsToRemove,
          })
          const updatedGameWithIntegrations = (await request.get(`/games/${this.selectedGameId}?with=integrations`)).data
          this.selectedGame = updatedGameWithIntegrations
          const index = this.allGames.map(p => p.id).indexOf(this.selectedGame.id)
          this.allGames.splice(index, 1, this.selectedGame)
          if (this.integrationsToNotify.length > 0) {
            console.log('sending emails')
            await this.sendGameEmails()
          }
          this.updateIntegrations()
          // this.games
          this.$toast.success(`${this.selectedGame.name} - ${this.$t('manageGames.integrationsUpdated')}`)
          this.emitter.emit('gameIntegrationsUpdated');
        } catch (e) {
          console.log(e)
          this.$toast.error(e.message)
        }
        this.saving = false
      },

    },
  }

</script>
<style scoped>

</style>