<template>
  <div class="text-white">
    <div class="flex justify-between pb-4">
      <h2 class="title">
        {{ $t("sessions.title") }}
      </h2>
      <div
        v-if="ready"
        class="flex items-center gap-4 text-lg"
      >
        <button
          @click="searching = true"
          class="py-1.5 text-white xl:hover:text-orange-600 fugaz uppercase flex items-center transition-all duration-150"
        >
          <font-awesome-icon
            :icon="['fas', 'magnifying-glass']"
            class="text-orange-600 pr-1 pb-0.5"
          />
          <span class="hidden md:block pl-1.5">{{
            $t("sessions.search")
          }}</span>
        </button>
        <router-link
          :to="{ name: 'Sessions List' }"
          @click="goToSessionsList()"
          class="py-1.5 text-white xl:hover:text-orange-600 fugaz uppercase flex items-center transition-all duration-150"
        >
          <font-awesome-icon
            class="text-orange-600 pr-2 pb-0.5"
            :icon="['fas', 'bars']"
          />
          <span class="hidden md:block">{{ $t("sessions.sessionsList") }}</span>
        </router-link>
      </div>
    </div>
    <div v-if="ready" class="">
      <div class="flex justify-between">
        <div class="flex gap-x-2 items-end">  
          <FilterDateRange />
          <FilterSessionType v-model="sessionsType"/>
          <button
            @click="getAllSessions"
            :disabled="loading"
            class="hidden md:block px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50 h-full"
          >
            {{ $t("sessions.submit") }}
          </button>
        </div>
        <div
          v-if="loading"
          class="hidden md:flex items-center justify-center pb-1.5 md:pb-0"
        >
          <img
            src="../../assets/loading2.png"
            alt="loading icon"
            class="h-6 md:h-7 text-zinc-200 animate-spin"
          />
        </div>
        <!-- <div v-else class="">{{ actualSessions.length }} sessions found</div> -->
        <div class="hidden xl:flex items-center gap-x-2">
          <!-- <button
            v-if="!fetchingSessions"
            @click="openFetch=false"
            class="hidden md:block px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
            :disabled="fetchingSessions"
          >
            Cancel
          </button> -->
          <div
            v-if="ready && userRoles.isLudusAdmin && !fetchingSessions"
            class="w-max"
            :class="appLanguage.long == 'en-GB' ? 'min-w-[330px]' : 'min-w-[440px]'"
          >
            <Multiselect
              mode="multiple"
              v-model="integrationsToFetch"
              :options="integrationsSortedByName"
              label="name"
              value-prop="id"
              :placeholder="$t('sessions.fetchPlaceholder')"
              :hide-selected="false"
              :caret="false"
              :close-on-select="false"
              :searchable="true"
              :multiple-label="
                (values) => `${values.length} Integrations selected`
              "
            />
          </div>
          <button
            v-if="ready && userRoles.isLudusAdmin && !fetchingSessions"
            @click="toggleFetch"
            class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
            :disabled="fetchingSessions"
          >
            {{ $t("sessions.fetch") }}
          </button>
          <div
            v-if="fetchingSessions"
            class="flex items-center gap-2 fugaz uppercase font-thin text-zinc-200 pl-3"
          >
            {{ $t("sessions.fetching") }}
            <img
              src="../../assets/loading2.png"
              alt="loading icon"
              class="text-zinc-600 dark:text-zinc-400 h-6 animate-spin"
            />
          </div>
        </div>
      </div>
      <!-- mobile submit -->
      <div class="flex md:hidden items-center justify-center gap-1 w-full">
        <button
          @click="getAllSessions"
          :disabled="loading"
          class="px-3 pt-1 pb-[3px] text-orange-600 bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
        >
          {{ $t("sessions.submit") }}
        </button>
        <div v-if="loading" class="flex items-center justify-center">
          <img
            src="../../assets/loading2.png"
            alt="loading icon"
            class="h-6 md:h-7 text-zinc-200 animate-spin"
          />
        </div>
      </div> 
      <div class="flex flex-col gap-1 pb-4 mt-2">
        <div
          class="w-full flex flex-col xl:flex-row items-start xl:items-end justify-between text-white pb-0 xl:pb-2"
        >
          <div class="flex items-start justify-start flex-wrap gap-2.5">
            <div
              class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
            >
              <span class="text-sm">{{ $t("sessions.all") }}</span>
              <span class="text-white text-xl">{{ sessionsCount }}</span>
            </div>
            <div
              class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
            >
              <span class="text-sm">{{ $t("sessions.open") }}</span>
              <span class="text-cyan-500 text-xl">{{ openSessions }}</span>
            </div>
            <div
              class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
            >
              <span class="text-sm">{{ $t("sessions.closed") }}</span>
              <span class="text-green-500 text-xl">{{closedSessions }}</span>
            </div>
            <!-- bet single -->
            <div
              v-if="
                (allBetFun === null && allBetReal === null) ||
                (allBetFun !== null && allBetReal === null) ||
                (allBetFun === null && allBetReal !== null)
              "
              class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
            >
              <span class="text-sm">Bet</span>
              <span
                v-if="allBetFun === null && allBetReal === null"
                class="text-xl text-white"
              >
                -
              </span>
              <span
                v-if="allBetFun !== null && allBetReal === null"
                class="text-xl text-white"
              >
                {{ getCurrencyValue(allBetFun) }}
              </span>
              <span
                v-if="allBetFun === null && allBetReal !== null"
                class="text-xl text-white"
              >
                {{ getCurrencyValue(allBetReal) }}
              </span>
            </div>
            <!-- win single -->
            <div
              v-if="
                (allWinFun === null && allWinReal === null) ||
                (allWinFun !== null && allWinReal === null) ||
                (allWinFun === null && allWinReal !== null)
              "
              class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
            >
              <span class="text-sm">Win</span>
              <span
                v-if="allWinFun === null && allWinReal === null"
                class="text-xl text-white"
              >
                -
              </span>
              <span
                v-if="allWinFun !== null && allWinReal === null"
                class="text-xl text-white"
              >
                {{ getCurrencyValue(allWinFun) }}
              </span>
              <span
                v-if="allWinFun === null && allWinReal !== null"
                class="text-xl text-white"
              >
                {{ getCurrencyValue(allWinReal) }}
              </span>
            </div>
            <!-- gross win single -->
            <div
              v-if="
                (grossWinFun === null && grossWinReal === null) ||
                (grossWinFun !== null && grossWinReal === null) ||
                (grossWinFun === null && grossWinReal !== null)
              "
              class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
            >
              <span class="text-sm">Gross win</span>
              <div class="text-xl">
                <span v-if="grossWinFun === null && grossWinReal === null">
                  -
                </span>
                <span
                  v-if="grossWinFun !== null && grossWinReal === null"
                  :class="grossWinFun > 0 ? 'text-green-500' : 'text-red-500'"
                >
                  {{ getCurrencyValue(grossWinFun) }}
                </span>
                <span
                  v-if="grossWinFun === null && grossWinReal !== null"
                  :class="
                    grossWinReal > 0 ? 'text-green-500' : 'text-red-500'
                  "
                >
                  {{ getCurrencyValue(grossWinReal) }}
                </span>
              </div>
            </div>
            <div
              v-if="allBetFun !== null && allBetReal !== null"
              class="flex flex-col px-1.5 pt-1 pb-1.5 bg-zinc-800 rounded-md"
            >
              <div class="flex items-center gap-1.5">
                <span class="fugaz uppercase pl-1">{{
                  $t("sessions.fun")
                }}</span>
                <font-awesome-icon
                  :icon="['fas', 'gift']"
                  class="h-3.5 pb-0.5"
                />
              </div>
              <div class="flex items-center gap-1.5 rounded-md">
                <div
                  class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                >
                  <span class="text-sm">Bet</span>
                  <span class="text-xl text-white">
                    {{ getCurrencyValue(allBetFun) }}
                  </span>
                </div>
                <div
                  class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                >
                  <span class="text-sm">Win</span>
                  <span class="text-xl text-white">
                    {{ getCurrencyValue(allBetFun) }}
                  </span>
                </div>
                <div
                  class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                >
                  <span class="text-sm">Gross Win</span>
                  <span
                    class="text-xl"
                    :class="
                      grossWinFun > 0 ? 'text-green-500' : 'text-red-500'
                    "
                  >
                    {{ getCurrencyValue(grossWinFun) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="allBetFun !== null && allBetReal !== null"
              class="flex flex-col px-1.5 pt-1 pb-1.5 bg-zinc-800 rounded-md"
            >
              <div class="flex items-center gap-1.5">
                <span class="fugaz uppercase pl-1">{{
                  $t("sessions.real")
                }}</span>
                <font-awesome-icon
                  :icon="['fas', 'euro-sign']"
                  class="h-4 pb-0.5"
                />
              </div>
              <div class="flex items-center gap-1.5 rounded-md">
                <div
                  class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                >
                  <span class="text-sm">Bet</span>
                  <span class="text-xl text-white">
                    {{ getCurrencyValue(allBetReal) }}
                  </span>
                </div>
                <div
                  class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                >
                  <span class="text-sm">Win</span>
                  <span class="text-xl text-white">
                    {{ getCurrencyValue(allBetReal) }}
                  </span>
                </div>
                <div
                  class="bg-zinc-700 rounded-md fugaz uppercase flex flex-col pt-1 px-2"
                >
                  <span class="text-sm">Gross Win</span>
                  <span
                    class="text-xl"
                    :class="
                      grossWinReal > 0 ? 'text-green-500' : 'text-red-500'
                    "
                  >
                    {{ getCurrencyValue(grossWinReal) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="bg-zinc-800 pb-1 pt-1.5 px-2 rounded-md fugaz uppercase flex flex-col"
            >
              <span class="text-sm">{{ $t("sessions.players") }}</span>
              <span class="text-white text-xl">{{ playersCount }}</span>
            </div>
          </div>
          <div
            class="flex items-center justify-start xl:justify-center gap-2 fugaz w-full xl:w-max pt-3 xl:pt-0"
          >
            <button
              @click="displayType = 'integrations'"
              class="px-1 uppercase xl:hover:text-orange-600 transition-all duration-100"
              :class="
                displayType === 'integrations' ? 'text-orange-600' : 'text-white'
              "
            >
              {{ integrationOperators.length > 0 ? $t('sessions.operators') : $t('sessions.integrations') }}
            </button>
            <button
              @click="displayType = 'games'"
              class="px-1 uppercase xl:hover:text-orange-600 transition-all duration-100"
              :class="
                displayType === 'games' ? 'text-orange-600' : 'text-white'
              "
            >
              {{ $t("sessions.games") }}
            </button>
          </div>
        </div>
        <BaseTable  
          v-show="displayType === 'integrations'"
          :cols="8"
          :header="[
            {text: integrationOperators.length > 0 ? $t('sessions.operators') : $t('sessions.integrations'), colSpan: 4}, 
            {text: 'Bet', hideInMobile: true, style: 'justify-center',  sortUp: 'betInverse', sortDown: 'bet'},
            {text: 'Win', hideInMobile: true, style: 'justify-center',  sortUp: 'winInverse', sortDown: 'win'},
            {text: 'Gross Win', style: `justify-center`,  sortUp: 'grossWinInverse', sortDown: 'grossWin'},
            {text: $t('sessions.title'), style: 'justify-center',  sortUp: 'sessionsCountInverse', sortDown: 'sessionsCount'},
            ]"
          :tableSort="sortIntegrationsBy"
          :body="allIntegrations"
          :rowCells="[{key: 'name', colSpan: 4}, {key: 'bet', type: 'currency', style: 'text-center'}, {key: 'win', type: 'currency', style: 'text-center'},{key: 'grossWin', type: 'currency', style: 'text-center', isPAndL: true},{key: 'sessionsCount', style: 'text-center'}]"
          :isRowSelectable="true"
          @row-selected="goToSessionsList"
          @sort-table="setIntegrationsSorting"
        />
        <BaseTable  
        v-show="displayType === 'games'"
        :cols="10"
        :header="[
          {text: $t('sessions.game'), colSpan: 5, style: 'text-center'}, 
          {text: 'Bet', hideInMobile: true, style: 'justify-center', sortUp: 'betInverse', sortDown: 'bet'},
          {text: 'Win', hideInMobile: true, style: 'justify-center', sortUp: 'winInverse', sortDown: 'win'},
          {text: 'Gross Win', style: 'justify-center', sortUp: 'grossWinInverse', sortDown: 'grossWin'},
          {text: 'Rounds', style: 'justify-center', sortUp: 'roundsCountInverse', sortDown: 'roundsCount'},
          {text: $t('sessions.title'), style: 'justify-center', sortUp: 'sessionsCountInverse', sortDown: 'sessionsCount'},
          ]"
        :rowSlot="true"
        @sort-table="setGamesSorting"

        >
        <!-- :tableSort="sortIntegrationsBy" -->
        <template #body>
          <tr v-for="(game, index) in games"
            :key="game.id"
            @click="goToSessionsList(game, 'game')"
            class="cursor-pointer text-white hover:brightness-125 px-6 py-5 text-lg"
            :class="['grid', `grid-cols-${10}`, {'bg-zinc-850': index % 2 === 0, 'bg-zinc-900': index % 2 !== 0}]"
            :style="
              index % 2 === 0
                ? getMiniBannerBackground(game, 'gray')
                : getMiniBannerBackground(game, 'black')
            ">
            <td class="col-span-5 text-right pr-[2.25rem] cursor-pointer whitespace-nowrap font-medium" style="text-shadow: #000 0px 0 5px">
              {{
                game.sub_name
                  ? `${game.name} - ${game.sub_name}`
                  : game.name
              }}
            </td>
            <td class="hidden md:block text-center content-center font-semibold">
              {{ typeof game.bet === 'number' ? getCurrencyValue(game.bet) : game.bet }}
            </td>
            <td class="hidden md:block content-center text-center font-semibold">
              {{ typeof game.bet === 'number' ? getCurrencyValue(game.win) : game.win }}
            </td>
            <!-- whitespace-nowrap -->
            <td class="content-center text-center font-semibold" :class="{'text-green-500': typeof game.grossWin === 'number' && game.grossWin >= 0, 'text-red-500': typeof game.grossWin === 'number' && game.grossWin < 0}">
              {{ typeof game.grossWin === 'number' ? getCurrencyValue(game.grossWin) : game.grossWin }}
            </td>
            <td class="content-center text-center font-semibold">
              {{ game.roundsCount }}
            </td>
            <td class="content-center text-center font-semibold">
              {{ game.sessionsCount }}
            </td>
          </tr>
        </template>
        </BaseTable>
        <div class="w-full flex items-start justify-between gap-2 rounded-sm text-white">
            <!-- <div v-show="displayType === 'integrations'" class="w-full">
              <table
                class="table table-fixed w-full border-separate border-spacing-y-1"
              >
                <thead
                  class="bg-zinc-800 py-3 pl-4 rounded-sm text-lg fugaz uppercase sticky top-0 z-20 shadow-sm"
                >
                  <tr>
                    <th
                      class="table-cell py-2 xl:py-3 pl-3 fugaz uppercase text-left"
                    >
                      {{
                        integrationOperators.length > 0
                          ? $t("sessions.operators")
                          : $t("sessions.integrations")
                      }}
                    </th>
                    <th
                      @click="setIntegrationsSorting('bet')"
                      class="hidden xl:table-cell text-center truncate fugaz uppercase cursor-pointer w-[130px]"
                      <font-awesome-icon
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                      Bet
                      <font-awesome-icon
                        v-if="sortIntegrationsBy == 'bet'"
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="sortIntegrationsBy == 'betInverse'"
                        :icon="['fas', 'sort-up']"
                        class="pt-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="
                          sortIntegrationsBy !== 'bet' &&
                          sortIntegrationsBy !== 'betInverse'
                        "
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                    </th>
                    <th
                      @click="setIntegrationsSorting('win')"
                      class="hidden xl:table-cell text-center truncate fugaz uppercase cursor-pointer w-[130px]"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                      Win
                      <font-awesome-icon
                        v-if="sortIntegrationsBy == 'win'"
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="sortIntegrationsBy == 'winInverse'"
                        :icon="['fas', 'sort-up']"
                        class="pt-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="
                          sortIntegrationsBy !== 'win' &&
                          sortIntegrationsBy !== 'winInverse'
                        "
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                    </th>
                    <th
                      @click="setIntegrationsSorting('grossWin')"
                      class="hidden xl:table-cell text-center truncate fugaz uppercase cursor-pointer w-[160px]"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                      Gross Win
                      <font-awesome-icon
                        v-if="sortIntegrationsBy == 'grossWin'"
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="sortIntegrationsBy == 'grossWinInverse'"
                        :icon="['fas', 'sort-up']"
                        class="pt-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="
                          sortIntegrationsBy !== 'grossWin' &&
                          sortIntegrationsBy !== 'grossWinInverse'
                        "
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                    </th>
                    <th
                      @click="setIntegrationsSorting('sessionsCount')"
                      class="hidden md:table-cell text-center truncate fugaz uppercase cursor-pointer w-[150px]"
                    >
                      <font-awesome-icon
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                      {{ $t("sessions.title") }}
                      <font-awesome-icon
                        v-if="sortIntegrationsBy == 'sessionsCount'"
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="sortIntegrationsBy == 'sessionsCountInverse'"
                        :icon="['fas', 'sort-up']"
                        class="pt-0.5 px-1.5"
                      />
                      <font-awesome-icon
                        v-if="
                          sortIntegrationsBy !== 'sessionsCount' &&
                          sortIntegrationsBy !== 'sessionsCountInverse'
                        "
                        :icon="['fas', 'sort-down']"
                        class="pb-0.5 px-1.5 invisible"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody
                  v-if="Object.keys(user.attributes).length > 0"
                  class="z-10"
                >
                  <tr
                    v-for="(operator, index) in integrationOperators"
                    :key="operator.id"
                    @click="goToSessionsList(operator, 'operator')"
                    class="py-3 pl-4 fugaz uppercase cursor-pointer xl:hover:brightness-105 text-zinc-200 xl:hover:text-white"
                    :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'"
                  >
                    <td class="table-cell py-2.5 pl-3 fugaz uppercase">
                      {{ operator.name }}
                    </td>
                    <td class="hidden xl:table-cell text-center">
                      {{
                        sessionsCount > 0 ? getCurrencyValue(operator.bet) : ""
                      }}
                    </td>
                    <td class="hidden xl:table-cell text-center">
                      {{
                        sessionsCount > 0 ? getCurrencyValue(operator.win) : ""
                      }}
                    </td>
                    <td
                      class="hidden xl:table-cell text-center"
                      :class="
                        operator.grossWin >= 0
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                    >
                      {{
                        sessionsCount > 0
                          ? getCurrencyValue(operator.grossWin)
                          : ""
                      }}
                    </td>
                    <td class="hidden md:table-cell text-center">
                      {{ sessionsCount > 0 ? operator.sessionsCount : "" }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else class="<-10">
                  <tr
                    v-for="(integration, index) in allIntegrations"
                    @click="goToSessionsList(integration, 'integration')"
                    :key="integration.id"
                    class="py-2 pl-4 fugaz uppercase cursor-pointer xl:hover:brightness-105 text-zinc-200 xl:hover:text-white"
                    :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'"
                  >
                    <td class="table-cell py-2.5 pl-3 fugaz uppercase">
                      {{ integration.name }}
                    </td>
                    <td class="hidden xl:table-cell text-center">
                      {{
                        sessionsCount > 0 ? getCurrencyValue(integration.bet) : ""
                      }}
                    </td>
                    <td class="hidden xl:table-cell text-center">
                      {{
                        sessionsCount > 0 ? getCurrencyValue(integration.win) : ""
                      }}
                    </td>
                    <td
                      class="hidden xl:table-cell text-center"
                      :class="
                        integration.grossWin >= 0
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                    >
                      {{
                        sessionsCount > 0
                          ? getCurrencyValue(integration.grossWin)
                          : ""
                      }}
                    </td>
                    <td class="hidden md:table-cell text-center">
                      {{ sessionsCount > 0 ? integration.sessionsCount : "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->
            <!-- <div
              v-show="displayType === 'games'"
              class="flex flex-col gap-1 w-full h-full"
            >
              <table
                class="table table-auto w-full border-separate border-spacing-y-1"
              >
                <thead
                  class="bg-zinc-800 py-1.5 rounded-sm text-lg fugaz uppercase sticky top-0 z-20 shadow-sm"
                >
                  <tr>
                    <th class="table-cell pl-4 py-2 xl:py-3 fugaz uppercase">
                      {{ $t("sessions.game") }}
                    </th>
                    <th
                      @click="setGamesSorting('bet')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden xl:table-cell w-[130px]"
                    >
                      <div class="flex items-center justify-center">
                        
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                        Bet
                        <font-awesome-icon
                          v-if="sortGamesBy == 'bet'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'betInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 pl-2"
                        />
                        
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'bet' &&
                            sortGamesBy !== 'betInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                      </div>
                    </th>
                    <th
                      @click="setGamesSorting('win')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden xl:table-cell w-[130px]"
                    >
                      <div class="flex items-center justify-center">
                        
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                        Win
                        <font-awesome-icon
                          v-if="sortGamesBy == 'win'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'winInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 pl-2"
                        />
                        
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'win' &&
                            sortGamesBy !== 'winInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                      </div>
                    </th>
                    <th
                      @click="setGamesSorting('grossWin')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden xl:table-cell w-[130px]"
                    >
                      <div class="flex items-center justify-center">
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                        Gross win
                        <font-awesome-icon
                          v-if="sortGamesBy == 'grossWin'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'grossWinInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 pl-2"
                        />
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'grossWin' &&
                            sortGamesBy !== 'grossWinInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                      </div>
                    </th>
                    <th
                      @click="setGamesSorting('roundsCount')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden xl:table-cell w-[130px]"
                    >
                      <div class="flex items-center justify-center">
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                        {{ $t("sessions.rounds") }}
                        <font-awesome-icon
                          v-if="sortGamesBy == 'roundsCount'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'roundsCountInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 pl-2"
                        />
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'roundsCount' &&
                            sortGamesBy !== 'roundsCountInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 pl-2 invisible"
                        />
                      </div>
                    </th>
                    <th
                      @click="setGamesSorting('sessionsCount')"
                      class="text-center truncate fugaz uppercase cursor-pointer hidden md:table-cell w-[155px]"
                    >
                      <div class="flex items-center justify-center">
                        <font-awesome-icon
                          :icon="['fas', 'sort-down']"
                          class="pb-2 px-2 invisible"
                        />
                        {{ $t("sessions.title") }}
                        <font-awesome-icon
                          v-if="sortGamesBy == 'sessionsCount'"
                          :icon="['fas', 'sort-down']"
                          class="pb-2 px-2"
                        />
                        <font-awesome-icon
                          v-if="sortGamesBy == 'sessionsCountInverse'"
                          :icon="['fas', 'sort-up']"
                          class="pt-1 px-2"
                        />
                        <font-awesome-icon
                          v-if="
                            sortGamesBy !== 'sessionsCount' &&
                            sortGamesBy !== 'sessionsCountInverse'
                          "
                          :icon="['fas', 'sort-down']"
                          class="pb-2 px-2 invisible"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(game, index) in games"
                    :key="game.id"
                    @click="goToSessionsList(game, 'game')"
                    class="py-6 fugaz uppercase z-10 cursor-pointer xl:hover:brightness-105"
                    :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'"
                    :style="
                      index % 2 === 0
                        ? getMiniBannerBackground(game, 'gray')
                        : getMiniBannerBackground(game, 'black')
                    "
                  >
                    <td
                      class="pl-3 py-4 xl:py-6 text-lg table-cell h-full max-w-[150px] truncate text-center"
                    >
                      <span class="bg-zinc-800 bg-opacity-50 px-1.5 rounded-sm">
                        {{
                          game.sub_name
                            ? `${game.name} - ${game.sub_name}`
                            : game.name
                        }}
                      </span>
                    </td>
                    <td class="w-max hidden xl:table-cell text-center">
                      <span
                        class="bg-zinc-800 bg-opacity-25 px-1.5 rounded-sm"
                        >{{ getCurrencyValue(game.bet) }}</span
                      >
                    </td>
                    <td class="w-max hidden xl:table-cell text-center">
                      <span
                        class="bg-zinc-800 bg-opacity-25 px-1.5 rounded-sm"
                        >{{ getCurrencyValue(game.win) }}</span
                      >
                    </td>
                    <td
                      class="w-max hidden xl:table-cell text-center"
                      :class="
                        game.grossWin >= 0 ? 'text-green-500' : 'text-red-500'
                      "
                    >
                      <span
                        class="bg-zinc-800 bg-opacity-25 px-1.5 rounded-sm"
                        >{{ getCurrencyValue(game.grossWin) }}</span
                      >
                    </td>
                    <td class="w-max hidden xl:table-cell text-center">
                      <span class="px-1 rounded-sm">{{
                        game.roundsCount
                      }}</span>
                    </td>
                    <td class="w-max hidden md:table-cell text-center">
                      <span class="px-1 rounded-sm">{{
                        game.sessionsCount
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>-->
        </div>
        <Transition>
          <SearchSession
            v-if="searching"
            :userIntegration="userIntegration?.id"
            :closeSearch="closeSearch"
            :allIntegrations="allIntegrations"
          />
        </Transition>
        <Transition>
          <GamesCharts
            v-if="charts.games"
            :openOrCloseCharts="openOrCloseCharts"
            :games="allGames"
            class=""
          />
        </Transition>
      </div> 
    </div>
    <div v-else class="w-full h-[90vh] flex justify-center items-center gap-3 text-white bg-zinc-950">
      <img
        v-if="!serverError"
        src="../../assets/hub-full-logo.png"
        alt="gai hub logo"
        class="h-24 w-auto animate-pulse"
      />
      <div v-else>
        {{ $t("serverError") }}
        <button @click="$router.go()" class="text-blue-600">
          {{ $t("reload") }}
        </button>
        {{ $t("tryAgain") }}
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/services/axios";
import Session from "@/components/sessions/Session.vue";
import GamesCharts from "@/components/sessions/GamesCharts.vue";
import SearchSession from "@/components/sessions/SearchSession.vue";
import IntegrationCharts from "@/components/sessions/IntegrationCharts.vue";
import Multiselect from "@vueform/multiselect";
import { useDateFormatter } from "@/helpers/dateFormatter.js";
import BaseTable from '@/components/utils/BaseTable.vue';
import FilterSessionType from "@/components/utils/FilterSessionType.vue";
import { sessionsEnum }from "@/helpers/enums/sessions.js";
import FilterDateRange from "@/components/utils/FilterDateRange.vue";

export default {
  data() {
    return {
      isFirstRequest: true,
      ready: false,
      loading: false,
      serverError: false,
      allIntegrations: [],
      integrationOperators: [],
      allGames: [],
      searching: false,
      loadingSearch: false,
      search: "",
      userIntegration: null,
      sessionFound: false,
      sessionNotFound: false,
      sessionsCount: '-',
      openSessions: '-',
      closedSessions: '-',
      allBetFun: null,
      allBetReal: null,
      allWinFun: null,
      allWinReal: null,
      grossWinReal: null,
      grossWinFun: null,
      netWin: 0,
      sortGamesBy: "sessionsCount",
      sortIntegrationsBy: "sessionsCount",
      selectedIntegration: {},
      charts: {},
      sessionsType: "real",
      hideRealWins: false,
      displayType: "integrations",
      openFetch: false,
      integrationsToFetch: [],
      playersCount: '-',
      mountTimer: null,
    };
  },
  components: {
    Session,
    GamesCharts,
    IntegrationCharts,
    Multiselect,
    SearchSession,
    
    BaseTable,
    FilterSessionType,
    FilterDateRange
  },
  computed: {
    games() {
      if (this.allGames && !!this.allGames.length) {
        return this.allGames.filter((g) => g.sessionsCount > 0);
      } else {
        return [{name: '-', bet: '-', win: '-', grossWin: '-', roundsCount: '-', sessionsCount: '-'}]
      }
    },
    dateRange() {
      return this.$store.state.filters.date;
    },
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    integrationsSortedByName() {
      const integrations = [...this.allIntegrations];
      integrations.sort((a, b) => a.name.localeCompare(b.name));
      return integrations;
    },
    fetchingSessions() {
      return this.$store.getters["jobs/fetching"];
    },
    lastJob() {
      return this.$store.getters["jobs/lastJob"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRoles() {
      return this.user.localRoles;
    },
  },
  async mounted() {
    this.mountTimer = setTimeout(async () => {
      if (this.$store.getters["sessions/getIntegrations"].length === 0) {
        await this.getAllIntegrations();
      } else {
        this.allIntegrations = this.$store.getters["sessions/getIntegrations"];
      }
      this.ready = true;
    }, 500);
  },
  beforeUnmount() {
    clearTimeout(this.mountTimer);
  },
  methods: {
    toggleFetch() {
      this.fetchAllSessions();
    },
    goToSessionsList(relatedObject = null, type = null) {
      if (this.allIntegrations.length > 0) {
        this.$store.dispatch("sessions/setIntegrationsAction", this.allIntegrations);
      }
      this.$router.push({ name: "Sessions List" });
    },
    // changeSessionsType(type = null) {
    //   // if (type !== null) {
    //   //   this.sessionsType = type;
    //   //   return;
    //   // }
    //   if (type === "fun") {
    //     if (this.sessionsType === "fun" || this.sessionsType === 'all') {
    //       this.sessionsType = "real";
    //     } else if (this.sessionsType === "real") {
    //       this.sessionsType = "all";
    //     }
    //   } else if (type === "real") {
    //     if (this.sessionsType === "real" || this.sessionsType === "all") {
    //       this.sessionsType = "fun";
    //     } else if (this.sessionsType === "fun") {
    //       this.sessionsType = "all";
    //     }
    //   }
    // },
    async getIntegrationOperators() {
      let plat = null;
      if (Object.keys(this.user.attributes).length > 0) {
        plat = this.allIntegrations.find(
          (plat) => plat.name == this.user.attributes.integration[0]
        );
      }
      if (plat) {
        const data = (await request.get(`/integrations/${plat.id}/operators`))
          .data;
        this.allIntegrations = data;
      }
    },
    // getMiniBannerBackground(game) {
    //   const image = game.game_media.find(
    //     (image) => image.isMiniBanner === true
    //   );
    //   if(image) return `background-image: url("${image?.url}");`;
    // },
    getMiniBannerBackground(game, color) {
      let image = null;
      if (game.game_media) {
        image = game.game_media.find(
          (image) => image.isMiniBanner === true
        );
      }

      if (image) {
        if (color === "gray") {
          // rgb(29 29 31
          return `background-image: linear-gradient(to left, rgba(29, 29, 31, 1) 28%, rgba(29, 29, 31, 1) 0%, rgba(29, 29, 31, 0)), url("${image.url}");`;
        }
        if (color === "black") {
          // 24 24 27
          return `background-image: linear-gradient(to left, rgba(24, 24, 27, 1) 28%, rgba(24, 24, 27, 1) 0%, rgba(24, 24, 27, 0)), url("${image.url}");`;
        }
      } else
        return `background-image: linear-gradient(to left, rgba(24, 24, 27, 1) 28%, rgba(24, 24, 27, 1) 0%, rgba(24, 24, 27, 0)), rgba(24, 24, 27, 0));`;
    },
    openIntegrationCharts(integration) {
      this.charts.integration = true;
      this.selectedIntegration = integration;
    },
    openOrCloseCharts(type, value) {
      this.charts[type] = value;
    },
    setGamesSorting(sort, force = false) {
      if (this.sortGamesBy == sort && !force) {
        const sortInverse = `${sort}Inverse`;
        this.sortGamesBy = sortInverse;
        this.allGames.sort((a, b) => a[sort] - b[sort]);
      } else {
        this.sortGamesBy = sort;
        this.allGames.sort((a, b) => b[sort] - a[sort]);
      }
    },
    setIntegrationsSorting(sort, force = false) {
      if (this.sortIntegrationsBy == sort && !force) {
        const sortInverse = `${sort}Inverse`;
        this.sortIntegrationsBy = sortInverse;
        this.allIntegrations.sort((a, b) => a[sort] - b[sort]);
        this.integrationOperators.sort((a, b) => a[sort] - b[sort]);
      } else {
        this.sortIntegrationsBy = sort;
        this.allIntegrations.sort((a, b) => b[sort] - a[sort]);
        this.integrationOperators.sort((a, b) => b[sort] - a[sort]);
      }
    },
    resetJobProgress() {
      if (this.$route.name === "Sessions") {
        this.getAllSessions();
      }
    },
    closeSearch() {
      this.searching = false;
      this.$store.dispatch("sessions/setSessionAction", {});
    },
    getSessionEarnings(session) {
      const bet = session.bet === null ? 0 : parseFloat(session.bet);
      const win = session.win === null ? 0 : parseFloat(session.win);
      const grossWin = bet - win;
      // const italianTax = (process.env.VUE_APP_ITALIAN_TAX / 100) * grossWin
      // const final = grossWin - italianTax
      return { bet, win, grossWin };
    },
    getGameValues(game, allSessions) {
      let bet, win, grossWin;
      bet = win = grossWin = 0;
      const sessions = allSessions.filter((s) => s.game.slug == game.slug);
      for (const session of sessions) {
        bet += this.getSessionEarnings(session).bet;
        win += this.getSessionEarnings(session).win;
        grossWin += this.getSessionEarnings(session).grossWin;
      }
      // console.log(bet, win, grossWin)
      return { bet, win, grossWin };
    },
    getIntegrationValues(integration, allSessions) {
      let bet, win, grossWin;
      bet = win = grossWin = 0;
      const platSessions = allSessions.filter(
        (s) => s.integration.id == integration.id
      );
      for (const session of platSessions) {
        bet += this.getSessionEarnings(session).bet;
        win += this.getSessionEarnings(session).win;
        grossWin += this.getSessionEarnings(session).grossWin;
      }
      return { bet, win, grossWin };
    },
    getOperatorValues(operator, allSessions) {
      let bet, win, grossWin;
      bet = win = grossWin = 0;
      const operatorSessions = allSessions.filter(
        (s) => s.operatorId == operator.id
      );
      for (const session of operatorSessions) {
        bet += this.getSessionEarnings(session).bet;
        win += this.getSessionEarnings(session).win;
        grossWin += this.getSessionEarnings(session).grossWin;
      }
      return { bet, win, grossWin };
    },
    getGameSessionsCount(game, sessions) {
      return sessions.filter((s) => s.game.slug === game.slug).length;
    },
    getGameRoundsCount(game, sessions) {
      const gameSessions = sessions.filter((s) => s.game.slug === game.slug);
      let rounds = 0;
      for (const gameSession of gameSessions) {
        rounds += gameSession.currentRound;
      }
      return rounds;
    },
    getIntegrationSessionsCount(integration, sessions) {
      return sessions.filter((s) => s.integration.id === integration.id).length;
    },
    getOperatorSessionsCount(operator, sessions) {
      const count = sessions.filter((s) => s.operatorId === operator.id).length;
      return count ? count : 0;
    },
    getTodayStart() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return this.lastJob?.from || today;
    },
    getTodayEnd() {
      const today = new Date();
      today.setHours(23, 59, 59, 59);
      return this.lastJob?.to || today;
    },
    async getAllIntegrations(sessions = []) {
      try {
        if (Object.keys(this.user.attributes).length > 0) {
          const integration = (
            await request.get(
              `/integrations/byName/${this.user.attributes.integration[0]}`
            )
          ).data;
          this.userIntegration = integration;
          const data = (
            await request.get(`/integrations/${integration.id}/operators`)
          ).data;
          if (this.isFirstRequest) {
            this.integrationOperators = data.map((operator) => ({
              ...operator,
              bet: '-',
              win: '-',
              grossWin: '-',
              sessionsCount: '-',
            }));
            this.isFirstRequest = false;
          } else {
            this.integrationOperators = data.map((operator) => ({
              ...operator,
              bet: this.getOperatorValues(operator, sessions).bet,
              win: this.getOperatorValues(operator, sessions).win,
              grossWin: this.getOperatorValues(operator, sessions).grossWin,
              sessionsCount: this.getOperatorSessionsCount(operator, sessions),
            }));
          }
        } else {
          const integrations = (await request.get("/integrations?active=1")).data.rows;
          integrations.sort((a, b) =>
            // this.getIntegrationSessionsCount(b, sessions) - this.getIntegrationSessionsCount(a, sessions)
            a.name.localeCompare(b.name)
          )
          if (this.isFirstRequest) {
            this.allIntegrations = integrations.map((integration) => ({
              ...integration,
              bet: '-',
              win: '-',
              grossWin: '-',
              sessionsCount: '-',
            }));
            this.isFirstRequest = false;
          } else {
            this.allIntegrations = integrations.map((integration) => ({
              ...integration,
              bet: this.getIntegrationValues(integration, sessions).bet,
              win: this.getIntegrationValues(integration, sessions).win,
              grossWin: this.getIntegrationValues(integration, sessions).grossWin,
              sessionsCount: this.getIntegrationSessionsCount(integration, sessions),
            }));
          }
        }
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },
    async getAllGames(dataset) {
      try {
        const games = (
          await request.get("/catalog?with=game_media&mediaTypes=mini")
        ).data.rows.map((game) => ({
            ...game,
            sessionsCount: dataset.sessionsTotals.find(b=>b.gameId == game.id)?.total,
            roundsCount: dataset.rounds.find(b=>b.gameId == game.id)?.total,
            win: dataset.win.find(b=>b.gameId == game.id)?.total,
            bet: dataset.bet.find(b=>b.gameId == game.id)?.total,
            grossWin: dataset.grosswin.find(b=>b.gameId == game.id)?.total, 
            netWin: dataset.netwin.find(b=>b.gameId == game.id)?.total,
          }));

        this.allGames = games;

      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },
    async getAllSessions() {
      this.loading = true;
      //get totals
      try {
        let sessionTypeId = sessionsEnum.types.real;
        let stats = 'bet,win,grosswin,netwin,due,rounds,sessionsTotals,usersCount';

        const sqlFrom = useDateFormatter(this.dateRange.start);
        const sqlTo = useDateFormatter(this.dateRange.end);

        // if (this.sessionsType === "real" || this.sessionsType === "fun") {
        if (this.sessionsType == "real") {
          sessionTypeId = sessionsEnum.types.real;
          this.allBetFun = null;
          this.allWinFun = null;
          this.grossWinFun = null;
        } else if (this.sessionsType == "fun") {
          this.hideRealWins = true;
          sessionTypeId = sessionsEnum.types.fun;
          this.allBetReal = null;
          this.allWinReal = null;
          this.grossWinReal = null;
        } else {
          sessionTypeId =  [sessionsEnum.types.real, sessionsEnum.types.fun];
        }

        const obj = {
          from: sqlFrom,
          to: sqlTo,
          sessionTypeId: sessionTypeId,
          datasetGames: 1,
          stats,
        };
        const data = btoa(JSON.stringify(obj));
        const res = await request.get(`/bookkeeping/stats?a=${data}`);

        const totals = res.data.grandTotals;
        this.playersCount = totals.usersCount;
        this.openSessions = totals.sessionsOpen;
        this.closedSessions = totals.sessionsClosed;
        this.sessionsCount = totals.sessionsTotal;


        // TODO maybe, just maybe centralize this #$%#$"#  ... food for thought
        const integrationsRequestParams = {
          from: sqlFrom,
          to: sqlTo,
          sessionTypeId: sessionTypeId,
          datasetIntegrations: 1,
        };
        const botaParams = btoa(JSON.stringify(integrationsRequestParams));
        const integrationsData = await request.get(`/bookkeeping/stats?a=${botaParams}`);
        integrationsData?.data?.map( integration => {
          const localIntegration = this.allIntegrations.find(all => all.id == integration.integration.id);
          localIntegration.bet =integration.grandTotals.bet;
          localIntegration.win =integration.grandTotals.win;
          localIntegration.grossWin = integration.grandTotals.grosswin;
          localIntegration.sessionsCount = integration.grandTotals.sessionsTotal;
        })


        if (this.sessionsType === "real") {
          this.allBetReal = totals.bet;
          this.allWinReal = totals.win;
          this.grossWinReal = totals.grosswin;
          this.netWinReal = totals.netwin;
        } else if (this.sessionsType === "fun") {
          this.allBetFun = totals.bet;
          this.allWinFun = totals.win;
          this.grossWinFun = totals.grosswin;
          this.netWinFun = totals.net;
        } else if (this.sessionsType === "all") {
          const realObj = {
            from: sqlFrom,
            to: sqlTo,
            sessionTypeId: sessionsEnum.types.real,
            // datasetIntegrations: 1,
          };

          let data = btoa(JSON.stringify(realObj));
          let res = await request.get(`/bookkeeping/stats?a=${data}`);
          let totals = res.data.grandTotals;
          this.allBetReal = totals.bet;
          this.allWinReal = totals.win;
          this.grossWinReal = totals.grosswin;
          this.netWinReal = totals.netwin;

          const funObj = {
            from: sqlFrom,
            to: sqlTo,
            sessionTypeId: this.sessionsEnum.types.fun,
          };

          data = btoa(JSON.stringify(funObj));
          res = await request.get(`/bookkeeping/stats?a=${data}`);
          totals = res.data.grandTotals;
          this.allBetFun = totals.bet;
          this.allWinFun = totals.win;
          this.grossWinFun = totals.grosswin;
          this.netWinFun = totals.net;
        }
        //get sessions
        // const sessionsResponse = (
        //   await request.get(
        //     `/bookkeeping/sessions/range?from=${sqlFrom}&to=${sqlTo}&sessionTypeId=${sessionTypeId}&isOpen=null`
        //   )
        // ).data;
        // const sessions = sessionsResponse.rows;
      
        // await this.getAllIntegrations(sessions);
        await this.getAllGames(res?.data?.datasets?.games);

        this.setGamesSorting(this.sortGamesBy, true);        
        this.setIntegrationsSorting(this.sortIntegrationsBy, true);
        if (this.sessionsType !== "fun") this.hideRealWins = false;
      } catch (e) {
        console.log(e);
        // TODO review this DRAMATIC error handling approach
        this.serverError = true;
      }

      this.loading = false;
    },
    isIntervalMoreThan31Days(dateString1, dateString2) {
      const date1 = new Date(dateString1);
      const date2 = new Date(dateString2);

      // Calculate the difference in milliseconds
      const timeDifference = Math.abs(date2 - date1);

      // Calculate the number of days
      const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

      // Check if the interval is more than 31 days
      return daysDifference > 31;
    },
    async fetchAllSessions() {
      if (
        this.isIntervalMoreThan31Days(this.dateRange.start, this.dateRange.end)
      ) {
        this.$toast.error(
          "You can't fetch more than 31 days of sessions at the same time",
          { duration: 5000 }
        );
        return;
      }
      this.$toast.open({
        message: "Fetching sessions...",
        type: "info",
        duration: 2000,
        position: "bottom",
      });
      try {
        // this.allSessions = (await request.get('/bookkeeping/sessions')).data.rows
        const sqlFrom = new Date(this.dateRange.start)
          .toISOString()
          .slice(0, 19);
        const sqlTo = new Date(this.dateRange.end).toISOString().slice(0, 19);
        // console.log('sqlFrom: ' + sqlFrom + ' sqlTo: ' + sqlTo);
        const { jobId } = (
          await request.get(
            `/bookkeeping/sessions/fetch?dateStart=${sqlFrom}&dateEnd=${sqlTo}&socketId=${this.socket.id}&integrationIds=${this.integrationsToFetch}`
          )
        ).data;

        this.$store.dispatch("jobs/setActiveJobAction", {
          jobId,
          userId: this.user.userId,
          state: "called",
        });
        // this.$store.dispatch('jobs/setLastJobAction', {
        //   from: this.dateRange.start,
        //   to: this.dateRange.end,
        // })
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
/* tbody tr:nth-child(even) {
  background-color: rgb(63 63 70);
} */
/* tbody tr:nth-child(even) {
  background-color: rgb(34, 34, 34);
} */
</style>