<template>
  <div class="relative text-white flex flex-col gap-3 w-full h-max rounded-sm border border-zinc-600 p-3">
    <div class="flex items-center gap-3 border-zinc-600 rounded-sm">
      <v-date-picker v-model="dateRange" is-dark color="pink" :locale="appLanguage.long">
        <template #default="{ inputValue, inputEvents }">
          <input :value="inputValue" v-on="inputEvents" class="w-28 rounded-sm py-1 text-center outline-none bg-zinc-700 text-white" />
        </template>
      </v-date-picker>
      <!-- <div class="flex flex-col">
        <select name="integrations" v-model="selectedGame" class="px-1.5 py-1.5 rounded-sm outline-none text-white bg-zinc-700" style="color-scheme: dark;">
          <option value="">Filter by game</option>
          <option v-for="(game, index) in integrationGames" :key="`integration${index}`" :value="game.name">{{game.name}} ({{ getGameSessionsCount(game) }})</option>
        </select>
      </div> -->
      <button @click="getSessions" class="fugaz uppercase bg-zinc-600 xl:hover:bg-zinc-700 px-3 py-1 rounded-sm">
        submit
      </button>
      <h2 v-if="!loading && allSessions.length" class="text-white fugaz uppercase text-xl">
        {{ allSessions.length }} sessions
      </h2>
      <img v-if="loading" src="../../assets/loading2.png" alt="loading icon"
          class="text-zinc-600 dark:text-zinc-400 h-6 animate-spin"
        />
    </div>
    <div class="px-1 flex flex-col items-center gap-4">
      <div class="flex flex-wrap gap-2 border-zinc-600 rounded-sm">
        <div v-for="(game, index) in integrationGames.sort((a, b) => this.getGameSessionsCount(b) - this.getGameSessionsCount(a))" :key="`index${index}`" class="px-3 py-1 rounded-sm bg-zinc-700 capitalize">
          {{ game.sub_name ? `${game.name} - ${game.sub_name}` : game.name }} ({{ getGameSessionsCount(game) }})
        </div>
      </div>
      <button @click="$router.push({ name: 'Sessions' })" class="px-3 py-1 rounded-sm bg-zinc-700 xl:hover:bg-opacity-50 text-white fugaz uppercase">
        Manage sessions
      </button>
    </div>
  </div>
</template>

<script>
import request from "../../services/axios";

export default {
  name: "IntegrationSessions",
  data() {
    return {
      dateRange: this.getToday(),
      selectedGame: '',
      allSessions: [],
      loading: false,
    };
  },
  props: {
    integration: Object,
    integrationGames: Array,
  },
  components: {

  },
  created() {
    this.getSessions()
  },
  mounted() {
    
  },
  watch: {
    allSessions() {
      this.integrationGames.sort((a, b) => this.getGameSessionsCount(b) - this.getGameSessionsCount(a));
    }
  },
  computed: {
    appLanguage() {
      return this.$store.getters['getLanguage']
    },
  },
  methods: {
    getToday(){
      // TODO improve this later
      const today = new Date()
      const year = today.getFullYear()

      let month = today.getMonth()
      if (month.toString().length === 1) month = `0${month + 1}`

      let day = today.getDate()
      if (day.length === 1) day = `0${day}`

      return `${year}-${month}-${day}`
    },
    getGameSessionsCount(game){
      const sessionsCount = this.allSessions.filter(s => s.game.slug === game.slug)
      return sessionsCount.length
    },
    async getSessions() {
      this.loading = true
      try {
        // this.allSessions = (await request.get(`/bookkeeping/sessions/${this.integration.id}`)).data.rows
        const from = new Date(this.dateRange)
        from.setHours(0, 0)
        const sqlFrom = new Date(from).toISOString().slice(0, 19)
        const to = new Date(this.dateRange)
        to.setHours(23, 59)
        const sqlTo = new Date(to).toISOString().slice(0, 19)
        // console.log('sqlFrom: ' + sqlFrom + ' sqlTo: ' + sqlTo);
        const url = `/bookkeeping/sessions/range?from=${sqlFrom}&to=${sqlTo}&filter[integrationId]=${this.integration.id}`

        this.allSessions = (await request.get(url)).data.rows
      } catch (e) {
        console.log(e)
        this.serverError = true
      }
      this.loading = false
    },
  },
};
</script>

<style></style>
