<template>
  <div>
    <div class="w-pb-[50px] xl:pb-0">
      <!-- header  -->
      <div class="flex justify-between border-zinc-700 pb-4">
        <h2 class="title">
          {{ $t("logs.title") }}
        </h2>
      </div>
      <div v-if="ready">
        <div
          class="flex flex-col md:flex-row gap-2 items-center justify-between pb-3"
        >
          <div class="w-full">
            <input
              v-model="search"
              @input="handleInput"
              type="text"
              :placeholder="$t('logs.search')"
              class="p-2 rounded-md w-full outline-none bg-zinc-900 text-white shadow-sm"
            />
          </div>
          <div class="flex flex-col md:flex-row gap-2 w-full md:w-max">
            <div class="w-full md:w-64 capitalize">
              <Multiselect
                mode="single"
                v-model="selectedType"
                :options="logTypes"
                :close-on-select="true"
                :searchable="true"
                label="name"
                value-prop="id"
                :placeholder="$t('logs.filterByType')"
                :loading="!ready"
                ref="multiselectType"
              />
            </div>
            <div class="w-full md:w-64 capitalize">
              <Multiselect
                mode="single"
                v-model="selectedUser"
                :options="allUsers"
                :close-on-select="true"
                :searchable="true"
                label="username"
                value-prop="id"
                :placeholder="$t('logs.filterByUser')"
                :loading="!ready"
                ref="multiselectUser"
              />
            </div>
          </div>
        </div>
        <div class="w-full rounded-sm pb-2">
          <table
            class="table table-auto w-full divide-zinc-900 shadow-md border-separate border-spacing-y-[4px]"
          >
            <thead
              class="text-white text-lg fugaz uppercase bg-zinc-800 sticky top-0 z-20"
            >
              <tr class="">
                <th
                  class="hidden md:table-cell pl-2 py-2 font-thin text-zinc-100 cursor-default rounded-tl-sm"
                >
                  {{ $t("logs.type") }}
                </th>
                <th
                  class="table-cell pl-2 py-2 font-thin text-zinc-100 cursor-default text-left"
                >
                  {{ $t("logs.description") }}
                </th>
                <!-- <th
                  class="table-cell pl-2 py-2 font-thin text-zinc-100 cursor-default"
                  >
                  Updates
                </th> -->
                <th
                  class="hidden md:table-cell pl-2 py-2 font-thin text-zinc-100 cursor-default"
                >
                  {{ $t("logs.username") }}
                </th>
                <th
                  class="table-cell pl-2 py-2 font-thin text-zinc-100 cursor-default"
                >
                  {{ $t("logs.createdAt") }}
                </th>
              </tr>
            </thead>
            <tbody class="text-white bg-zinc-800 z-10">
              <tr
                v-for="(log, index) in filteredLogs.slice(0, listLimit)"
                :key="`log${index}`"
                @click="openLogBox(log.id)"
                class="border-zinc-900 text-center cursor-pointer xl:hover:brightness-110"
                :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'"
              >
                <td
                  class="hidden md:table-cell pl-2 py-2 fugaz uppercase font-thin"
                >
                  {{ log.logType.name }}
                </td>
                <td
                  class="table-cell pl-2 py-2 truncate text-left max-w-[200px] xl:max-w-max"
                >
                  {{ log.description }}
                </td>
                <!-- <td v-if="log.description.toLowerCase().includes('created')" class="table-cell pl-2 py-2 flex justify-center capitalize">New</td>
                <td v-else-if="log.description.toLowerCase().includes('deleted')" class="table-cell pl-2 py-2 flex justify-center capitalize">Deleted</td> -->
                <!-- <td v-else class="table-cell pl-2 py-2 table-cell justify-center capitalize">
                  <span v-for="(key, index) in getUpdatesKeys(log.updates).slice(0,7)" class="pl-1.5">
                    {{key}}{{ index < getUpdatesKeys(log.updates).slice(0,7).length - 1 ? ',' : '' }}
                  </span>
                  {{ getUpdatesKeys(log.updates).length > 7 ? '...' : '' }}
                  
                </td> -->
                <td
                  class="hidden md:table-cell pl-2 py-2 fugaz uppercase font-thin"
                >
                  {{ log.user ? log.user.username : "N/A" }}
                </td>
                <td class="table-cell px-2 py-2">
                  {{ clearDate(log.createdAt) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="!ready"
            class="w-full h-full min-h-[75vh] flex justify-center items-center gap-3 text-zinc-400 bg-zinc-950"
          >
            <img
              v-if="!serverError"
              src="../../assets/hub-full-logo.png"
              alt="gai hub logo"
              class="h-24 w-auto animate-pulse"
            />
            <!-- <img
              src="../../assets/loading2.png"
              alt="loading icon"
              v-if="!serverError"
              class="h-12 animate-spin mb-2"
            /> -->
            <div v-else>
              {{ $t("serverError") }}
              <button @click="$router.go()" class="text-blue-600">
                {{ $t("reload") }}
              </button>
              {{ $t("tryAgain") }}
            </div>
          </div>

          <div
            v-if="ready && filteredLogs.length > 0"
            class="w-full bg-zinc-900 text-white flex gap-1.5 xl:gap-2.5 items-center justify-center py-2"
          >
            <button
              class="xl:hover:bg-zinc-800 px-1.5 xl:px-2.5 xl:py-1 rounded-sm cursor-pointer"
              @click="prevPage"
              :disabled="currentPage === 1"
            >
              <!-- Previous -->
              <font-awesome-icon :icon="['fas', 'angle-left']" />
            </button>
            <button
              @click="goToPage(page)"
              v-for="page in visiblePages"
              :key="page"
              class="hover:text-white text-zinc-400 fugaz uppercase p-1"
            >
              <span :class="currentPage === page && 'text-white'">{{
                page
              }}</span>
            </button>
            <button
              class="xl:hover:bg-zinc-800 px-1.5 xl:px-2.5 xl:py-1 rounded-sm cursor-pointer"
              @click="nextPage"
              :disabled="currentPage === pagesCount"
            >
              <!-- Next -->
              <font-awesome-icon :icon="['fas', 'angle-right']" />
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-full h-[90vh] flex justify-center items-center gap-3 text-zinc-400 bg-zinc-950"
      >
        <img
          v-if="!serverError"
          src="../../assets/hub-full-logo.png"
          alt="gai hub logo"
          class="h-24 w-auto animate-pulse"
        />
        <div v-else>
          {{ $t("serverError") }}
          <button @click="$router.go()" class="text-blue-600">
            {{ $t("reload") }}
          </button>
          {{ $t("tryAgain") }}
        </div>
      </div>
    </div>
    <Transition>
      <Log v-if="logBox" :id="actualLogId" :clearDate="clearDate" />
    </Transition>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import request from "@/services/axios";
import Log from "../../components/logs/Log.vue";

export default {
  data() {
    return {
      ready: false,
      logs: [],
      listLimit: 17,
      logBox: false,
      actualLogId: null,
      selectedUser: null,
      selectedType: null,
      search: "",
      loadingLogBox: false,
      pagesCount: 0,
      currentPage: 1,
      debounceTimer: null,
      logTypes: [],
      allUsers: [],
      mountTimer: null,
    };
  },
  components: {
    Multiselect,
    Log,
  },
  async mounted() {
    this.mountTimer = setTimeout(async () => {
      await this.getAllLogs();
      this.getAllUsers();
      this.getLogTypes();
      if (this.screenWidth > 1280) this.listLimit = 16;
      if (this.screenWidth < 1280 && this.screenWidth > 640)
        this.listLimit = 19;
      if (this.screenWidth < 640) this.listLimit = 12;
      this.emitter.on("logBoxClosed", this.openLogBox);
      this.ready = true;
    }, 500);
  },
  beforeUnmount() {
    clearTimeout(this.mountTimer);
    document.body.style.overflowY = "auto";
  },
  watch: {
    screenWidth() {
      if (this.screenWidth > 1280) this.listLimit = 16;
      if (this.screenWidth < 1280 && this.screenWidth > 640)
        this.listLimit = 19;
      if (this.screenWidth < 640) this.listLimit = 12;
    },
    selectedUser() {
      this.currentPage = 1;
      this.getAllLogs();
      this.$refs.multiselectUser.blur();
    },
    selectedType() {
      this.currentPage = 1;
      this.getAllLogs();
      this.$refs.multiselectType.blur();
    },
  },
  computed: {
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    screenWidth() {
      return this.$store.getters["getScreenWidth"];
    },
    visiblePages() {
      const pages = [];
      const ellipsis = "...";

      if (this.pagesCount <= 10) {
        for (let i = 1; i <= this.pagesCount; i++) {
          pages.push(i);
        }
      } else {
        pages.push(1, 2);

        if (this.currentPage <= 4) {
          pages.push(3, 4, ellipsis, this.pagesCount - 1, this.pagesCount);
        } else if (this.currentPage >= this.pagesCount - 3) {
          pages.push(
            ellipsis,
            this.pagesCount - 4,
            this.pagesCount - 3,
            this.pagesCount - 2,
            this.pagesCount - 1,
            this.pagesCount
          );
        } else {
          pages.push(
            ellipsis,
            this.currentPage - 1,
            this.currentPage,
            this.currentPage + 1,
            ellipsis,
            this.pagesCount - 1,
            this.pagesCount
          );
        }
      }

      return pages;
    },

    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
    filteredLogs() {
      let logs = JSON.parse(JSON.stringify(this.logs));
      // if(this.selectedType) logs = logs.filter(log => log.logType.name === this.selectedType)
      // if(this.selectedUser) logs = logs.filter(log => log.user.username === this.selectedUser)
      return logs;
    },
  },
  methods: {
    getLogTypes() {
      const typesWithRepeats = this.logs.map((log) => log.logType);
      const types = [];
      for (const type of typesWithRepeats) {
        const typeIds = types.map((type) => type.id);
        if (!typeIds.includes(type.id)) {
          types.push(type);
        }
      }
      this.logTypes = types;
    },
    getAllUsers() {
      const usersWithRepeats = this.logs
        .filter((log) => log.user !== null)
        .map((log) => log.user);
      const users = [];
      for (const user of usersWithRepeats) {
        const userIds = users.map((user) => user.id);
        if (!userIds.includes(user.id)) {
          users.push(user);
        }
      }
      this.allUsers = users;
    },
    handleInput() {
      // Clear the previous timeout
      clearTimeout(this.debounceTimer);

      // Set a new timeout
      this.debounceTimer = setTimeout(() => {
        // Call your function here
        this.getAllLogs();
      }, 500);
    },
    goToPage(page) {
      if (page !== "...") {
        this.currentPage = page;
        this.getAllLogs();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getAllLogs();
      }
    },
    nextPage() {
      if (this.currentPage < this.pagesCount) {
        this.currentPage++;
        this.getAllLogs();
      }
    },

    openLogBox(id = null) {
      if (!this.logBox) {
        document.body.style.overflowY = "hidden";
        this.actualLogId = id;
        this.logBox = true;
      } else {
        document.body.style.overflowY = "auto";
        this.actualLogId = null;
        this.logBox = false;
      }
    },
    getUpdatesKeys(object) {
      const names = [];
      Object.keys(object).forEach((key) => names.push(key));
      return names;
    },
    clearDate(date) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(date).toLocaleDateString(this.appLanguage.long, options);
    },
    async getAllLogs() {
      try {
        if (this.search) {
          this.currentPage = 1;
        }
        let url = `logs?limit=${this.listLimit}&page=${this.currentPage}`;
        // &search=${this.search}&userId=${this.selectedUser}&typeId=${this.selectedType}
        if (this.search) url += `&search=${this.search}`;
        if (this.selectedType) url += `&typeId=${this.selectedType}`;
        if (this.selectedUser) url += `&userId=${this.selectedUser}`;
        const data = (await request.get(url)).data;
        this.logs = data.rows;
        this.pagesCount = Math.ceil(data.count / this.listLimit);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
.multiselect-option {
  text-transform: capitalize;
}
</style>