<template>
    <!-- header  -->
    <div class="flex flex-col">
      <div class="flex justify-between">
        <h2 class="title">
          {{ $t("charts.title") }}
        </h2>
        <div class="rounded shadow-xl overflow-hidden grid grid-cols-2 w-64">
          <button
           @click="changeChartsType('Totals')"
            class="fugaz pt-1.5 pb-1 uppercase text-center h-full lg:text-lg"
            :class="chartsType === 'Totals' ? 'text-white  bg-orange-600' : 'text-white bg-zinc-800'"
          >
            {{ $t("charts.totals.title") }}

          </button>
          <button
            @click="changeChartsType('Specific')"
            class="fugaz pt-1.5 pb-1 uppercase text-center h-full lg:text-lg"
            :class="chartsType === 'Specific' ? 'text-white  bg-orange-600' : 'text-white bg-zinc-800'"
          >
           {{ $t("charts.specific.title") }}
          </button>
        </div>
      </div>
      <div v-if="ready" class="flex-1 pt-4 flex flex-col">
        <ChartsTotals
          v-if="chartsType === 'Totals'"
          :chartKey="chartKey"
          :allGames="allGames"
          :allIntegrations="allIntegrations"
          :userIntegration="userIntegration"
        />
        <ChartsSpecific
          v-if="chartsType === 'Specific'"
          :chartKey="chartKey"
          :allGames="allGames"
          :allIntegrations="allIntegrations"
          :userIntegration="userIntegration"
        />
      </div>
      <div
        v-else
        class="flex items-center justify-center w-full h-[90vh] bg-zinc-950"
      >
        <img
          src="../../assets/hub-full-logo.png"
          alt="gai hub logo"
          class="h-24 w-auto animate-pulse"
        />
      </div>
    </div>
      <!-- </div> -->
</template>

<script>
import request from "@/services/axios";
import ChartsTotals from "@/components/charts/ChartsTotals.vue";
import ChartsSpecific from "@/components/charts/ChartsSpecific.vue";

export default {
  data() {
    return {
      ready: false,
      allGames: [],
      allIntegrations: [],
      userIntegration: null,
      chartsType: "Totals",
      serverError: false,
      resizeTimeout: null,
      chartKey: 0,
      mountTimer: null,
    };
  },
  components: {
    ChartsTotals,
    ChartsSpecific,
  },
  async mounted() {
    this.mountTimer = setTimeout(async () => {
      await this.getAllGames();
      await this.getAllIntegrations();
      this.ready = true;
      window.addEventListener("resize", this.handleResize);
    }, 500);
  },
  beforeUnmount() {
    clearTimeout(this.mountTimer);
    // Remove event listener when component is destroyed
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {},
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    handleResize() {
      // Clear previous timeout to debounce
      clearTimeout(this.resizeTimeout);

      // Debounce by setting a new timeout
      this.resizeTimeout = setTimeout(() => {
        // Call your function here
        this.chartKey++;
      }, 300); // Adjust the delay as needed
    },
    changeChartsType(type) {
      // console.log(`changingChartsTo${type}`)
      this.emitter.emit(`changingChartsTo${type}`);
      this.chartsType = type;
    },
    async getAllIntegrations() {
      try {
        if (Object.keys(this.user.attributes).length > 0) {
          const integration = (
            await request.get(
              `/integrations/byName/${this.user.attributes.integration[0]}`
            )
          ).data;
          this.userIntegration = integration;
        } else {
          const integrations = (await request.get("/integrations?with=games&active=1&with=operators")).data
            .rows;
          this.allIntegrations = integrations;
        }
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },
    async getAllGames() {
      try {
        const games = (await request.get("/games")).data?.rows
          .map((game) => ({
            id: game.id,
            name: game.sub_name ? `${game.name} - ${game.sub_name}` : game.name,
            isDev: game.isDev,
            slug: game.slug,
          }))
          .filter((game) => !game.isDev);

        this.allGames = games;
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },
  },
};
</script>

<style scoped>
.vc-popover-content-wrapper select {
  background-color: black;
}
</style>async 