<template>
  <div
    @click.stop
    v-if="session.id"
    class="bg-zinc-900 flex flex-col gap-2.5 rounded-md relative px-5 pb-3 pt-3 h-full overflow-y-auto text-white"
    :class="!fromSearch ? 'border border-zinc-600 max-w-[1400px]' : ''"
  >
    <button @click.stop="closeSessionBox" class="absolute top-2.5 right-3 text-white xl:hover:text-orange-600">
      <font-awesome-icon :icon="['fas', 'xmark']" class="h-6" />
    </button>
    <div class="flex itesm-center justify-between pr-8 pb-1">
      <div class="flex items-center flex-wrap gap-x-6 gap-y-2">
        <div class="flex flex-col fugaz uppercase">
          <span class="text-sm text-orange-600 -mb-1">
            {{ Object.keys(user.attributes).length === 0 ? $t('session.integration') : $t('session.operator') }}
          </span>
          <span class="text-xl text-white">
            {{ Object.keys(user.attributes).length === 0 ? session.integration?.name : session.operator?.name }}
          </span>
        </div>
        <div class="flex flex-col fugaz uppercase">
          <span class="text-sm text-orange-600 -mb-1">{{ $t('session.game') }}</span>
          <span class="text-xl text-white">
            {{ session.game.sub_name ? `${session.game.name} - ${session.game.sub_name}` : session.game.name }}
          </span>
        </div>
      </div>
      <router-link :to="{ name: 'NewTicket', params: { propSessionId: session.id } }" class="fugaz uppercase hover:text-orange-600 text-sm">
        Report bug
        <font-awesome-icon :icon="['fas', 'bug']" />
      </router-link>
      <!-- <div class="flex items-center">
        <h1 class="text-white fugaz uppercase text-2xl"><span class="hidden xl:block">Session</span> details</h1>
      </div> -->
      <div class="flex flex-col fugaz uppercase">
        <span class="text-sm text-zinc-200 -mb-1">{{ $t('session.status') }}</span>
        <span class="text-xl" :class="session.isOpen ? 'text-cyan-500' : 'text-green-500'">
          {{ session.isOpen ? $t('session.open') : $t('session.closed') }}
        </span>
      </div>
    </div>
    <div class="w-full h-full flex flex-col gap-3">
      <div class="flex flex-wrap justify-center gap-3">
        <div class="flex flex-wrap w-full justify-center gap-2.5">
          <div class="w-max max-w-full h-max bg-zinc-800 rounded-md px-2 pt-1 relative">
            <button @click="copyToClipboard(session.sessionId, 'Session ID')" 
              class="absolute top-0.5 right-1.5 text-white xl:hover:text-orange-600 transition-all duration-150 text-sm">
              <font-awesome-icon :icon="['fas', 'copy']" />
            </button>
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">Session Id</h4>
            <h4 class="text-white roboto break-words"> 
              {{ session.sessionId }}
            </h4>
          </div>
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1 relative">
            <button @click="copyToClipboard(session.aamsSessionId, 'Aams Session ID')" 
              class="absolute top-0.5 right-1.5 text-white xl:hover:text-orange-600 transition-all duration-150 text-sm">
              <font-awesome-icon :icon="['fas', 'copy']" />
            </button>
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">Aams session Id</h4>
            <h4 class="text-white roboto">
              {{ session.aamsSessionId }}
            </h4>
          </div>
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1 relative">
            <button @click="copyToClipboard(session.aamsTicketId, 'Aams Ticket ID')" 
              class="absolute top-0.5 right-1.5 text-white xl:hover:text-orange-600 transition-all duration-150 text-sm">
              <font-awesome-icon :icon="['fas', 'copy']" />
            </button>
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">Aams ticket Id</h4>
            <h4 class="text-white roboto">
              {{ session.aamsTicketId }}
            </h4>
          </div>
          <div v-if="Object.keys(user.attributes).length === 0" class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">{{ $t('session.integration') }}</h4>
            <h4 class="text-white fugaz uppercase">
              {{ session.integration.name }}
            </h4>
          </div>
        <!-- </div> -->
        <!-- <div class="flex flex-wrap gap-3"> -->
        <!-- </div> -->
      <!-- </div>
      <div class="flex flex-wrap justify-between gap-2"> -->
        <!-- <div class="flex flex-wrap justify-center gap-3"> -->
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">Bet</h4>
            <h4 class="text-white fugaz uppercase">
              {{ getCurrencyValue(session.bet) }}
            </h4>
          </div>
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">Win</h4>
            <h4 class="text-white fugaz uppercase">
              {{ getCurrencyValue(session.win) }}
            </h4>
          </div>
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">Gross Win</h4>
            <h4 class="text-white fugaz uppercase">
              <span 
                :class="[
                  (session.grossWin > 0 ? 'text-green-500' : 'text-red-500'), ((session.grossWin == 0 || session.grossWin == null) && 'text-white')
                ]"
              >
                  {{ getCurrencyValue(session.grossWin) }}
                </span>
            </h4>
          </div>
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">Net Win</h4>
            <h4 class="text-white fugaz uppercase">
              <span 
                :class="[
                  (session.netWin > 0 ? 'text-green-500' : 'text-red-500'), ((session.netWin == 0 || session.netWin == null) && 'text-white')
                ]"
              >
                {{ getCurrencyValue(session.netWin) }}
              </span>
            </h4>
          </div>
          <div v-if="userRoles.isLudusAdmin" class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">Due</h4>
            <h4 class="text-white fugaz uppercase">
              <span 
                :class="[
                  (session.due > 0 ? 'text-green-500' : 'text-red-500'), ((session.due == 0 || session.due == null) && 'text-white')
                ]"
              >
                {{ getCurrencyValue(session.due) }}
              </span>
            </h4>
          </div>
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1 relative min-w-[90px]">
            <button @click="copyToClipboard(session.userId, 'User ID')" 
              class="absolute top-0.5 right-1.5 text-white xl:hover:text-orange-600 transition-all duration-150 text-sm">
              <font-awesome-icon :icon="['fas', 'copy']" />
            </button>
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">User ID</h4>
            <h4 class="text-white roboto">
              {{ session.userId }}
            </h4>
          </div>
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">{{ $t('session.currentRound') }}</h4>
            <h4 class="text-white fugaz uppercase">
              {{ session.currentRound }}
            </h4>
          </div>
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">{{ $t('session.isMobile') }}</h4>
            <h4 class="text-white fugaz uppercase">
              {{ session.isMobile }}
            </h4>
          </div>
          <div class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">{{ $t('session.type') }}</h4>
            <h4 v-if="session.sessionTypeId === 1" class="text-white fugaz uppercase">
              {{ $t('session.real') }}
            </h4>
            <h4 v-if="session.sessionTypeId === 4" class="text-white fugaz uppercase">
              {{ $t('session.fun') }}
            </h4>
          </div>
          <div v-if="Object.keys(user.attributes).length !== 0" class="w-max h-max bg-zinc-800 rounded-md px-2 pt-1">
            <h4 class="text-zinc-300 fugaz uppercase text-[15px]">{{ $t('session.operator') }}</h4>
            <h4 class="text-white fugaz uppercase">
              {{ session.operator?.name }}
            </h4>
          </div>
        </div>
      </div>
      <div v-if="!feedError" class="w-full hidden md:flex items-center justify-center h-full">
        <div v-show="loadingIframe" class="flex items-center gap-4">
          <img src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-16 w-auto animate-pulse">
          <img src="../../assets/loading2.png" alt="loading icon"
            class="text-zinc-400 h-[50px] animate-spin mb-1.5"
          />
        </div>
        <iframe v-show="!loadingIframe" class="w-full h-full" :src="iframeUrl" frameborder="0" @load="openIframe"></iframe>
      </div>
      <div v-else class="flex flex-col justify-center pt-4 items-center">
        <p>Something wrong happened loading the session feed.</p>
        <button type="button" @click="getFeedUrl" class="mx-5 px-5 py-1 rounded-[3px] bg-orange-600 hover:bg-pink-700 fugaz uppercase">
          retry
        </button>
      </div>
      <div class="w-full flex md:hidden items-center justify-center mt-4">
        <button class="text-white fugaz uppercase">
          {{ $t('session.openFeed') }} <font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" class="pb-px pl-1" />
        </button>
      </div>
    </div>
    <div v-if="userRoles.isLudusAdmin || userRoles.isLudusManager || userRoles.isIntegrationAdmin || userRoles.isIntegrationManager" class="flex items-center gap-2">
      <div class="flex items-center gap-1 w-full">
        <span class="text-white">
          {{ $t('session.lastFetch') }}: {{ session.updatedAt ? clearDate(session.updatedAt) : clearDate(session.createdAt) }}
        </span>
      </div>
      <div class="flex items-center justify-end xl:justify-center w-full">
        <div v-if="loadingSessionUpdates" class="flex justify-center items-center gap-2">
          <img src="../../assets/loading2.png" alt="loading icon"
            class="text-zinc-400 h-6 animate-spin"
          />
          <span class="fugaz uppercase text-white -mb-0.5">{{ $t('session.updating') }}</span>
        </div>
        <button
          v-else
          class="px-2 py-1 rounded-sm fugaz uppercase text-white xl:hover:text-orange-600 transition-all duration-150"
          @click.stop="updateSession"
        >
          {{ $t('session.refreshSession') }}
        </button>

        <button
          class="px-2 py-1 rounded-sm fugaz uppercase text-white xl:hover:text-orange-600 transition-all duration-150"
          @click="copyToClipboard(this.iframeUrl, 'session feed' )"
        >

          {{ $t('session.copyFeedURL') }}
          <font-awesome-icon :icon="['fas', 'copy']" />
        </button>
      </div>
      <div class="hidden xl:flex items-center justify-end gap-2 w-full">
        <button
          v-if="session.isOpen && canBeClosed"
          @click.stop="closeSession"
          class="px-3 py-1 rounded-sm fugaz uppercase text-zinc-100 xl:hover:text-white mt-1"
        >
          {{ $t('session.closeSession') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/services/axios";
import axios from "axios";
export default {
  data() {
    return {
      loadingIframe: true,
      loadingSessionUpdates: false,
      closingSession: false,
      iframeUrl: '',
      feedError: false,
    };
  },
  props: {
    fromSearch: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    appLanguage() {
      return this.$store.getters['getLanguage']
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRoles() {
      return this.user.localRoles;
    },
    session() {
      return this.$store.getters["sessions/getSession"];
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
    canBeClosed() {
      const givenDate = new Date(this.session.sessionUpdatedAt);
      const currentDate = new Date();
      const timeDifference = currentDate - givenDate;

      if (timeDifference <= 10 * 60 * 1000) {
        // console.log('The given date is 10 minutes or less before the current date.');
        return false
      } else {
        // console.log('The given date is more than 10 minutes before the current date.');
        return true
      }
    },
  },
  created() {
    this.getFeedUrl()
  },
  methods: {
    async copyToClipboard(target, type) {
      const textToCopy = target;

      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(textToCopy);
          this.$toast.success(`${type} copied to clipboard`);
        } catch (error) {
          this.$toast.error('Something went wrong, please try again')
          console.error("Unable to copy text to clipboard", error);
        }
      } else {
        console.warn("Clipboard API not supported, falling back to execCommand (deprecated).");

        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();

        try {
          document.execCommand("copy");
          this.$toast.success(`${type} copied to clipboard`);
        } catch (error) {
          this.$toast.error('Something went wrong, please try again')
          console.error("Unable to copy text to clipboard", error);
        } finally {
          document.body.removeChild(textarea);
        }
      }
    },
    async getFeedUrl(){
      try {
        let encodedId = encodeURIComponent(this.session.sessionId);
        let url = (await axios.get(`${this.session.integration.gamingServiceUrl}/transactions/feed/${encodedId}?link=true`)).data.url
       
        this.iframeUrl = `${url}&closable=false`
        this.feedError = false; //should update variable to false because code executed correctly to here ( did it tho.. ?)
      } catch (e) {
        console.log(e)
        this.feedError = true
      }
    },
    clearDate(date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      }
      return new Date(date).toLocaleDateString(this.appLanguage.long, options)
    },
    openIframe() {
      this.loadingIframe = false
    },
    closeSessionBox() {
      document.body.style.overflowY = "auto";
      this.$store.dispatch("sessions/setSessionAction", {});
    },
    async closeSession() {
      this.closingSession = true;
      try {
        await request.post(`/bookkeeping/session/${this.session.id}/close`)

        const updatedSession = (await request.get(`/bookkeeping/sessions/update/${encodeURIComponent(this.session.id)}`)).data
  
        // console.log("dispatching session update on store");
        updatedSession.integration = this.session.integration
        updatedSession.game = this.session.game
        if(updatedSession.id === this.session.id){
          this.$store.dispatch("sessions/setSessionAction", updatedSession);
          this.emitter.emit(`sessionUpdated`);
        }
        this.$toast.success("Session closed");
      } catch (error) {
        console.log(error);
        this.$toast.error("Error closing session. Please try again later or contact the support.");
      }
      this.closingSession = false;
    },
    async updateSession() {
      // console.log("handling update session data");
      this.loadingSessionUpdates = true
      try {
        const updatedSession = (await request.get(`/bookkeeping/sessions/update/${encodeURIComponent(this.session.id)}`)).data
        // console.log("session received");
        // console.log(response.data);
  
        // console.log("dispatching session update on store");
        updatedSession.integration = this.session.integration
        updatedSession.game = this.session.game
        if(updatedSession.id === this.session.id){
          // updatedSession.bet = updatedSession.bet / 100
          // updatedSession.win = updatedSession.win / 100
          this.$store.dispatch("sessions/setSessionAction", {
            ...updatedSession,
            grossWin: updatedSession.grosswin,
            netWin: updatedSession.netwin
          });
          this.emitter.emit(`sessionUpdated`);
          this.$toast.success("Session updated");
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("Error in updating session data. Please try again later.");
      }
      this.loadingSessionUpdates = false
    },
  },
};
</script>

<style scoped></style>
