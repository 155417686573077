<template>
    <div class="flex items-end justify-between pb-1.5 text-white">
      <div class="flex flex-wrap items-end gap-3 relative"> 
        <FilterDateRange />
        <FilterFilterBy 
          :selectedGames="selectedGames"
          :selectedIntegrations="selectedIntegrations"
          :selectedOperators="selectedOperators"
          @update:selectedGames="selectedGames = $event"
          @update:selectedIntegrations="selectedIntegrations = $event"
          @update:selectedOperators="selectedOperators = $event"
          :ready="ready"
          :userIntegration="userIntegration"
          :actualIntegrations="actualIntegrations"
          :allGames="allGames"
          :actualOperators="actualOperators"
          chartType="totals"
        />
        <FilterGroupBy v-model="tempLinesFormat"/>
        <FilterSessionType v-model="sessionsType"/>
        <FilterSessionState v-model="isOpen"/>
        <button
          @click="getTotals(true)"
          :disabled="loading"
          class="hidden md:block md:px-3 xl:hover:text-orange-600 md:bg-zinc-700 xl:hover:bg-zinc-800 md:rounded-md md:uppercase md:fugaz md:text-base md:transition-all md:duration-100 md:disabled:opacity-50"
          style="height: 4.1rem;"
        >
          {{ $t("charts.submit") }}
        </button>
      </div>
    </div>
    <!-- mobile submit -->
    <div class="flex md:hidden items-center justify-center gap-1 w-full">
      <button
        @click="getTotals(true)"
        :disabled="loading"
        class="px-3 pt-1 pb-[3px] text-orange-600 bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
      >
        {{ $t("charts.submit") }}
      </button>
    </div>
    <!-- CURRENCY INFO -->
    <div
      class="flex items-end flex-wrap justify-start gap-2.5 cursor-default pb-[60px] xl:pb-4 mt-1 md:mt-0 text-white"
    >
      <div
        class="bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md"
      >
        <p class="text-sm fugaz uppercase"> Bet </p>
        <p class="text-xl text-sky-600 font-bold roboto">
          {{ allBet ? getCurrencyValue(allBet) : '-'}}
        </p>
      </div>
      <div
        class="bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md"
      >
        <p class="text-sm fugaz uppercase"> Win </p>
        <p class="text-xl font-bold roboto text-orange-600">
          {{ allWin ? getCurrencyValue(allWin) : '-'}}
        </p>
      </div>
      <div v-if="!hideRealWins" class="bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md">
        <p class="text-sm fugaz uppercase"> Gross win </p>
        <p
          class="text-xl font-bold roboto"
          :class="allGrossWin >= 0 ? 'text-green-500' : 'text-red-500'"
        >
          {{ allGrossWin ? getCurrencyValue(allGrossWin) : '-'}}
        </p>
      </div>
      <div
        v-if="
          ready &&
          (userRoles.isLudusAdmin || userRoles.isIntegrationAdmin) &&
          !hideRealWins
        "
        class=" bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md"
      >
        <p class="text-sm fugaz uppercase"> Net win </p>
        <p
          class="text-xl font-bold roboto"
          :class="allNetWin >= 0 ? 'text-orange-600' : 'text-red-500'"
        >
          {{ allNetWin ? getCurrencyValue(allNetWin) : '-'}}
        </p>
      </div>
      <div
        v-if="
          ready &&
          (userRoles.isLudusAdmin || userRoles.isIntegrationAdmin) &&
          !hideRealWins
        "
        class=" bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md"
      >
        <p class="text-sm fugaz uppercase"> due </p>
        <p
          class="text-xl font-bold roboto"
          :class="allDue >= 0 ? 'text-purple-500' : 'text-red-500'"
        >
          {{ allDue ? getCurrencyValue(allDue) : '-' }}
        </p>
      </div>
      <div class="flex items-center bg-zinc-800 rounded-md">
        <div
          class="bg-zinc-800 pb-1 px-2.5 rounded-md text-center pt-1.5"
        >
          <p class="text-sm fugaz uppercase">
            {{ $t("charts.sessions") }}
          </p>
          <p class="text-xl font-bold roboto text-white">
            {{ sessionsCount ? formatNumberWithPoints(sessionsCount) : '-' }}
          </p>
        </div>
        <div
          class="bg-zinc-800 pb-1 pt-1.5 px-3 border-x border-zinc-700 text-center"
        >
          <p class="text-sm fugaz uppercase">
            {{ $t("charts.totals.open") }}
          </p>
          <p class="text-xl font-bold roboto text-sky-500">
            {{ sessionsOpen ? formatNumberWithPoints(sessionsOpen) : '-' }}
          </p>
        </div>
        <div
          class="bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-center"
        >
          <p class="text-sm fugaz uppercase">
            {{ $t("charts.totals.closed") }}
          </p>
          <p class="text-xl font-bold roboto text-green-500">
            {{ sessionsClosed ? formatNumberWithPoints(sessionsClosed) : '-' }}
          </p>
        </div>
      </div>
      <div
        class="bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-center"
      >
        <p class="text-sm fugaz uppercase">
          {{ $t("charts.rounds") }}
        </p>
        <p class="text-xl font-bold roboto text-zinc-300">
          {{ rounds ? formatNumberWithPoints(rounds) : '-' }}
        </p>
      </div>
      <div
        class="bg-zinc-800 pb-1 pt-1.5 px-2.5 rounded-md text-center"
      >
        <p class="text-sm fugaz uppercase">
          {{ $t("charts.uniquePlayers") }}
        </p>
        <p class="text-xl font-bold roboto text-red-500">
          {{ usersCount ? formatNumberWithPoints(usersCount) : '-' }}
        </p>
      </div>
    </div>  
    <!-- CHART -->
    <div class="h-[400px] md:flex-1 min-h-0 flex justify-between gap-x-2 bg-zinc-900 p-2 rounded-md">
      <div class="xl:w-[80%] flex-1 min-h-0 flex flex-col">
        <div class="h-fit flex justify-between" :class="allWin > 0 && 'pr-4'">
          <div class="space-x-1.5">
            <button
              @click="changeChartType('lines')"
              class="py-1 px-2 rounded-sm"
              :class="[
                chartType == 'lines'
                  ? 'bg-zinc-600'
                  : 'bg-zinc-700 text-zinc-300',
                chartTypesAllowed.includes('lines')
                  ? 'xl:hover:bg-opacity-60'
                  : 'opacity-50 ',
              ]"
            >
              <font-awesome-icon :icon="['fas', 'chart-line']" />
            </button>
            <button
              @click="changeChartType('bars')"
              class="py-1 px-2 rounded-sm"
              :class="
                chartType == 'bars'
                  ? 'bg-zinc-600'
                  : 'bg-zinc-700 xl:hover:bg-opacity-60 text-zinc-300'
              "
            >
              <font-awesome-icon :icon="['fas', 'chart-column']" />
            </button>
            <!-- <button
              @click="changeChartType('barsHorizontal')"
              class="py-1 px-2 rounded-sm"
              :class="
                chartType == 'barsHorizontal'
                  ? 'bg-zinc-600'
                  : 'bg-zinc-700 xl:hover:bg-opacity-60 text-zinc-300'
              "
            >
              <font-awesome-icon :icon="['fas', 'chart-bar']" />
            </button> -->
          </div>
          <button
            v-if="betDataset.length > 0"
            @click="exportToExcel"
            class="py-1 px-2 rounded-sm bg-zinc-700 xl:hover:bg-opacity-60"
          >
            <font-awesome-icon :icon="['fas', 'file-csv']" />
          </button>
        </div>
        <div class="flex-1" :key="chartKey">
        <Line
          v-if="!loadingChart && chartType == 'lines'"
          :data="linesChartData"
          :options="linesChartOptions"
        />
        <Bar
          v-if="!loadingChart && chartType == 'bars'"
          :data="linesChartData"
          :options="linesChartOptions"
        />
        <!-- <Bar
          v-if="!loadingChart && chartType == 'barsHorizontal' "
          :data="linesChartData"
          :options="horizontalBarOptions"
        /> -->
        <div v-if="loadingChart" class="flex w-full justify-center h-full items-center">
          <img
            src="../../assets/hub-full-logo.png"
            alt="gai hub logo"
            class="h-22 w-auto animate-pulse"
          />
          <!-- <img src="../../assets/loading2.png" alt="loading icon"
            class="text-zinc-400 h-[50px] animate-spin mb-1.5"
          /> -->
        </div>
      </div>
        

      </div>
      <div class="hidden xl:block xl:w-[20%]">
          <Pie
            class="w-full h-full max-h-[450px] pt-2"
            :data="pieChartData"
            :options="pieChartOptions"
          />
      </div>
    </div>
</template>

<script>
import FilterGroupBy from "../utils/FilterGroupBy.vue"
import FilterFilterBy from "../utils/FilterFilterBy.vue";
import FilterDateRange from "../utils/FilterDateRange.vue";
import FilterSessionType from "../utils/FilterSessionType.vue";
import FilterSessionState from "../utils/FilterSessionState.vue";
import DatePicker from "@/components/DatePicker";
import { useDateFormatter } from "@/helpers/dateFormatter.js";
import request from "@/services/axios";
import { utils, writeFile } from "xlsx";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Pie, Line, Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  TimeScale,
} from "chart.js";

import { DateTime } from "luxon";
import "chartjs-adapter-luxon";
import { areIntervalsOverlapping } from "date-fns";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  TimeScale
);
export default {
  data() {
    return {
      ready: false,
      loading: false,
      loadingChart: false,
      actualIntegrations: [],
      openSessionsInfo: false,
      sessionsCount: null,
      sessionsOpen: null,
      sessionsClosed: null,
      rounds: null,
      allBet: null,
      allWin: null,
      allGrossWin: null,
      allNetWin: null,
      allDue: null,
      totals: [],
      selectedIntegrations: [],
      selectedGames: [],
      chartType: "lines",
      chartTypesAllowed: ["lines", "bars", "barsHorizontal"],
      linesFormat: "hour",
      tempLinesFormat: "hour",
      linesFormatsAllowed: ["hour"],
      sessionsType: "real",
      hideRealWins: false,
      chartLabels: [],
      betDataset: [],
      winDataset: [],
      grossWinDataset: [],
      netWinDataset: [],
      dueDataset: [],
      sessionsDataset: [],
      playersDataset: [],
      roundsDataset: [],
      dateKey: 0,
      groupDate: "YYYY-mm",
      usersCount: null,
      isOpen: null,
      selectedOperators: [],
      actualOperators: [],
    };
  },
  props: {
    allGames: Array,
    allIntegrations: Array,
    userIntegration: Object | null,
    chartKey: Number,
  },
  components: {
    DatePicker,
    Pie,
    Line,
    Bar,
    Multiselect,
    FilterSessionType,
    FilterDateRange,
    FilterFilterBy,
    FilterGroupBy,
    FilterSessionState
  },
  async mounted() {
    // this.emitter.on('changingChartsToSpecific', this.saveFilters)

    // await this.getAllIntegrations();
    // await this.getAllSessions();
    // await this.getTotals(true)
    this.actualIntegrations = [...this.allIntegrations];
    // if(this.savedFilters !== null) {
    //   this.updateFilters()
    //   if(this.savedFilters.submitted) {
    //     await this.getTotals(true)
    //   }
    // }
    // else {
    // this.dateRange.start = this.getTodayStart();
    // this.dateRange.end = this.getTodayEnd();
    // this.dateKey++;
    // }

    this.actualOperators = this.userIntegration?.operators;
    if (this.selectedIntegrations.length == 1) {
      this.actualOperators = this.selectedIntegrations[0].operators;
    }
    
    this.ready = true;
  },
  watch: {
    // 'dateRange.start'() {
    //   this.checkDatesInterval()
    // },
    // tempLinesFormat() {
    //   this.checkDatesInterval()
    // },
    selectedGames() {
      this.filterIntegrations();
    },
    selectedIntegrations() {
      this.filterGames();
    },
  },
  computed: {
    dateRange() {
      return this.$store.state.filters.date;
    },
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    savedFilters() {
      return this.$store.getters["charts/savedFilters"];
    },
    horizontalBarOptions() {
      return {
        indexAxis: "y",
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        interaction: {
          mode: "index",
          intersect: true,
        },
        plugins: {
          legend: {
            position: "right",
          },
        },
        scales: {
          y: {
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
          x: {
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
        },
      };
    },
    linesChartOptions() {
      return {
        responsive: true,
        interaction: {
          mode: "index",
          intersect: false,
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const value = context.dataset.data[context.dataIndex];
                const datasetName = context.dataset.label;
                let label = "";
                if (
                  datasetName !== "Rounds" &&
                  datasetName !== "Sessions" &&
                  datasetName !== "Players"
                ) {
                  label = this.getCurrencyValue(value);
                } else {
                  // Default number format for other datasets
                  label = value;
                }
                return `${datasetName}: ${label}`;
              },
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            ticks: {
              color: "white", // Change scale label (number) color here
              font: {
                family: "Fugaz One, cursive", // Change font-family here
                weight: "medium",
                size: 12, // Adjust font size as needed
              },
              autoSkip: true,
              maxTicksLimit: 12,
            },
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
          x: {
            time: {
              unit: this.linesFormat, // TODO this must respect the current select
              // parser: 'YYYY-MM-dd HH',
              // displayFormats: {
              //   hour: 'YYYY-MM-dd HH'
              // },
              // tooltipFormat: 'YYYY-MM-dd HH:mm:ss'
            },
            // suggestedMin: DateTime.fromJSDate( new Date(this.dateRange.start) ).toFormat(this.groupDate),
            // suggestedMax: DateTime.fromJSDate( new Date(this.dateRange.end) ).toFormat(this.groupDate),
            a: "0",
            ticks: {
              autoSkip: false,
              color: "white", // Change scale label (number) color here
              font: {
                family: "Fugaz One, cursive", // Change font-family here
                weight: "medium",
                size: 12, // Adjust font size as needed
              },
              autoSkip: true,
              maxTicksLimit: 12,
            },
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
        },
        maintainAspectRatio: false,
        // spanGaps: 1000 * 60 * 60 * 24 * 1,
      };
    },
    linesChartData() {
      let datasets = [
        {
          label: "Bet",
          borderColor: "rgb(2 132 199)",
          backgroundColor: "rgb(2 132 199)",
          data: this.betDataset,
          tension: 0.3,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        },
        {
          label: "Win",
          borderColor: "rgb(249 115 22)",
          backgroundColor: "rgb(249 115 22)",
          data: this.winDataset,
          tension: 0.3,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        },
        {
          label: "GrossWin",
          borderColor: "rgb(34 197 94)",
          backgroundColor: "rgb(34 197 94)",
          data: this.grossWinDataset,
          tension: 0.3,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        },
      ];

      if (this.userRoles.isLudusAdmin || this.userRoles.isIntegrationAdmin) {
        datasets.push({
          label: "NetWin",
          borderColor: "#FF6600",
          backgroundColor: "#FF6600",
          data: this.netWinDataset,
          tension: 0.3,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        });
        datasets.push({
          label: "Due",
          borderColor: "rgb(168 85 247)",
          backgroundColor: "rgb(168 85 247)",
          data: this.dueDataset,
          tension: 0.3,
          // spanGaps: 1000 * 60 * 60 * 24 * 1,
          // spanGaps: false,
          // showLines: true,
        });
      }
      datasets.push({
        label: "Sessions",
        borderColor: "rgb(255 255 255)",
        backgroundColor: "rgb(255 255 255)",
        data: this.sessionsDataset,
        tension: 0.3,
        hidden: true,
      });
      datasets.push({
        label: "Rounds",
        borderColor: "rgb(212 212 216)",
        backgroundColor: "rgb(212 212 216)",
        data: this.roundsDataset,
        tension: 0.3,
        hidden: true,
      });
      datasets.push({
        label: "Players",
        borderColor: "rgb(239 68 68)",
        backgroundColor: "rgb(239 68 68)",
        data: this.playersDataset,
        tension: 0.3,
        hidden: true,
      });
      return {
        labels: this.chartLabels,
        datasets,
      };
    },
    pieChartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            labels: {
              color: "white",
            },
          },
          legpieChartOptionsend: {
            labels: {
              color: "white",
            },
            legendCallback: (chart) => {
              const legendPadding = 10; // Adjust the padding as needed
              return `<div style="padding-bottom: ${legendPadding}px;"></div>`;
            },
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const value = context.dataset.data[context.dataIndex];
                return new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(value);
              },
            },
          },
        },
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        maintainAspectRatio: false,
      };
    },
    pieChartData() {
      let labels = [];
      let data = [];
      labels = ["Bet", "Win", "GrossWin"];
      data = [this.allBet, this.allWin, this.allGrossWin];
      if (this.userIntegration === null) {
        labels.push("NetWin");
        data.push(this.allNetWin);
        labels.push("Due");
        data.push(this.allDue);
      }
      return {
        labels,
        datasets: [
          {
            backgroundColor: [
              "rgb(2 132 199)",
              "rgb(249 115 22)",
              "rgb(34 197 94)",
              "#FF6600",
              "rgb(168 85 247)",
            ],
            data,
          },
        ],
        cutoutPercentage: 80,
      };
    },
    lastJob() {
      return this.$store.getters["jobs/lastJob"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRoles() {
      return this.user.localRoles;
    },
    storeSession() {
      return this.$store.getters["sessions/getSession"];
    },
  },
  methods: {
    filterIntegrations() {
      if (this.selectedGames.length === 0) {
        // If no game is selected, enable all integrations
        this.allIntegrations.forEach((integration) => (integration.disabled = false));
        return;
      }

      // For each integration, check if it contains all selected games
      this.allIntegrations.forEach((integration) => {
        integration.disabled = !this.selectedGames.every((gameId) =>
          integration.games.map((game) => game.id).includes(gameId)
        );
      });
    },
    filterGames() {
      if (this.selectedIntegrations.length === 0) {
        // If no integration is selected, disable all games
        this.allGames.forEach((game) => (game.disabled = false));

        // reseting operators list
        this.actualOperators = [];
        this.selectedOperators = [];
        return;
      }

      if (this.selectedIntegrations.length == 1) {
        let selectedIntegrationId = this.selectedIntegrations[0];
        let actualIntegration = this.actualIntegrations.find(i => i.id == selectedIntegrationId);
        this.actualOperators = actualIntegration?.operators;
      } else {
        this.actualOperators = [];
        this.selectedOperators = [];
      }

      // For each game, check if it exists in every selected integration's games
      this.allGames.forEach((game) => {
        game.disabled = !this.selectedIntegrations.every((integrationId) => {
          const integration = this.allIntegrations.find((p) => p.id === integrationId);
          return (
            integration && integration.games.map((game) => game.id).includes(game.id)
          );
        });
      });
    },
    changeChartType(value) {
      if (!this.chartTypesAllowed.includes(value)) {
        this.$toast.open({
          message:
            "Chart type not allowed, increase date range or change date time group.",
          type: "error",
          duration: 5000,
          position: "bottom-right",
        });
      } else {
        this.chartType = value;
      }
    },
    changeIsOpen() {
      if (this.isOpen === null) {
        this.isOpen = true;
        return;
      }
      if (this.isOpen === true) {
        this.isOpen = false;
        return;
      }
      if (this.isOpen === false) {
        this.isOpen = null;
        return;
      }
    },
    saveFilters() {
      // console.log('totals saving filters')
      let submitted = false;
      if (this.savedFilters?.submitted || this.betDataset.length > 0)
        submitted = true;
      this.$store.dispatch("charts/setSavedFilters", {
        dateRange: this.dateRange,
        selectedGames: this.selectedGames,
        tempLinesFormat: this.tempLinesFormat,
        sessionsType: this.sessionsType,
        submitted,
      });
    },
    updateFilters() {
      this.dateRange = this.savedFilters.dateRange;
      this.selectedGames = this.savedFilters.selectedGames;
      this.sessionsType = this.savedFilters.sessionsType;
      this.tempLinesFormat = this.savedFilters.tempLinesFormat;
    },
    checkDatesInterval() {
      const date1 = this.dateRange.start;
      const date2 = this.dateRange.end;
      // Check if the dates are on the same day
      const isSameDay =
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();

      // Check if the dates are on the same month
      const isSameMonth =
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();

      if (this.tempLinesFormat === "hour") {
        this.chartTypesAllowed = ["lines", "bars", "barsHorizontal"];
      }
      if (
        (this.tempLinesFormat === "day" && isSameDay) ||
        (this.tempLinesFormat === "month" && isSameMonth)
      ) {
        this.chartTypesAllowed = ["bars", "barsHorizontal"];
        if (this.chartType === "lines") {
          this.chartType = "bars";
        }
      } else {
        this.chartTypesAllowed = ["lines", "bars", "barsHorizontal"];
      }
    },
    formatNumberWithPoints(number) {
      return number
        ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : number;
    },
    exportToExcel() {
      const labels = this.linesChartData.labels;
      const datasets = this.linesChartData.datasets;

      const header = ["Date"];
      header.push(...datasets.map((dataset) => dataset.label));

      // Sample data
      let data = [header];
      // Combine data and generate rows
      for (let i = 0; i < labels.length; i++) {
        let rowData = [];
        rowData.push(labels[i]);
        rowData.push(
          ...datasets.map((dataset) => {
            // console.log(dataset)
            if (dataset.label !== "Rounds" && dataset.label !== "Sessions") {
              return this.getCurrencyValue(dataset.data[i].toFixed(2));
            } else return dataset.data[i];
          })
        );
        data.push(rowData);
      }
      data.push("");
      data.push([
        "",
        "Total Bet",
        "Total Win",
        "Total Gross Win",
        "Total Net Win",
        "Total Due",
        "Total Sessions",
        "Total Rounds",
      ]);
      data.push([
        "",
        this.getCurrencyValue(this.allBet.toFixed(2)),
        this.getCurrencyValue(this.allWin.toFixed(2)),
        this.getCurrencyValue(this.allGrossWin.toFixed(2)),
        this.getCurrencyValue(this.allNetWin.toFixed(2)),
        this.getCurrencyValue(this.allDue.toFixed(2)),
        this.sessionsCount,
        this.rounds,
      ]);
      // console.log(data);

      // Create a worksheet
      const ws = utils.aoa_to_sheet(data);

      // Set column widths (widths are in Excel's "characters" unit)
      ws["!cols"] = [
        { width: 20 }, // Width for date
        { width: 20 }, // Width for bet
        { width: 20 }, // Width for win
        { width: 20 }, // Width for GrossWin
        { width: 20 }, // Width for NetWin
        { width: 20 }, // Width for Due
        { width: 15 }, // Width for Sessions
        { width: 15 }, // Width for Rounds
      ];
      // Create a workbook
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Date range");

      // Export the workbook to a file
      writeFile(wb, "totals_charts_data.xlsx");
    },
    areDatesOnSameDay(dateArray) {
      const firstDateDay = dateArray[0].split(" ")[0];
      return dateArray.every((date) => date.split(" ")[0] === firstDateDay);
    },
    areDatesOnSameYear(dateArray) {
      return dateArray.every(
        (date) =>
          new Date(date).getFullYear() === new Date(dateArray[0]).getFullYear()
      );
    },
    areMonthsOnSameYear(dateArray) {
      return dateArray.every((date) => {
        const date1String = `${date}-01`;
        const date2String = `${dateArray[0]}-01`;
        return (
          new Date(date1String).getFullYear() ===
          new Date(date2String).getFullYear()
        );
      });
    },
    async getTotals(fromSubmit) {
      this.checkDatesInterval();
      if (fromSubmit) this.loading = true;
      if (this.sessionsType === "fun") this.hideRealWins = true;
      this.linesFormat = this.tempLinesFormat;
      this.loadingChart = true;
      try {
        const from = new Date(this.dateRange.start);
        const to = new Date(this.dateRange.end);
        const gameIds =
          this.selectedGames.length > 0 ? this.selectedGames : null;
        let integrationIds = null;
        if (this.selectedIntegrations.length) integrationIds = this.selectedIntegrations;
        if (this.userIntegration) integrationIds = [this.userIntegration.id];
        let operatorsIds = null;
        if (this.selectedOperators.length) {
          operatorsIds = this.selectedOperators;
        }
        let groupDate = "";
        if (this.linesFormat == "hour") {
          // groupDate = '%Y-%m-%d %H'
          groupDate = "%Y-%m-%d %H";
          this.groupDate = "y-LL-dd HH";
        }
        if (this.linesFormat == "day") {
          groupDate = "%Y-%m-%d";
          this.groupDate = "y-LL-d";
        }
        if (this.linesFormat == "month") {
          groupDate = "%Y-%m";
          this.groupDate = "y-LL";
        }

        let sessionTypeId = null;
        if (this.sessionsType == "real") sessionTypeId = 1;
        if (this.sessionsType == "fun") sessionTypeId = 4;

        const requestObject = {
          from: useDateFormatter(from),
          to: useDateFormatter(to),
          games: gameIds,
          integrations: integrationIds,
          operators: operatorsIds,
          stats: "bet,win,grosswin,netwin,due,sessionsTotals,usersCount,rounds",
          datasetTotals: 1,
          key: groupDate,
          sessionTypeId,
        };
        if (this.isOpen !== null) {
          requestObject.isOpen = this.isOpen;
        }
        // console.log(requestObject)
        const a = btoa(JSON.stringify(requestObject));
        // const b = atob(a)
        // console.log(b)
        const response = (await request(`/bookkeeping/stats?a=${a}`)).data;
        // console.log(response)
        this.allBet = response.grandTotals.bet;
        this.allWin = response.grandTotals.win;
        this.allGrossWin = response.grandTotals.grosswin;
        // const italianTax = (process.env.VUE_APP_ITALIAN_TAX / 100) * this.allGrossWin
        // const netWin = this.allGrossWin - italianTax
        this.allNetWin = response.grandTotals.netwin;
        this.allDue = response.grandTotals.due;
        this.sessionsCount = response.grandTotals.sessionsTotal;
        this.sessionsClosed = response.grandTotals.sessionsClosed;
        this.sessionsOpen = response.grandTotals.sessionsOpen;
        this.rounds = response.grandTotals.rounds;
        this.usersCount = response.grandTotals.usersCount;

        const formatFunction = (label) => {
          if (this.linesFormat == "hour") {
            const labelSplitted = label.split(" ");
            const labelDate = new Date(labelSplitted[0]);
            labelDate.setHours(labelSplitted[1]);

            const options = { hour: "numeric", minute: "numeric" };

            if (!this.areDatesOnSameDay(response.labels)) {
              options.day = "numeric";
              options.month = "short";
            }
            return labelDate.toLocaleTimeString("en-GB", options);
          }
          if (this.linesFormat == "day") {
            const labelDate = new Date(label);
            const options = { day: "numeric", month: "short" };
            if (!this.areDatesOnSameYear(response.labels)) {
              options.year = "numeric";
            }
            return labelDate.toLocaleDateString("en-GB", options);
          }
          if (this.linesFormat == "month") {
            const labelString = `${label}-01`;
            const labelDate = new Date(labelString);
            const options = { month: "long" };
            if (!this.areMonthsOnSameYear(response.labels)) {
              options.year = "numeric";
            }
            return labelDate.toLocaleDateString("en-GB", options);
          }
        };

        // console.log(response.labels)
        const labels = response.labels.map(formatFunction);

        this.chartLabels = [...labels];

        this.betDataset = response.datasets.totals.bet[0].data;
        this.winDataset = response.datasets.totals.win[0].data;
        this.grossWinDataset = response.datasets.totals.grosswin[0].data;
        this.netWinDataset = response.datasets.totals.netwin[0].data;
        this.dueDataset = response.datasets.totals.due[0].data;

        this.sessionsDataset = response.datasets.totals.sessionsTotals[0].data;
        this.playersDataset = response.datasets.totals.usersCount[0].data;
        this.roundsDataset = response.datasets.totals.rounds[0].data;

        if (this.sessionsType !== "fun") this.hideRealWins = false;
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
      if (fromSubmit) this.loading = false;
      this.loadingChart = false;
      this.saveFilters();
    },
  },
};
</script>

<style>
.multiselect {
  background: rgb(24 24 27);
  border: 0px;
  max-height: 20px;
  box-shadow: none;
}
.multiselect.is-active {
  border-width: 1px;
  border-color: rgb(63 63 70);
  box-shadow: none;
}
.multiselect.is-disabled {
  background: rgb(24 24 27);
  border-width: 1px;
  opacity: 0.3;
  border-color: rgb(63 63 70);
  box-shadow: none;
}
.multiselect-tags-search {
  background: rgb(24 24 27);
  color: white;
}
.multiselect-search {
  background: rgb(24 24 27);
  color: white;
}
.multiselect-single-label {
  color: white;
}
.multiselect-dropdown {
  background: rgb(24 24 27);
  color: white;
  border-width: 1px;
  border-color: rgb(63 63 70);
  text-transform: capitalize;
  max-height: 25rem;
}
.multiselect-option.is-selected,
.multiselect-option.is-selected.is-pointed,
.multiselect-tag {
  background: #ff6600;
}
.multiselect-multiple-label {
  color: white;
}
.multiselect-option.is-disabled {
  background: rgb(24 24 27);
  color: rgb(85, 85, 85);
}
</style>