<template>
  <div class="w-full h-full flex flex-col items-start bg-zinc-950 gap-4 pb-[65px] xl:pb-0 py-4 text-white">
    <!-- header  -->
    <div class="w-full flex justify-between ">
      <h1 class="title">
        {{ $t('tickets.tickets') }}
      </h1>
      <router-link :to="{name: 'NewTicket'}" class="text-xl fugaz uppercase hover:text-orange-600">
        {{ $t('tickets.newTicket') }}
      </router-link>
    </div>
    <div v-if="ready" class="w-full h-full flex flex-col items-start gap-4">
      <!-- filters -->
      <div class="flex items-center gap-3">
        <div 
          class="w-[100%] max-w-[190px] flex items-center gap-1.5 capitalize"
          :class="appLanguage.long == 'en-GB' ? 'min-w-[190px]' : 'min-w-[440px]'"
        >
          <Multiselect
            mode="single"
            v-model="selectedStatus"
            :options="possibleStatus"
            :placeholder="$t('tickets.filterByStatus')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
          />
        </div>
        <div 
          v-if="!isIntegrationUser"
          class="w-[100%] max-w-[260px] flex items-center gap-1.5 capitalize"
          :class="appLanguage.long == 'en-GB' ? 'min-w-[260px]' : 'min-w-[440px]'"
        >
          <Multiselect
            mode="single"
            v-model="selectedIntegration"
            :options="integrations"
            :label="'name'"
            value-prop="id"
            :placeholder="$t('tickets.filterByIntegration')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
          />
        </div>
        <div 
          class="w-[100%] max-w-[260px] flex items-center gap-1.5 capitalize"
          :class="appLanguage.long == 'en-GB' ? 'min-w-[260px]' : 'min-w-[440px]'"
        >
          <Multiselect
            mode="single"
            v-model="selectedGame"
            :options="games"
            :label="'name'"
            value-prop="id"
            :placeholder="$t('tickets.filterByGame')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
          />
        </div>
      </div>
      <table class="w-full table table-auto border-separate border-spacing-y-[4px]">
        <thead>
          <tr class="bg-zinc-700 fugaz uppercase">
            <th class="text-left py-2 font-normal pl-2">{{ $t('tickets.status') }}</th>
            <th class="text-left py-2 font-normal">{{ $t('tickets.title') }}</th>
            <th v-if="!isIntegrationUser" class="text-left py-2 font-normal">{{ $t('tickets.integration') }}</th>
            <th class="text-left py-2 font-normal">{{ $t('tickets.game') }}</th>
            <th class="text-left py-2 font-normal">{{ $t('tickets.sessionId') }}</th>
            <th class="text-center py-2 font-normal">{{ $t('tickets.answers') }}</th>
            <th class="text-center py-2 font-normal">{{ $t('tickets.reporter') }}</th>
            <th class="text-left py-2 font-normal w-[180px]">{{ $t('tickets.createdAt') }}</th>
          </tr>
        </thead>
        <tbody :key="ticketsKey">
          <tr v-for="ticket in filteredTickets" @click="openTicket = ticket" class="bg-zinc-900 hover:brightness-125 cursor-pointer">
            <td class="py-2 pl-2">
              <span class="font-thin px-2.5 pb-px pt-0.5 rounded-lg fugaz uppercase text-sm" :class="getStatusColor(ticket.status)">
                {{ ticket.status }}
              </span>
            </td>
            <td class="py-2">{{ ticket.title }}</td>
            <td v-if="!isIntegrationUser" class="py-2 fugaz uppercase text-sm">{{ ticket.integration ? ticket.integration.name : 'N/A' }}</td>
            <td class="py-2 fugaz uppercase text-sm">{{ ticket.game ? ticket.game.name : 'N/A' }}</td>
            <td class="py-2">{{ ticket.sessionId ? ticket.sessionId : 'N/A' }}</td>
            <td class="py-2 text-center pl-2">{{ ticket.answers.length }}</td>
            <td class="py-2 text-center capitalize">
              <span v-if="ticket.reporter">
                {{ loggedUser.username !== ticket.reporter.username ? ticket.reporter.username : 'You' }}
              </span>
              <span v-else>[{{ $t('tickets.deleted') }}]</span>
            </td>
            <td class="py-2">{{ clearDate(ticket.createdAt) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="flex flex-col pb-[50px] xl:pb-0 bg-zinc-950">
        <Transition>
          <Ticket v-if="openTicket !== false" :key="ticketsKey" :ticket="openTicket" :getStatusColor="getStatusColor" :clearDate="clearDate" />
        </Transition>
      </div>
    </div>
    <div
      v-else
      class="w-full h-[90vh] flex justify-center items-center bg-zinc-950 text-white mt-4"
    >
      <img v-if="!serverError" src="../assets/hub-full-logo.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
      <div v-else>
        {{ $t('serverError') }}
        <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
        {{ $t('tryAgain') }}
      </div>
    </div>
  </div>
</template>
<script>
import Ticket from './Ticket.vue'
import request from '@/services/axios'
import Multiselect from '@vueform/multiselect'
  export default {
    data() {
      return {
        ready: false,
        tickets: [],
        filteredTickets: [],
        selectedIntegration: null,
        selectedStatus: null,
        selectedGame: null,
        possibleStatus: [
          'open',
          'ongoing',
          'closed'
        ],
        openTicket: false,
        ticketsKey: 0,
        mountTimer: null,
      }
    },
    components: {
      Multiselect,
      Ticket,
    },
    async mounted() {
      this.mountTimer = setTimeout(async () => {
        await this.getAllTickets()
        this.emitter.on('closeTicket', this.closeTicket)
        this.emitter.on('ticketUpdated', this.getAllTickets)
        this.ready = true
      }, 500);
    },
    beforeUnmount() {
      clearTimeout(this.mountTimer);
    },
    watch: {
      selectedStatus() {
        this.filterTickets()
      },
      selectedIntegration() {
        this.filterTickets()
      },
      selectedGame() {
        this.filterTickets()
      }
    },
    computed: {
      loggedUser() {
        return this.$store.getters['user/user']
      },
      isIntegrationUser() {
        return Object.keys(this.loggedUser.attributes).length > 0
      },
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
      integrations() {
        const integrations = []
        for (const ticket of this.tickets) {
          if (ticket.integration !== null && !integrations.map(p => p.id).includes(ticket.integration.id)) {
            integrations.push({
              ...ticket.integration,
              disabled: this.selectedStatus ? this.selectedStatus !== ticket.status : false
            })
          }
        }
        return integrations
      },
      games() {
        const games = []
        for (const ticket of this.tickets) {
          if (ticket.game && !games.map(p => p.id).includes(ticket.game.id)) {
            let response = {
              ...ticket.game,
              disabled: false,
            }
            if (this.selectedStatus && this.selectedStatus !== ticket.status) response.disabled = true
            if (this.selectedIntegration && this.selectedIntegration !== ticket.integration) response.disabled = true
            games.push(response)
          }
        }
        return games
      }
    },
    methods: {
      closeTicket() {
        this.openTicket = false
      },
      filterTickets() {
        this.filteredTickets = [...this.tickets]
        if (this.selectedStatus !== null) {
          this.filteredTickets = this.filteredTickets.filter(ticket => ticket.status === this.selectedStatus)
        }
        if (this.selectedIntegration !== null) {
          this.filteredTickets = this.filteredTickets.filter(ticket => {
            if(ticket.integration === null) return false 
            else if (ticket.integration.id === this.selectedIntegration) return true
            return false
          })
        }
        if (this.selectedGame !== null) {
          this.filteredTickets = this.filteredTickets.filter(ticket => {
            if(ticket.game === null) return false 
            else if (ticket.game.id === this.selectedGame) return true
            return false
          })
        }
      },
      getStatusColor(status) {
        switch (status) {
          case 'open':
            return 'bg-red-500'
          case 'ongoing':
            return 'bg-orange-600'
          case 'closed':
            return 'bg-green-600'
          default:
            return 'bg-gray-500'
        }
      },
      clearDate(date) {
        const options = {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
        return new Date(date).toLocaleDateString(this.appLanguage.long, options)
      },
      async getAllTickets() {
        try {
          const tickets = (await request.get('/tickets')).data.reverse()
          for (const ticket of tickets) {
            if (ticket.game !== null) {
              if (ticket.game.sub_name) ticket.game.name = `${ticket.game.name} - ${ticket.game.sub_name}`
            }
          }
          this.tickets = tickets
          this.filteredTickets = [...this.tickets]
          this.ticketsKey++
          if (this.openTicket !== false) {
            this.openTicket = tickets.find(ticket => ticket.id == this.openTicket.id)
          }
        } catch (e) {
          console.log(e.message)
          this.$toast.error('Something went wrong, please try again')
        }
      },
    },
  }
</script>
<style scoped>
tr:nth-child(even) {
  background-color: rgb(39 39 42);
}

</style>