<template>
  <div class="text-white">
    <div class="w-full flex items-center justify-between pb-4">
      <h1 class="title">
        {{ $t('users.title') }}
      </h1>
      <div class="rounded shadow-xl overflow-hidden grid grid-cols-2 w-64">
        <button
          @click="action = 'manage'" 
          class="fugaz pt-1.5 pb-1 uppercase text-center h-full lg:text-lg"
          :class="action ==='manage' ? 'text-white  bg-orange-600' : 'text-white bg-zinc-800'"
        >
        {{ $t('users.manage') }}

        </button>
        <button
          @click="action = 'create'" 
          class="fugaz pt-1.5 pb-1 uppercase text-center h-full lg:text-lg"
          :class="
            action ==='create' ? 'text-white bg-orange-600' : 'text-white bg-zinc-800 '
          "
        >
        {{ $t('users.create') }}
        </button>
      </div>
      <!-- <div class="flex items-center gap-3">
        <button @click="action = 'manage'" class="rounded-l-sm flex items-center justify-center gap-1.5 fugaz uppercase text-xl hover:text-orange-600" :class="action == 'manage' ? 'bg-zinc-950 text-orange-600' : 'bg-zinc-950'">
          <span class="pt-0.5">{{ $t('users.manage') }}</span>
          !-- <font-awesome-icon :icon="['fas', 'user-gear']" class="h-4" /> --
        </button>
        <button @click="action = 'create'" class="rounded-r-sm flex items-center justify-center gap-1.5 fugaz uppercase text-xl hover:text-orange-600" :class="action == 'create' ? 'bg-zinc-950 text-orange-600' : 'bg-zinc-950'">
          <span class="pt-0.5">{{ $t('users.create') }}</span>
          !-- <font-awesome-icon :icon="['fas', 'user-plus']" class="h-4" /> --
        </button>
      </div> -->
    </div>
    <div v-if="ready" class="w-full flex flex-col items-start gap-4 pb-[65px] xl:pb-4">
      <div v-if="action == 'manage'" class="w-full flex flex-col gap-1 py-1 rounded-sm mt-2" :key="usersKey">
        <div class="flex w-full justify-between mb-1">
          <div class="flex items-center gap-2">
            <div v-if="userIntegration === null" class="w-full md:w-max" 
            :class="appLanguage.long == 'en-GB' ? 'min-w-[235px]' : 'min-w-[300px]'">
              <Multiselect
                mode="single"
                v-model="selectedIntegration"
                :options="allIntegrations"
                label="name"
                value-prop="id"
                :placeholder="$t('charts.specific.filterByIntegration')"
                :hide-selected="false"
                :caret="false"
                :close-on-select="false"
                :searchable="true"
                :multiple-label="values => `${values.length} Integrations selected`"
                :loading="!ready"
              />
            </div>
            <div class="w-full md:w-max" :class="appLanguage.long == 'en-GB' ? 'min-w-[235px]' : 'min-w-[260px]'">
              <Multiselect
                mode="single"
                v-model="selectedRole"
                :options="possibleRoles"
                label="name"
                value-prop="id"
                :placeholder="'Filter by role'"
                :hide-selected="false"
                :caret="false"
                :close-on-select="false"
                :searchable="true"
                :loading="!ready"
              />
            </div>
          </div>
          <button v-if="userIntegration === null" @click="syncUsers()" class="fugaz uppercase transition-all duration-200 flex items-center" :class="!syncing && 'hover:text-orange-600'">
            {{ syncing ? $t('users.syncing') : $t('users.syncUsers') }}
            <img v-if="syncing" src="../../assets/loading2.png" alt="loading icon"
              class="h-[20px] animate-spin ml-1"
            />
          </button>
        </div>
        <div v-for="(user, index) in currentUsers" 
          class="flex flex-col bg-zinc-900 rounded-md border transition-all duration-150" :class="user.expanded ? 'border-zinc-700' : 'border-zinc-800'"
        >
          <div @click="expandUser(user, index)" 
            class="pl-3 rounded-md relative w-full flex items-center transition-colors duration-200" 
            :class="[(user.expanded ? 'pr-12' : 'pr-3'), (!user.editing ? 'hover:bg-zinc-800 cursor-pointer' : 'bg-zinc-800')]"
          >
            <div class="flex flex-col py-2 items-start w-full">
              <div class="text-sm font-thin">
                {{ $t('users.username') }}
              </div>
              <div :class="user.editing && 'cursor-not-allowed'">
                {{ user.username }} {{ loggedUser.userId == user.id ? '(You)' : '' }}
                <span v-if="user.localOnly" class="text-red-500">(Local Only)</span>
                <span v-if="user.kcOnly" class="text-blue-500">(KC Only)</span>
              </div>
            </div>
            <div class="flex flex-col py-2 items-center w-full">
              <span class="text-sm font-thin">{{ $t('users.email') }}</span>
              <div v-if="!user.editing">
                <div v-if="user.email" class="flex items-center gap-1" :title="user.emailVerified ? 'Email verified' : 'Email not verified'">
                  {{ user.email }}
                  <font-awesome-icon v-if="user.emailVerified" :icon="['fas', 'check']" class="text-green-500" />
                  <font-awesome-icon v-else :icon="['fas', 'circle-exclamation']" class="text-yellow-500" />
                </div>
                <span v-else class="">N/A</span>
              </div>
              <div v-else>
                <input type="email" pattern=".+@example\.com" v-model="user.newEmail" class="bg-zinc-950 w-72 px-2 py-px rounded-md outline-none">
              </div>
            </div>
            <div class="flex flex-col py-2 items-center w-full">
              <span class="text-sm font-thin">{{ $t('users.role') }}</span>
              <div v-if="!user.editing">
                <span v-if="user.role" class="capitalize">{{ user.role }}</span>
                <span v-else class="">N/A</span>
              </div>
              <div v-else>
                <select v-model="user.newRole"class="bg-zinc-950 outline-none px-1 py-px rounded-md">
                  <option v-for="role in getPossibleUserRoles(user)" :selected="role == user.role" :value="role" :key="role">{{ role }}</option>
                </select>
              </div>
            </div>
            <div v-if="userIntegration === null" class="flex flex-col py-2 items-end w-full">
              <span class="text-sm font-thin">{{ $t('users.integration') }}</span>
              <span v-if="user.attributes?.integration[0]" class="">{{ user.attributes.integration[0] }}</span>
              <span v-else class="">N/A</span>
            </div>
            <button v-if="user.expanded && !user.localOnly" @click.stop="user.editing = !user.editing" class="absolute top-0 right-0 pl-2.5 pr-2 h-full flex items-center bg-zinc-700 hover:text-pink-700 rounded-md" :class="user.editing && 'text-orange-600'">
              <font-awesome-icon :icon="['fas', 'pen-to-square']" />
            </button>
            <button v-if="user.expanded && user.localOnly" @click="deletingLocalUser = user" class="absolute top-0 right-0 pl-2.5 pr-2 h-full flex items-center bg-zinc-700 hover:text-pink-700 rounded-md">
              <font-awesome-icon :icon="['fas', 'trash']" />
            </button>
          </div>
          <div v-if="!user.editing && user.expanded" class="px-2 border-t-2 border-zinc-950 py-0">
            <div v-if="!loadingUser" class="w-full flex items-center">
              <button v-if="user.sessions" @click="user.expandSessions = !user.expandSessions" class="flex flex-col py-1 items-center w-full group" 
              :class="user.sessions.length == 0 && 'pointer-events-none'">
                <span class="text-sm" :class="user.expandSessions ? 'font-normal text-orange-600' : 'font-thin group-hover:font-normal'">
                  {{ $t('users.sessions') }}
                </span>
                <span class="group-hover:font-semibold" :class="user.expandSessions && 'font-semibold'">{{ user.sessions.length }}</span>
              </button>
              <div class="flex flex-col py-1 items-center w-full">
                <span class="text-sm font-thin">{{ $t('users.createdAt') }}</span>
                <span class="">{{ clearDate(user.created_at) }}</span>
              </div>
            </div>
            <div v-else class="w-full py-1.5 flex justify-center">
              <img src="../../assets/loading2.png" alt="loading icon"
                class="h-[25px] animate-spin"
              />
            </div>
          </div>
          <div v-if="!user.editing && user.sessions && (user.expanded && user.expandSessions)" class="px-2 border-t-2 border-zinc-950 w-full flex items-center">
            <div class="w-full flex flex-wrap justify-center gap-1 py-2 cursor-default">
              <div v-for="session in user.sessions" class="rounded-md flex items-center gap-1.5 bg-zinc-800">
                <div class="flex flex-col bg-zinc-800 px-2 rounded-md">
                  <span class="font-thin">{{ $t('users.ipAddress') }}</span>
                  <span>{{ session.ipAddress }}</span>
                </div>
                <div class="flex flex-col bg-zinc-800 px-2 rounded-md">
                  <span class="font-thin">{{ $t('users.lastAccess') }}</span>
                  <span>{{ fullDate(session.lastAccess) }}</span>
                </div>
                <div class="flex flex-col bg-zinc-800 px-2 rounded-md">
                  <span class="font-thin">{{ $t('users.firstAccess') }}</span>
                  <span>{{ fullDate(session.start) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="user.editing" class="w-full flex justify-center pb-0.5 pt-1.5 text-sm">
            <button @click="updateUser(user, index)" 
              class="fugaz uppercase px-2 rounded-sm hover:text-orange-600"
              :class="(user.role === user.newRole && (user.email === user.newEmail || user.newEmail === '')) && 'pointer-events-none opacity-30'"  
            >
              {{ $t('users.saveUpdates') }}
            </button>
            <button 
              v-if="!user.deleting"
              @click="user.deleting = !user.deleting"
              class="fugaz uppercase px-2 rounded-sm hover:text-red-600 transition-all duration-150"
            >
              {{ $t('users.deleteUser') }}
            </button>
            <div v-else class="flex items-center gap-1.5 fugaz uppercase">
              {{ $t('users.areYouSure') }}
              <button @click="deleteUser(user)" class="uppercase hover:text-orange-600">
                {{ $t('users.yes') }}
              </button>
              <button @click="user.deleting = false" class="uppercase hover:text-orange-600">
                {{ $t('users.no') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="usersToDelete.length > 0" 
        @click="usersToDelete = []"
        class="w-full h-full flex justify-center items-center bg-black bg-opacity-70 fixed top-0 left-0 z-[999]"
      >
        <div @click.stop class="bg-zinc-800 rounded-md flex flex-col items-center">
          <div class="px-2.5 pb-2 pt-3 border-b border-zinc-700 w-full text-center fugaz uppercase">
            There is {{ usersToDelete.length }} local users to be deleted
          </div>
          <div class="flex w-full flex-wrap justify-center gap-2 px-4 py-4 border-b border-zinc-700">
            <section v-for="user in usersToDelete" class="flex flex-col gap-1 bg-zinc-900 h-max p-1 rounded-md">
              <div class="bg-zinc-700 px-2 rounded-sm font-light">
                {{ $t('users.username') }}: <strong>{{ user.username }}</strong>
              </div>
              <div class="bg-zinc-700 px-2 rounded-sm font-light" v-if="user.integration">
                {{ $t('users.integration') }}: <strong>{{ user.integration.name }}</strong>
              </div>
              <div class="bg-zinc-700 px-2 rounded-sm font-light">
                {{ $t('users.email') }}: <strong>{{ user.email }}</strong>
              </div>
              <div class="bg-zinc-700 px-2 rounded-sm font-light">
                {{ $t('users.role') }}: <strong>{{ user.role }}</strong>
              </div>
            </section>
          </div>
          <div class="flex w-full justify-center items-center gap-1.5 px-6 py-2">
            {{ $t('users.sureThese') }}
            <div class="flex items-center gap-1.5 pl-1">
              <button @click="syncUsers(true)" class="fugaz uppercase px-0.5 pt-0.5 rounded-md hover:text-orange-600">Yes</button>
              <button @click="usersToDelete = []" class="fugaz uppercase px-0.5 pt-0.5 rounded-md hover:text-orange-600">No</button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="deletingLocalUser !== null" 
        @click="deletingLocalUser = null"
        class="w-full h-full flex justify-center items-center bg-black bg-opacity-70 fixed top-0 left-0 z-[999]"
      >
        <div @click.stop class="bg-zinc-800 rounded-md flex flex-col items-center">
  
          <div class="flex w-full flex-wrap justify-center gap-2 px-4 py-3 border-b border-zinc-700">
            <section class="flex flex-col gap-1 bg-zinc-900 h-max p-1 rounded-md">
              <div class="bg-zinc-700 px-2 rounded-sm font-light">
                {{ $t('users.username') }}: <strong>{{ deletingLocalUser.username }}</strong>
              </div>
              <div class="bg-zinc-700 px-2 rounded-sm font-light" v-if="deletingLocalUser.integration">
                {{ $t('users.integration') }}: <strong>{{ deletingLocalUser.integration.name }}</strong>
              </div>
              <div class="bg-zinc-700 px-2 rounded-sm font-light">
                {{ $t('users.email') }}: <strong>{{ deletingLocalUser.email }}</strong>
              </div>
              <div class="bg-zinc-700 px-2 rounded-sm font-light">
                {{ $t('users.role') }}: <strong>{{ deletingLocalUser.role }}</strong>
              </div>
            </section>
          </div>
          <div class="flex w-full justify-center items-center gap-1.5 px-6 py-2">
            {{ $t('users.sureThis') }}
            <div class="flex items-center gap-1.5 pl-1">
              <button @click="deleteUser(user)" class="fugaz uppercase px-0.5 pt-0.5 rounded-md hover:text-orange-600">Yes</button>
              <button @click="deletingLocalUser = null" class="fugaz uppercase px-0.5 pt-0.5 rounded-md hover:text-orange-600">No</button>
            </div>
          </div>
        </div>
      </div>
      <NewUser v-if="action == 'create'" :getPossibleUserRoles="getPossibleUserRoles" :checkValidEmail="checkValidEmail" :allUsers="allUsers" />
    </div>
    <div
      v-else
      class="w-full h-[90vh] flex justify-center items-center bg-zinc-950 text-white"
    >
      <img v-if="!serverError" src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
      <div v-else>
        {{ $t('serverError') }}
        <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
        {{ $t('tryAgain') }}
      </div>
    </div>
  </div>
</template>
<script>
import request from '@/services/axios';
import Multiselect from '@vueform/multiselect'
import NewUser from '@/components/users/NewUser.vue';

  export default {
    data () {
      return {
        action: 'manage',
        allUsers: [],
        currentUsers: [],
        allIntegrations: [],
        selectedIntegration: null,
        selectedRole: null,
        ready: false,
        syncing: false,
        serverError: false,
        usersKey: 0,
        // syncWaitingConfirmation: false,
        usersToDelete: [],
        deletingLocalUser: null,
        loadingUser: false,
        mountTimer: false,
      }
    },
    components: {
      Multiselect,
      NewUser
    },
    async mounted() {
      this.mountTimer = setTimeout(async () => {
        await this.getAllUsers()
        this.currentUsers = this.allUsers
        this.ready = true
      }, 500);
    },
    beforeUnmount() {
      clearTimeout(this.mountTimer);
    },
    computed: {
      possibleRoles() {
        if(this.userIntegration === null) {
          return [
            'ludus_admin',
            'ludus_manager',
            'integration_admin',
            'integration_manager',
            'integration_guest',
          ]
        } else {
          return [
            'integration_admin',
            'integration_manager',
            'integration_guest',
          ]
        }
      },
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
      loggedUser() {
        return this.$store.getters['user/user']
      },
      userIntegration() {
        if (this.loggedUser.attributes?.integration) {
          return this.loggedUser.attributes.integration[0]
        } else return null
      },
    },
    watch: {
      selectedIntegration() {
        this.filterUsers()
      },
      selectedRole() {
        this.filterUsers()
      },
      async action() {
        if (this.action == 'manage') {
          await this.getAllUsers()
          this.currentUsers = [...this.allUsers]
          this.filterUsers()
          this.usersKey++
        }
      },
    },
    methods: {
      getPossibleUserRoles(user) {
        if(user.attributes === undefined || Object.keys(user.attributes).length === 0) {
          return [
            'ludus_admin',
            'ludus_manager',
          ]
        } else {
          return [
            'integration_admin',
            'integration_manager',
            'integration_guest',
          ]
        }
      },
      filterUsers() {
        for (const user of this.currentUsers) {
          user.expanded = false
          user.editing = false
          user.expandSessions = false
          user.deleting = false
        } 
        this.currentUsers = this.allUsers.filter(user => {
          if(this.selectedIntegration !== null) {
            if(user.attributes?.integration[0] !== this.selectedIntegration) return false
          }
          if(this.selectedRole !== null) {
            if(user.role !== this.selectedRole) return false
          }
          return true
        }) 
      },
      checkValidEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email)
      },
      async syncUsers(confirmation = false) {
        this.syncing = true
        try {
          const response = (await request.get(`/users/kc/sync/${confirmation}`)).data
          if (response.usersToDelete.length > 0) {
            this.syncWaitingConfirmation = true
            this.usersToDelete = response.usersToDelete
          }
          await this.getAllUsers()
          this.currentUsers = [...this.allUsers]
          this.filterUsers()
          this.usersKey++
          if(confirmation) {
            this.usersToDelete = []
            this.$toast.open(
              {
                // message: this.$t('toast.usersSyncedAndDeleted'),
                message: 'Users synced and deleted',
                type:'success'
              }
            )
          } else {
            this.$toast.open(
              {
                // message: this.$t('toast.usersSynced'),
                message: 'Users synced',
                type:'success'
              }
            )
          }
        } catch (error) {
          console.log(error)
        }
        this.syncing = false
      },
      async expandUser(user, index) {
        if(user.editing) return
        this.loadingUser = true
        if(!user.expanded) {
          for (const user of this.currentUsers) {
            user.expanded = false
            user.editing = false
            user.expandSessions = false
            user.deleting = false
          }
          this.currentUsers[index].expanded = true
          let enhancedUser = {}
          if (!user.localOnly) {
            enhancedUser = await this.getUserInfo(user.keycloakUserId)
            if(enhancedUser.sessions) {
              enhancedUser.sessions = enhancedUser.sessions.map(session => {
                return {
                  ...session,
                  start: new Date(session.start),
                  lastAccess: new Date(session.lastAccess)
                }
              })
            }
          }
          this.currentUsers[index] = {
            ...user,
            ...enhancedUser,
          }
        } else {
          this.currentUsers[index].expanded = false
        }
        this.loadingUser = false
      },
      async deleteUser(user) {
        try {
          if(user.localOnly) {
            await request.delete(`/user/${user.id}`)
          } else {
            await request.delete(`/user/kc/${user.id}`)
          }
          const currentUsersIndex = this.currentUsers.findIndex(u => user.id === u.id)
          const allUsersIndex = this.allUsers.findIndex(u => user.id === u.id)
          this.currentUsers.splice(currentUsersIndex, 1)
          this.allUsers.splice(allUsersIndex, 1)
          this.$toast.open(
            {
              // message: this.$t('toast.userUpdated'),
              message: `User ${user.username} deleted successfully`,
              type:'success'
            }
          )
        } catch (error) {
          console.log(error)
        }
      },
      async updateUser(user, index) {
        try {
          if (!this.checkValidEmail(user.newEmail)) {
            this.$toast.open(
              {
                // message: this.$t('toast.invalidEmail'),
                message: 'Invalid email address',
                type: 'error'
              }
            )
            return
          }
          const updatedUser = (await request.put(`/user/kc/${user.id}`, {
            userAttributes: {
              email: user.newEmail,
            }, 
            clientRolesToAdd: [user.newRole],
            clientRolesToRemove: [user.role]
          })).data
          
          this.currentUsers[index] = {
            ...updatedUser,
            newEmail: updatedUser.email ? updatedUser.email : '',
            newRole: updatedUser.role ? updatedUser.role : '',
            editing: false,
            expanded: user.expanded,
            expandSessions: user.expandSessions
          }

          const allUsersIndex = this.allUsers.findIndex(u => user.id == u.id)
          this.allUsers[allUsersIndex] = {
           ...this.currentUsers[index]
          }
          this.$toast.open(
            {
              // message: this.$t('toast.userUpdated'),
              message: `User ${user.username} updated successfully`,
              type:'success'
            }
          )
        } catch (error) {
          console.log(error)
        }
      },
      async getUserInfo(userId) {
        try {
          const user = (await request.get(`/user/kc/${userId}`)).data
          return user
        } catch (error) {
          console.log(error)
        }
      },
      async getAllUsers() {
        try {
          this.allUsers = (await request.get('/user/kc/list')).data.map(user => {
            if (user.attributes?.integration[0] && !this.allIntegrations.includes(user.attributes?.integration[0])) {
              this.allIntegrations.push(user.attributes?.integration[0])
            }
            return {
              ...user,
              expanded: false,
              expandSessions: false,
              editing: false,
              deleting: false,
              newEmail: user.email ? user.email : '',
              newRole: user.role ? user.role : '',
            }
          })
        } catch (error) {
          console.log(error)
        }
      },
      clearDate(date) {
        const inputDate = new Date(date);

        const options = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        return inputDate.toLocaleDateString(this.appLanguage.long, options);
      },
      fullDate(date) {
        const inputDate = new Date(date);

        const options = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric"
        };
        return inputDate.toLocaleTimeString(this.appLanguage.long, options);
      },
    },
  }
</script>
<style scoped>

</style>