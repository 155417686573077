<template>
    <div class="filter__filter-by">
        <p class="text-zinc-300 whitespace-nowrap text-sm mb-0.5">Filter by</p>
        <div class="flex items-center justify-center gap-3">
            <div
                class="w-full md:w-max whitespace-nowrap rounded-md"
                :class="{
                  'min-w-[190px]': chartType === 'totals' && appLanguage.long == 'en-GB',
                  'min-w-[270px]': chartType === 'totals' && appLanguage.long == 'it-IT',
                  'min-w-[220px]': chartType === 'specific' && appLanguage.long == 'en-GB',
                  'min-w-[270px]': chartType === 'specific' && appLanguage.long == 'it-IT'
                }"
            >
              <Multiselect
                mode="multiple"
                :model-value="selectedGames"
                @update:modelValue="updateSelectedGames"
                :options="allGames"
                label="name"
                value-prop="id"
                :placeholder="$t(`charts.${chartType}.filterByGame`)"
                :hide-selected="false"
                :caret="false"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :multiple-label="gamesLabel"
                :loading="!ready"
              />
            </div>
            <button
              v-if="chartType === 'specific'"
              @click="$emit('selectAllGames')"
              :class="allGamesSelected ? 'text-orange-600' : 'text-white xl:hover:text-orange-600'"
              class="px-2 pb-1 pt-1.5 rounded-md fugaz bg-zinc-700 uppercase -ml-1">
                all
            </button>
            <div
                v-if="(chartType === 'totals' && !userIntegration) || (chartType === 'specific' && userRoles.isLudusAdmin || userRoles.isLudusManager)"
                class="w-full md:w-max whitespace-nowrap rounded-md"
                :class="{
                  'min-w-[220px]': chartType === 'totals' && appLanguage.long == 'en-GB',
                  'min-w-[280px]': chartType === 'totals' && appLanguage.long == 'it-IT',
                  'min-w-[221px]': chartType === 'specific' && appLanguage.long == 'en-GB',
                  'min-w-[281px]': chartType === 'specific' && appLanguage.long == 'it-IT'
                }"
            >
            <!-- 'min-w-[280px]': chartType === 'totals' && appLanguage.long == 'it-IT',
            'min-w-[220px]': chartType === 'specific' && appLanguage.long == 'en-GB',
            'min-w-[280px]': chartType === 'specific' && appLanguage.long == 'it-IT' -->
              <Multiselect
                mode="multiple"
                :model-value="selectedIntegrations"
                @update:modelValue="updateSelectedIntegrations"
                :options="actualIntegrations"
                label="name"
                value-prop="id"
                :placeholder="$t(`charts.${chartType}.filterByIntegration`)"
                :hide-selected="false"
                :caret="false"
                :close-on-select="false"
                :searchable="true"
                :create-option="true"
                :multiple-label="(values) => `${values.length} Integrations selected`"
                :loading="!ready"
              />
            </div>
            <button
              v-if="chartType === 'specific' && (userRoles.isLudusAdmin || userRoles.isLudusManager)"
              @click="$emit('selectAllIntegrations')"
              :class="allIntegrationsSelected ? 'text-orange-600' : 'text-white xl:hover:text-orange-600'"
              class="px-2 pb-1 pt-1.5 rounded-md fugaz bg-zinc-700 uppercase -ml-1">
                all
            </button>
            <div
              class="w-full md:w-max whitespace-nowrap rounded-md"
              :class="{
                'min-w-[150px]': chartType === 'totals' && appLanguage.long == 'en-GB',
                'min-w-[280px]': chartType === 'totals' && appLanguage.long == 'it-IT',
                'min-w-[151px]': chartType === 'specific' && appLanguage.long == 'en-GB',
                'min-w-[281px]': chartType === 'specific' && appLanguage.long == 'it-IT',
              }"
            >
            <Multiselect
              mode="multiple"
              :model-value="selectedOperators"
              @update:modelValue="updateSelectedOperators"
              :options="actualOperators"
              label="name"
              value-prop="id"
              :placeholder="$t(`charts.${chartType}.filterByOperator`)"
              :hide-selected="false"
              :caret="false"
              :close-on-select="false"
              :searchable="true"
              :create-option="true"
              :multiple-label="(values) => `${values.length} Operators selected`"
              :loading="!ready"
            />
            </div>
            <button
              v-if="chartType === 'specific'"
              @click="$emit('selectAllOperators')"
              :class="allOperatorsSelected ? 'text-orange-600' : 'text-white xl:hover:text-orange-600'"
              class="px-2 pb-1 pt-1.5 rounded-md fugaz bg-zinc-700 uppercase -ml-1">
                all
            </button>
        </div>
    </div>
  </template>
  
  <script>
    import Multiselect from "@vueform/multiselect";

    export default {
    name: 'FilterFilterBy',
    components: { Multiselect },
    props: {
      selectedGames: {
        type: Array,
        required: true
      },
      selectedIntegrations: {
        type: Array,
        required: true
      },
      selectedOperators: {
        type: Array,
        required: true
      },
      ready: Boolean,
      userIntegration: Boolean,
      actualOperators: Array,
      actualIntegrations: Array,
      allGames: Array,
      chartType: String,
      allGamesSelected: {
        type: Boolean,
        default: false
      },
      allIntegrationsSelected: {
        type: Boolean,
        default: false
      },
      allOperatorsSelected: {
        type: Boolean,
        default: false
      },
    },
    computed: {
      appLanguage() {
        return this.$store.getters["getLanguage"];
      },
      user() {
        return this.$store.getters["user/user"];
      },
      userRoles() {
        return this.user.localRoles;
      },
    },
    methods: {
      gamesLabel() {
        return this.allGamesSelected ? "All Games Selected" : `${(this.selectedGames || []).length} Games selected`;
      },
      updateSelectedGames(value) {
        this.$emit("update:selectedGames", value);
      },
      updateSelectedIntegrations(value) {
        this.$emit("update:selectedIntegrations", value);
      },
      updateSelectedOperators(value) {
        this.$emit("update:selectedOperators", value);
      }
    }
  };
  </script>
  
  <style lang="postcss" scoped>
  .filter__filter-by {
    @apply text-zinc-300 whitespace-nowrap text-sm bg-zinc-850 px-2 py-1 rounded-md h-16;
  }
  </style>

<style lang="postcss" >
.multiselect {
    min-height: fit-content !important;
    /* height: 4.2rem !important; */
    margin-top: -0.15rem !important;
    border: 1px solid #2d2d2d !important;
}

.multiselect-wrapper {
    min-height: 2.15rem !important;
    height: 2.15rem !important;
    @apply !rounded-md;
}

.multiselect-dropdown {
    border: 1px solid #2d2d2d !important;
}
</style>
  