<template>
  <div v-if="ready" class="w-full h-full bg-zinc-800 rounded-b-md flex flex-col gap-2 p-2">
    <div class="flex flex-col md:flex-row justify-between items-center relative py-2 px-2 bg-zinc-950 rounded-md">
      <div class="flex items-center justify-start gap-3 w-full">
        <h4 class="text-white fugaz uppercase pl-1.5">{{ $t('manageGames.integration') }}</h4>
        <div class="w-full md:w-[250px] max-w-full border-zinc-800 rounded-sm">
          <Multiselect
            mode="single"
            v-model="selectedintegrationId"
            :options="allIntegrations"
            :close-on-select="true"
            :searchable="true"
            label="name"
            value-prop="id"
            :placeholder="$t('manageGames.selectIntegration')"
            ref="multiselect"
          />
        </div>
      </div>
      <div v-show="hasChanges" class="w-full flex justify-center md:justify-end pt-2.5 md:pt-0">
        <button v-if="!saving" @click="saveChanges" class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-opacity-50 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50">
          {{ $t('manageGames.saveChanges') }}
        </button>
        <div v-else class="text-white fugaz uppercase px-3">
          {{ $t('manageGames.saving') }}
        </div>
      </div>
    </div>
    <div v-if="selectedintegrationId" class="flex flex-col xl:flex-row gap-1.5 xl:gap-0 items-center justify-between h-full w-full">
      <div
        class="h-full w-full flex flex-col items-center rounded-md xl:rounded-none xl:rounded-l-md bg-zinc-950 pb-3">
        <div class="w-full flex justify-center">
          <h3 class="text-white fugaz uppercase py-2">{{ $t('manageGames.notVisible') }}</h3>\
        </div>
        <div v-if="notVisibleGames.length > 0" :class="`flex flex-col gap-1.5 text-white px-2.5 h-full w-full xl:max-h-[600px] xl:overflow-y-auto`"
        >
          <div v-for="game in notVisibleGames" class="py-3.5 px-4 bg-zinc-800 fugaz uppercase cursor-default text-center relative">
            <button @click="addGame(game)" class="group px-4 py-1 rounded-sm bg-green-600 xl:hover:bg-green-500 absolute top-0 bottom-0 my-auto right-0 uppercase">
              <font-awesome-icon :icon="['fas', 'right-long']" class="hidden xl:block" />
              <font-awesome-icon :icon="['fas', 'down-long']" class="block xl:hidden" />
            </button>
            {{ game.name }}
          </div>
        </div>
        <div v-if="notVisibleGames.length === 0 && selectedintegrationId !== null" class="text-white">
          {{ $t('manageGames.empty') }}
        </div>
      </div>
      <div
        class="h-full w-full flex flex-col items-center rounded-md xl:rounded-none bg-zinc-950 pb-3 xl:border-x border-zinc-800">
        <div class="w-full flex justify-center">
          <h3 class="text-white fugaz uppercase py-2">{{ $t('manageGames.viewing') }}</h3>
        </div>
        <div v-if="visibleGames.length > 0" :class="`flex flex-col gap-1.5 text-white px-2.5 h-full w-full xl:max-h-[600px] xl:overflow-y-auto`"  
        >
          <div v-for="game in visibleGames" class="py-3.5 px-4 bg-zinc-800 fugaz uppercase cursor-default text-center relative">
            <button @click="removeGame(game)" class="group px-4 py-1 rounded-sm bg-red-600 xl:hover:bg-red-500 absolute top-0 bottom-0 my-auto right-0 xl:right-auto xl:left-0 uppercase">
              <font-awesome-icon :icon="['fas', 'left-long']" class="hidden xl:block" />
              <font-awesome-icon :icon="['fas', 'up-long']" class="block xl:hidden" />
            </button>
            <span v-if="game.toAdd" class="text-green-500 absolute top-0 left-1 xl:left-auto xl:right-1 text-xs">
              {{ $t('manageGames.new') }}
            </span>
            <span v-if="game.toAdd" class="text-white flex items-center gap-1 absolute bottom-0 left-1 xl:left-auto xl:right-1 text-xs">
              <span class="pr-0.5">{{ $t('manageGames.notify') }}</span>
              <button 
                @click="addGameToNotify(game.id)" 
                class="fugaz uppercase"
                :class="gamesToNotify.includes(game.id) ? 'text-green-500' : 'text-zinc-500'"
              >
                {{ $t('manageGames.yes') }}
              </button>
              <button 
                @click="removeGameToNotify(game.id)" 
                class="fugaz uppercase"
                :class="!gamesToNotify.includes(game.id) ? 'text-red-500' : 'text-zinc-500'"
              >
                {{ $t('manageGames.no') }}
              </button>
            </span>
            {{ game.name }}
          </div>
        </div>
        <div v-if="visibleGames.length === 0 && selectedintegrationId !== null" class="text-white">
          {{ $t('manageGames.empty') }}
        </div>
      </div>
      <div
        class="h-full w-full flex flex-col items-center rounded-md xl:rounded-none xl:rounded-r-md bg-zinc-950 pb-3">
        <div class="w-full flex justify-center">
          <h3 class="text-white fugaz uppercase py-2">{{ $t('manageGames.owned') }}</h3>
          <!-- <span class="text-sm fugaz uppercase text-white">(active)</span> -->
        </div>
        <div v-if="activeGames.length > 0" :class="`flex flex-col gap-1.5 text-white px-2.5 h-full w-full xl:max-h-[600px] xl:overflow-y-auto`"
        >
          <div v-for="game in activeGames" class="py-3.5 px-4 bg-zinc-800 fugaz uppercase cursor-default text-center">
            {{ game.name }}
          </div>
        </div>
        <div v-if="activeGames.length === 0 && selectedintegrationId !== null" class="text-white">
          {{ $t('manageGames.empty') }}
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="w-full h-full flex justify-center items-center gap-3 text-zinc-600 dark:text-zinc-400"
  >
    <img v-if="!serverError" src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-16 w-auto animate-pulse">
    <img
      v-if="!serverError"
      src="../../assets/loading2.png"
      alt="loading icon"
      class="h-12 animate-spin mb-2"
    />
    <div v-else>
      {{ $t('serverError') }}
      <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
      {{ $t('tryAgain') }}
    </div>
  </div>
</template>
<script>
  import request from '@/services/axios'
  import Multiselect from '@vueform/multiselect'

  export default {
    data() {
      return {
        selectedIntegration: null,
        selectedintegrationId: null,
        notVisibleGames: [],
        visibleGames: [],
        gamesToAdd: [],
        gamesToRemove: [],
        ready: false,
        serverError: false,
        selectedType: 'notVisible',
        saving: false,
        gamesToNotify: [],
      }
    },
    props: {
      allGames: Array,
      allIntegrations: Array,
      sortByName: Function,
    },
    components: {
      Multiselect,
    },
    async created() {
      this.ready = true
    },
    watch: {
      selectedintegrationId: {
        async handler() {
          this.updateGames()
          this.$refs.multiselect.blur()
        },
      },
    },
    computed: {
      hasChanges() {
        return this.gamesToAdd.length > 0 || this.gamesToRemove.length > 0
      },
      activeGames() {
        return this.selectedIntegration ? this.selectedIntegration?.games.filter(game => game.integration_games.is_active !== null) : []
      },
    },
    methods: {
      addGameToNotify(gameId) {
        this.gamesToNotify.push(gameId)
      },
      removeGameToNotify(gameId) {
        this.gamesToNotify = this.gamesToNotify.filter(id => id !== gameId)
      },
      resetInfo() {
        this.selectedIntegration = null
        this.gamesToNotify = []
        this.notVisibleGames = []
        this.visibleGames = []
        this.gamesToAdd = []
        this.gamesToRemove = []
      },
      addGame(game) {
        if(game.toRemove) {
          const index = this.gamesToRemove.indexOf(game.id)
          this.gamesToRemove.splice(index, 1)
          this.visibleGames.push(game)
        } else {
          this.gamesToAdd.push(game.id)
          this.visibleGames.push({
            ...game,
            toAdd: true
          })
        }
        const index = this.notVisibleGames.map(g => g.id).indexOf(game.id)
        this.notVisibleGames.splice(index, 1)
        
        this.notVisibleGames.sort(this.sortByName)
        this.visibleGames.sort(this.sortByName)
      },
      removeGame(game) {
        if(game.toAdd) {
          const index = this.gamesToAdd.indexOf(game.id)
          this.gamesToAdd.splice(index, 1)
          this.notVisibleGames.push(game)
        } else {
          this.gamesToRemove.push(game.id)
          this.notVisibleGames.push({
            ...game,
            toRemove: true
          })
        }
        const index = this.visibleGames.map(g => g.id).indexOf(game.id)
        this.visibleGames.splice(index, 1)
        this.notVisibleGames.sort(this.sortByName)
        this.visibleGames.sort(this.sortByName)
      },
      updateGames() {
        if(this.selectedintegrationId) {
          this.resetInfo()
          this.selectedIntegration = {...this.allIntegrations.find(integration => integration.id === this.selectedintegrationId)}
          this.visibleGames = [...this.selectedIntegration.games.filter(game => game.integration_games.is_active === null)]
            .map(game => {
              return {
                ...game,
                name: game.sub_name ? `${game.name} - ${game.sub_name}` : game.name
              }
            })
          const availableGamesIds = this.visibleGames.map(game => game.id)
          const activeGamesIds = this.activeGames.map(game => game.id)
          this.notVisibleGames = this.allGames.filter(game => !availableGamesIds.includes(game.id) && !activeGamesIds.includes(game.id) && game.isCatalog)
          this.notVisibleGames.sort(this.sortByName)
          this.visibleGames.sort(this.sortByName)
        } else {
          this.resetInfo()
        }
      },
      async sendGameEmails() {
        try {
          const response = await request.post(`/notify-integration-games/${this.selectedintegrationId}`, { gameIds: this.gamesToNotify })
          this.$toast.success(response.data)
        } catch (e) {
          console.log(e)
          this.$toast.error("Error sending emails")
        }
      },
      async saveChanges() {
        this.saving = true
        const ids = this.selectedIntegration.games.filter(game => !this.gamesToRemove.includes(game.id)).map(game => game.id)
        const newIds = ids.concat(this.gamesToAdd)
        try {
          const response = await request.post(`/integrations/${this.selectedintegrationId}/games`, {
            games: newIds
          })
          // console.log(response.data)
          this.selectedIntegration.games = response.data
          const index = this.allIntegrations.map(p => p.id).indexOf(this.selectedIntegration.id)
          this.allIntegrations.splice(index, 1, this.selectedIntegration)
          if (this.gamesToNotify.length > 0) {
            console.log('sending emails')
            await this.sendGameEmails()
          }
          this.updateGames()
          // this.games
          this.$toast.success(`${this.selectedIntegration.name} - ${this.$t('manageGames.gamesUpdated')}`)
          this.emitter.emit('integrationGamesUpdated');
        } catch (e) {
          console.log(e)
          this.$toast.error(e.message)
        }
        this.saving = false
      },
    },
  }

</script>
<style scoped>

</style>