<template>
    <!-- <div class="filter__group-by">
        <p class="text-zinc-300 whitespace-nowrap text-sm mb-0.5">Group by</p>
        <div class="flex items-center gap-1.5 py-1 mb-0.5">
            <button
        
        @click="updateVar('hour')"
            class="h-9 px-2 rounded-md fugaz uppercase text-sm"
            :class="
                modelValue == 'hour'
                ? 'bg-zinc-700 text-orange-600'
                : 'bg-zinc-700 text-white xl:hover:bg-opacity-80'
            "
            >
                {{ $t("charts.hour") }}
            </button>
            <button
            @click="updateVar('day')"
            class="h-9 px-2 rounded-md fugaz uppercase text-sm disabled:opacity-25 disabled:cursor-not-allowed"
            :class="
                modelValue == 'day'
                ? 'bg-zinc-700 text-orange-600'
                : 'bg-zinc-700 text-white xl:hover:bg-opacity-80'
            "
            >
                {{ $t("charts.day") }}
            </button>
            <button
            @click="updateVar('month')"
            class="h-9 px-2 rounded-md fugaz uppercase text-sm disabled:opacity-25 disabled:cursor-not-allowed"
            :class="
                modelValue == 'month'
                ? 'bg-zinc-700 text-orange-600'
                : 'bg-zinc-700 text-white xl:hover:bg-opacity-80'
            "
            >
                {{ $t("charts.month") }}
            </button>
        </div>
    </div> -->
    <div class="bg-zinc-850 px-2 py-1 rounded-md h-full">
      <p class="text-zinc-300 whitespace-nowrap text-sm mb-0.5">Group by</p>
      <div class="dropdown" ref="dropdown">
        <div class="dropdown-toggle" @click="toggleDropdown">
            <font-awesome-icon v-if="selectedOption.icon" :icon="['fas', selectedOption.icon]" class="h-4 mr-1 text-orange-600" />
            {{ $t(`charts.${selectedOption.value}`) }}
            <font-awesome-icon :icon="['fas', 'sort-down']" class="absolute right-2 top-1.5"/>
        </div>
        <ul v-if="isOpen" class="dropdown-menu">
          <li 
            v-for="option in options" 
            :key="option.value" 
            @click="selectOption(option)"
            :class="['pl-2.5 pr-6 capitalize', {selected: option.value === modelValue }]"
            >
            {{ $t(`charts.${option.value}`) }}
          </li>
        </ul>
      </div>
   </div>
</template>

<script>

export default {
    name: 'FilterGroupBy',

    data() {
        return {
            options: [
                {
                    icon: 'clock',
                    value: 'hour'
                },
                {
                    icon: 'calendar',
                    value: 'day'
                },
                {
                    icon: 'calendar-alt',
                    value: 'month'
                },

            ],
            isOpen: false
        }
    },

    computed: {
      selectedOption() {
        const selected = this.options.find(opt => opt.value === this.modelValue);
        return selected ? selected : this.options[0];
      }
    },

    props: {
        modelValue: {
            type: [String],
            required: true
        },
    },

    methods: {
        toggleDropdown() {
            this.isOpen = !this.isOpen;
        },
        selectOption(option) {
            this.$emit("update:modelValue", option.value);
            this.isOpen = false;
        },
        handleClickOutside(event) {
            if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
                this.isOpen = false;
            }
        }
    },

    created() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeUnmount() {
      document.removeEventListener("click", this.handleClickOutside);
    }
}
</script>

<style lang="postcss" scoped>
.filter__group-by {
    @apply text-zinc-300 whitespace-nowrap text-sm bg-zinc-850 px-2 py-1 rounded-md;
}

.dropdown {
    /* border-top: 1px solid rgb(234,88,12);
    border-bottom: 1px solid #2d2d2d;
    border-left: 1px solid #2d2d2d;
    border-right: 1px solid #2d2d2d; */
    border: 1px solid #2d2d2d;
    width: 7.15rem;
    @apply relative h-9 roboto rounded-md;
  }
  
  .dropdown-toggle {
    @apply cursor-pointer h-full bg-[#18181b] capitalize flex items-center gap-x-1 pr-6 pl-2.5 pt-0.5 rounded-md;
  }
  
  .dropdown-menu {
    position: absolute;
    width: 100%;
    border: 1px solid #2d2d2d;
    /* margin-top: 5px; */
    /* list-style: none; */
    /* padding: 0; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    @apply  bg-[#18181b];
  }
  
  .dropdown-menu li {
    /* padding: 10px; */
    cursor: pointer;
  }
  
  .dropdown-menu li:hover,
  .dropdown-menu li.selected {
    @apply  bg-zinc-800;
  }
</style>