<template>
  <div
    v-if="ready"
    class="w-full min-h-full h-max flex flex-col items-center bg-zinc-200 dark:bg-zinc-950 relative pb-[49px] xl:pb-0"
  >
    <button
      class="hidden xl:block fixed bottom-[50px] xl:bottom-0 text-white xl:hover:text-orange-600 transition-all duration-100 bg-zinc-950 fugaz uppercase px-2.5 pt-0.5 rounded-tr-lg z-[99] border-t border-r border-zinc-700"
      :class="sidebarFixed ? 'left-0 xl:left-72' : 'left-0 xl:left-20'"
      @click="$router.push({ name: 'Catalog' })"
    >
      <font-awesome-icon
        :icon="['fas', 'backward']"
        class="h-4 pr-1"
      />
      Catalog
    </button>
    <div class="w-full h-max relative">
      <div
        class="absolute fugaz max-w-[400px] bottom-[12%] md:bottom-[25%] flex flex-col gap-1"
        :class="sidebarFixed ? 'left-3 md:left-[11%]' : 'left-3 md:left-[13%]'"
      >
        <div class="flex flex-col font-thin">
          <h1 class="text-white text-xl sm:text-2xl lg:text-5xl uppercase -mb-2">HUB</h1>
          <h1 class="text-white text-xl sm:text-2xl lg:text-5xl uppercase -mb-1.5">ONLINE GAMES</h1>
        </div>
        <div v-if="game.isDemo">
          <button
            @click="playDemo"
            class="w-full hidden xl:flex justify-center items-center py-2 sm:py-3 md:py-4 bg-orange-600 text-white transition-colors duration-150 xl:hover:bg-purple-600 rounded-sm"
          >
            <span class="text-base sm:text-lg md:text-xl uppercase">
              Play demo
            </span>
          </button>
          <button
            @click="mobilePlayDemo"
            class="w-full flex xl:hidden justify-center items-center px-4 py-2 sm:py-3 md:py-4 bg-orange-600 text-white transition-colors duration-150 xl:hover:bg-purple-600 rounded-sm"
          >
            <span class="text-sm sm:text-lg md:text-xl uppercase">
              Play demo
            </span>
          </button>
        </div>
        <div v-else>
          <button class="w-full flex justify-center items-center py-2 sm:py-3 md:py-4 bg-zinc-600 text-white cursor-default rounded-sm">
            <span class="text-xs md:text-base lg:text-xl uppercase">
              Demo unavailable
            </span>
          </button>
        </div>
      </div>
      <div
        v-if="showOnWishlist"
        class="absolute top-0 right-0 bg-orange-600 px-6 py-2 text-lg rounded-bl-xl uppercase text-white"
      >
        on wishlist
      </div>
      <!-- <div
        v-if="!game.providerId"
        class="absolute top-0 left-0 bg-orange-600 px-6 py-2 text-lg rounded-br-xl uppercase text-white"
      >
        in development
      </div> -->
      <img
        :src="bannerImage"
        alt="game image"
        class="w-full object-cover object-right h-[12rem]" 
        :class="[
          (sidebarFixed ? 'lg:h-[39rem]' : 'lg:h-[42rem]'),
          (screenHeight > 640 ? 'sm:h-[22rem]' : 'sm:h-[22rem]')
        ]"
      />
      <div class="absolute top-0 left-0 w-full object-cover h-[12rem] pointer-events-none" 
        :class="[
          (sidebarFixed ? 'lg:h-[39rem]' : 'lg:h-[42rem]'),
          (screenHeight > 640 ? 'sm:h-[22rem]' : 'sm:h-[22rem]')
        ]"
        style="
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(9,9,11,1) 0%, rgba(34,6,56,0) 5%, rgba(35,7,56,0) 100%);" 
      ></div>
    </div>
    <div class="w-full">
      <transition name="fade" mode="out-in">
        <div
          class="fixed top-0 w-full dark:text-white bg-gradient-to-b from-white dark:from-zinc-950 via-white dark:via-zinc-950 flex justify-start items-center font-normal uppercase fugaz pt-3 pb-10 pl-6 z-[99]"
          v-show="showNavbar"
        >
          <div class="w-14 md:w-16 h-14 md:h-16 mr-4">
            <img
              :src="mainImage"
              alt="game image"
              class="w-full h-full rounded-sm object-cover"
            />
          </div>
          <div class="flex items-end gap-1.5">
            <h1 class="font-normal text-2xl md:text-3xl -mb-1">
              {{ game.name}}
            </h1>
            <h1 v-if="game.sub_name" class="font-normal text-sm">
              {{ game.sub_name}}
            </h1>
          </div>
        </div>
      </transition>
    </div>
    <div
      class="w-full pt-5"
    > 
      <div ref="demo" v-show="openDemo" :class="sidebarFixed ? 'min-h-[655px]' : 'min-h-[715px]'">
        <iframe 
          v-if="openDemo" 
          :src="demoUrl" frameborder="0" 
          class="w-full h-auto mb-2" 
          :class="sidebarFixed ? 'min-h-[655px]' : 'min-h-[715px]'">
        </iframe>
      </div>
      <div class="w-full sm:flex justify-center pb-6 px-6 lg:px-4" style="">
        <!-- left container  -->
        <div class="h-full dark:text-white mr-6 lg:mr-8 w-full sm:w-1/2">
          <div class="pt-2 text-left fugaz">
            <h1 ref="originalHeader" class="dark:text-white font-normal uppercase text-3xl sm:text-5xl">
              {{ game.name }}
            </h1>
            <h1 class="dark:text-white font-normal uppercase text-xl sm:text-3xl">
              {{ game.sub_name }}
            </h1>
            <div class="w-44 h-1 sm:h-2 mb-2 mt-1.5 bg-orange-600"></div>
            <h1 class="font-normal uppercase text-xl sm:text-3xl text-orange-600">
              {{ $t('game.gameDescription') }}
            </h1>
          </div>
          <div class="rounded-md sm:mb-6">
            <span
              v-html="game[`description_${appLanguage.short}`]"
              class="text-zinc-600 dark:text-zinc-300"
            ></span>
          </div>
        </div>
        <!-- right container  -->
        <div class="h-max md:w-[18rem] lg:w-[22rem] fugaz" style="">
          <div
            v-if="!userRoles.isIntegrationGuest"
            class="w-full rounded-sm flex flex-wrap justify-between items-center gap-x-4 gap-y-3 mb-4 pt-2 sm:pt-0"
          >
          <!-- @click="downloadDocs" -->
          <!-- v-if="game.docs?.url" -->
            <button
              v-if="game.certificates.length > 0"
              @click="openCertificates = true"
              class="px-5 py-1.5 lg:py-3 h-max w-full shrink bg-orange-600 text-white font-normal transition-colors duration-150 xl:hover:bg-purple-600 flex items-center justify-center rounded-sm"
            >
              <span class="uppercase text-sm lg:text-[17px] whitespace-nowrap flex items-center gap-1">
                {{ $t('game.gameDocs') }}
              </span
              >
            </button>
            <button
              v-if="game.assets?.url"
              @click="downloadAssets"
              :title="game.assets.url"
              class="px-3 py-1.5 lg:py-3 h-max w-full shrink bg-orange-600 text-white font-normal transition-colors duration-150 xl:hover:bg-purple-600 flex items-center justify-center rounded-sm"
            >
              <span class="uppercase text-sm lg:text-[17px] whitespace-nowrap flex items-center gap-1">
                {{ $t('game.promoAssets') }}
              </span>
            </button>
            <!-- <button
              v-if="game.certificates.length > 0"
              @click="openCertificates = true"
              class="px-3 py-1.5 lg:py-3 h-max w-full shrink bg-orange-600 text-white font-normal transition-colors duration-150 xl:hover:bg-purple-600 flex items-center justify-center rounded-sm"
            >
              <span class="uppercase text-sm lg:text-[17px] whitespace-nowrap flex items-center gap-1">
                Certificates
              </span>
            </button> -->
          </div>
          <div
            class="bg-zinc-900 rounded-sm flex flex-col sm:gap-1 py-3 px-4"
          >
            <div
              class="mb-1 sm:mb-0"
              v-if="
                game.supportedPlatforms?.desktop ||
                game.supportedPlatforms?.mobile
              "
            >
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.supportedPlatforms') }}
              </h4>
              <div class="flex gap-x-3 text-xl sm:text-2xl text-white mt-1">
                <font-awesome-icon
                  v-if="game.supportedPlatforms.desktop"
                  :icon="['fas', 'laptop']"
                />
                <font-awesome-icon
                  v-if="game.supportedPlatforms.mobile"
                  :icon="['fas', 'mobile']"
                />
              </div>
            </div>
            <div v-if="game.format" class="">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.format') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">{{ game.format }}</h2>
            </div>
            <div v-if="game.rtp">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.rtp') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ game.rtp.toFixed(2) }} %
              </h2>
            </div>
            <div v-if="game.volatility">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.volatility') }}
              </h4>
              <div
                class="flex gap-2 text-xl md:text-2xl lg:text-3xl text-white mt-1"
                :title="game.volatility"
              >
                <div v-for="index in fullCircle" :key="`circle${index}`">
                  <font-awesome-icon :icon="['fa', 'circle']" />
                </div>
                <div v-if="hasHalfCircle">
                  <font-awesome-icon :icon="['fas', 'circle-half-stroke']" />
                </div>
                <div
                  v-for="(n, index) in emptyCircles"
                  :key="`emptyCircle${index}`"
                >
                  <font-awesome-icon :icon="['far', 'circle']" />
                </div>
              </div>
            </div>
            <div v-if="game.hit_frequency">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.hitFrequency') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ game.hit_frequency }} %
              </h2>
            </div>
            <div v-if="game.max_multiplier_win">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.maxWin') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                x{{ game.max_multiplier_win }}
              </h2>
            </div>
            <div v-if="game.max_bet">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.maxBet') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ getCurrencyValue(game.max_bet) }}
              </h2>
            </div>
            <div v-if="game.min_bet">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.minBet') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ getCurrencyValue(game.min_bet) }}
              </h2>
            </div>
            <div v-if="game.pay_method">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.payMethod') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal uppercase">
                {{ game.pay_method }}
              </h2>
            </div>
            <div class="flex items-center gap-4">
              <div v-if="game.lines && game.lines > 0">
                <h4
                  class="text text-orange-600 text-sm font-normal uppercase"
                >
                  {{ $t('game.lines') }}
                </h4>
                <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                  {{ game.lines }}
                </h2>
              </div>
              <div v-if="game.reels">
                <h4
                  class="text text-orange-600 text-sm font-normal uppercase"
                >
                  {{ $t('game.reels') }}
                </h4>
                <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                  {{ game.reels }}
                </h2>
              </div>
              <div v-if="game.gameRows">
                <h4
                  class="text text-orange-600 text-sm font-normal uppercase"
                >
                  {{ $t('game.gameRows') }}
                </h4>
                <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                  {{ game.gameRows }}
                </h2>
              </div>
            </div>
            <div v-if="game.max_exposure">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.maxExposure') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal">
                {{ getCurrencyValue(game.max_exposure) }}
              </h2>
            </div>
            <div v-if="game.isBuyBonus" class="">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.buyBonus') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white font-normal uppercase">
                {{ game.isBuyBonus ? 'Yes' : 'No' }}
              </h2>
            </div>
            <div
              v-for="(value, key, index) in game.propertiesData"
              :key="`propertiesData${index}`"
            >
              <div class="">
                <h4
                  class="text text-orange-600 text-sm font-normal uppercase"
                >
                  {{ key }}
                </h4>
                <h2 class="text-xl md:text-2xl lg:text-4xl text-white font-normal truncate">
                  {{ value }}
                </h2>
              </div>
            </div>
            <div v-if="game.release_date">
              <h4
                class="text text-orange-600 text-sm font-normal uppercase"
              >
                {{ $t('game.releaseDate') }}
              </h4>
              <h2 class="text-xl md:text-2xl lg:text-3xl text-white fugaz uppercase">
                {{ getReleaseDate(game.release_date) }}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="h-full w-full py-6 rounded-md flex flex-col items-center">
        <h2
          class="text-xl md:text-2xl lg:text-4xl w-full text-left dark:text-white font-normal uppercase fugaz"
          :style="
            sidebarFixed ? 'padding-left: 13.5%;' : 'padding-left: 11.5%;'
          "
        >
          {{ $t('game.gallery') }}
        </h2>
        <div
          class="w-full mb-8 mt-1"
          :style="
            sidebarFixed ? 'padding-left: 13.5%;' : 'padding-left: 11.5%;'
          "
        >
          <div class="w-44 h-1 md:h-2 bg-orange-600"></div>
        </div>
        <div class="flex flex-col items-center">
          <div
            class="w-full flex flex-wrap gap-3 md:gap-5 justify-center items-center px-0 md:px-16 lg:px-0"
            style="height: 14%"
          >
            <div
              v-for="(image, index) in sidebarFixed
                ? mediaGallery.slice(0, 5)
                : mediaGallery.slice(0, 6)"
              :key="`image${index}`"
            >
              <div v-if="!image.isBanner && !image.isMainImage">
                <img
                  v-if="image.url.includes('jpg') || image.url.includes('png') || image.url.includes('webp')"
                  @click="openGallery(image, index)"
                  class="w-36 md:w-44 lg:w-[215px] h-24 md:h-32 lg:h-36 object-cover cursor-pointer rounded-sm xl:hover:scale-[1.02]"
                  :src="image.url"
                  alt="mini img"
                />
                <!-- <div
                  v-else
                  class="w-36 md:w-44 lg:w-[215px] h-24 md:h-32 lg:h-36 bg-zinc-200 dark:bg-zinc-700 cursor-pointer rounded-sm flex justify-center items-center xl:hover:scale-[1.02]"
                  @click="openGallery(image, index)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'circle-play']"
                    class="text-zinc-400 h-14"
                  />
                </div> -->
                <div v-else 
                  @click="openGallery(image, index)"
                  class="relative w-36 md:w-44 lg:w-[215px] cursor-pointer xl:hover:scale-[1.02]"
                >
                  <video :src="`${image.url}#t=1`" class="w-full h-auto"></video>
                  <font-awesome-icon
                    :icon="['fas', 'play']"
                    class="text-orange-600 h-10 md:h-14 absolute top-0 bottom-0 left-0 right-0 m-auto"
                  />
                </div>
              </div>
            </div>
          </div>
          <button v-if="userRoles.isLudusAdmin && mediaGallery.length" @click="openGalleryOder" 
            class="px-3 py-1 w-max rounded-sm text-zinc-200 xl:hover:text-white fugaz uppercase mt-4 hidden sm:block">
            Update gallery order
          </button>
        </div>
        <div
          v-if="mediaGallery.length > 5"
          class="w-full flex justify-end"
          :style="
            sidebarFixed ? 'padding-right: 15.5%;' : 'padding-right: 13.5%;'
          "
        >
          <div
            @click="openGallery(mediaGallery[0], index)"
            class="text-white text-base md:text-lg fugaz uppercase mt-4 cursor-pointer xl:hover:text-orange-600 transition-all duration-150"
          >
            {{ $t('game.viewMore') }}
          </div>
        </div>
      </div>
      <button
        v-if="userRoles.isLudusAdmin"
        @click="goToEditGame"
        class="fixed top-0 z-[999] rounded-sm px-4 py-3 right-0 text-white xl:hover:text-orange-600 text-lg transition-all duration-150"
        :class="screenWidth > 768 && screenHeight > 450 ? 'block' : 'hidden'"
      >
        <span class="fugaz uppercase">Edit game</span>
      </button>
    </div>
    <Transition>
      <gallery v-if="showGallery" :media="mediaGallery" />
    </Transition>
    <Transition>
      <div @click="openCertificates = false; selectedIntegration = null" v-if="openCertificates"
        class="fixed top-0 left-0 flex justify-center items-center bg-black bg-opacity-75 z-[100] w-screen h-screen text-white">
        <div @click.stop class="w-[50%] max-h-[60%] bg-zinc-850 rounded-lg overflow-y-auto relative p-4" :class="{'h-[60%]': userRoles.isLudusAdmin || userRoles.isLudusManager}">
          <button @click.stop="openCertificates = false; selectedIntegration = null" class="absolute top-2 right-2.5 text-white xl:hover:text-orange-600">
            <font-awesome-icon :icon="['fas', 'xmark']" class="h-[18px]" />
          </button>
          <div class="bg-zinc-850 flex items-center justify-between fugaz uppercase border-b border-zinc-700 pl-4 pr-8 pb-3 pt-4 mb-2">
            <h4 class="font-light">{{ $t('game.documentation') }}</h4>
            <h5 class="font-light">{{ game.name }}</h5> 
            <!-- <router-link :to="{name: 'Games'}" class="font-light xl:hover:text-orange-600">Manage</router-link>  -->
          </div>
          <p class="roboto text-zinc-400 mt-4 mb-1 px-1.5">Game Files:</p>
            <div v-if="certificates.gameFiles?.length > 0" class="flex flex-wrap gap-2 pb-3 items-end">
              <div v-for="(cert, index) in certificates.gameFiles" class="space-y-1.5 h-full px-2 py-1 rounded-md bg-zinc-900 w-[18.2rem]">
              <!-- <div v-if="game.certificates?.length > 0" v-for="(cert, index) in game.certificates" class="space-y-1.5 h-full px-2 py-1 rounded-md bg-zinc-900" :class="userRoles.isLudusAdmin && index > 0 && cert.integration !== game.certificates[index + 1]?.integration ? 'w-full' : 'w-max'"> -->
                <div class="mt-auto flex items-center gap-4 justify-between group">
                  <div @click="downloadFile(cert.url)" :title="cert.name" class="flex tems-center gap-1.5 cursor-pointer w-[87%]">
                    <img :src="require(`@/assets/${showFileIcon(cert.url)}.png`)" width="24" height="24" class="pl-0.5"/>
                    <!-- <font-awesome-icon :icon="showFileIcon(cert.name)" class="pl-0.5 pt-0.5" /> -->
                    <span class="truncate">{{ cert.name ? cert.name : '-' }}</span>
                  </div>
                  <button @click="downloadFile(cert.url)" class="xl:group-hover:text-orange-600 pb-px">
                    <font-awesome-icon :icon="['fas', 'download']" class="cursor-pointer h-3.5 pb-0.5" />
                  </button>
                </div>
                <p class="text-sm font-light">
                    {{ $t('manageGames.uploadedOn') }} {{ clearDate(cert.updatedAt) }}
                </p>
              </div>
            </div>
            <p v-else-if="certificates.gameFiles?.length == 0 && Object.keys(certificates.integrations).length == 0" class="roboto text-sm text-zinc-400 px-1.5">No files found</p>
            <div v-if="(userRoles.isLudusAdmin || userRoles.isLudusManager) && !!Object.keys(certificates.integrations).length" class="flex gap-x-4 items-center mt-4 px-1.5">
              <p class="roboto text-zinc-400"> Integrations </p>
              <div 
                class="w-full md:w-52" 
                :class="{'min-w-[430px]': appLanguage.long == 'en-GB', 'min-w-[540px]': appLanguage.long != 'en-GB'} "
              >        
                <Multiselect
                  mode="single"
                  v-model="selectedIntegration"
                  :options="Object.keys(certificates.integrations)"
                  label="name"
                  value-prop="name"
                  :placeholder="$t('mailing.selectIntegration')"
                  :hide-selected="false"
                  :caret="true"
                  :close-on-select="true"
                  :searchable="true"
                />
              </div>
            </div>
            <div  v-if="selectedIntegration || (!userRoles.isLudusAdmin && !userRoles.isLudusManager && certificates.integrations[Object.keys(certificates.integrations)[0]])" class="flex flex-wrap gap-2 pb-3 items-end">
              <div v-for="(cert, index) in selectedIntegration ? certificates.integrations[selectedIntegration] : certificates.integrations[Object.keys(certificates.integrations)[0]]" class="space-y-1.5 h-full px-2 py-1 rounded-md bg-zinc-900 w-[18.2rem]">
              <!-- <div v-if="game.certificates?.length > 0" v-for="(cert, index) in game.certificates" class="space-y-1.5 h-full px-2 py-1 rounded-md bg-zinc-900" :class="userRoles.isLudusAdmin && index > 0 && cert.integration !== game.certificates[index + 1]?.integration ? 'w-full' : 'w-max'"> -->   
                <div class="mt-auto flex items-center gap-4 justify-between group">
                  <div @click="downloadFile(cert.url)" :title="cert.name" class="flex tems-center gap-1.5 cursor-pointer w-[87%]">
                    <!-- <font-awesome-icon :icon="['fas', 'file']" class="pl-0.5 pt-0.5" /> -->
                    <img :src="require(`@/assets/${showFileIcon(cert.url)}.png`)" width="24" height="24" class="pl-0.5"/>
                    <span class="truncate">{{ cert.name ? cert.name : '-' }}</span>
                  </div>
                  <button @click="downloadFile(cert.url)" class="xl:group-hover:text-orange-600 pb-px">
                    <font-awesome-icon :icon="['fas', 'download']" class="cursor-pointer h-3.5 pb-0.5" />
                  </button>
                </div>
                <p class="text-sm font-light">
                    {{ $t('manageGames.uploadedOn') }} {{ clearDate(cert.updatedAt) }}
                </p>
              </div>
            </div>
            <!-- <p class="roboto text-zinc-400 mt-2 mb-1 px-1.5">General:</p>
            <div class="flex flex-wrap  gap-1.5 pb-3">
              <div v-for="(cert, index) in game.certificates" class="flex flex-col items-center gap-1.5">
                <div class="px-2 py-1 rounded-md bg-zinc-900 flex items-end justify-between gap-3 relative h-full w-max">
                  <div class="flex flex-col justify-between gap-1.5">
                    <div v-if="userRoles.isLudusAdmin && cert.integration">
                      Integration: <span class="font-semibold">{{ cert.integration.name }}</span>
                    </div>
                    <div class="flex items-center gap-4 justify-between group">
                      <div @click="downloadFile(cert.url)" :title="cert.name" class="flex tems-center gap-1.5 cursor-pointer">
                        <font-awesome-icon :icon="['fas', 'file']" class="pl-0.5 pt-0.5" />
                        <span>{{ cert.name ? cert.name : '-' }}</span>
                      </div>
                      <button @click="downloadFile(cert.url)" class="xl:group-hover:text-orange-600 pb-px">
                        <font-awesome-icon :icon="['fas', 'download']" class="cursor-pointer h-3.5 pb-0.5" />
                      </button>
                    </div>
                    <div class="flex items-center gap-4 justify-between">
                      <span class="font-light">
                        {{ $t('manageGames.uploadedOn') }} {{ clearDate(cert.updatedAt) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
        </div>
      </div>
    </Transition>
    <div 
      v-if="changeGalleryOrder" @click="changeGalleryOrder = false" 
      class="fixed top-0 flex items-center justify-center w-full h-full bg-black bg-opacity-50 z-[50]"
      :class="sidebarFixed ? 'left-36' : 'left-10'"
    >
      <div v-if="!savingOrder" @click.stop class="py-3 px-6 rounded-sm bg-zinc-700 flex flex-col gap-1.5 items-center justify-center max-w-[865px]">
        <h3 class="text-white fugaz uppercase">Drag and drop to set order</h3>
        <div class="flex flex-col gap-1.5 w-full px-4 py-2 rounded-md bg-zinc-800">
          <!-- <h5 class="text-white fugaz uppercase">Start</h5> -->
          <draggable v-model="finalGallery" group="people" item-key="id" class="w-max max-w-full flex flex-wrap gap-x-4 gap-y-2">
            <template #item="{element, index}">
              <div class="flex flex-col items-center justify-center gap-0.5">
                <span class="fugaz uppercase text-white">{{index + 1}}</span>
                <img v-if="element.mediaFormat == 'image'" class="h-28 w-36 object-cover cursor-grab" :src="element.url" alt="game image" />
                <video v-else class="h-auto w-36 z-10 cursor-grab" :src="element.url"></video>
              </div>
            </template>
          </draggable>
          <!-- <h5 class="text-white fugaz uppercase text-right">end</h5> -->
        </div>
        <button @click="saveOrder" class="px-3 py-0.5 rounded-sm xl:hover:text-white text-zinc-200 text-lg fugaz uppercase mt-1">Save order</button>
      </div>
      <div v-else>
        <img src="../../assets/loading2.png" alt="loading icon"
          class="h-10 animate-spin"
        />
      </div>
    </div>
    <router-link @click="showNavbar = false" v-if="gamesOrder.length > 0" :to="{ name: 'Game', params: { id: nextGameId } }" class="hidden md:block fixed top-1/2 right-0 px-3 lg:px-4 pt-3 pb-2 text-opacity-25 text-white xl:hover:text-orange-600 transition-all duration-150">
      <font-awesome-icon :icon="['fas', 'angle-right']" class="h-10" />
    </router-link>
    <router-link @click="showNavbar = false" v-if="gamesOrder.length > 0" :to="{ name: 'Game', params: { id: previousGameId } }" class="hidden md:block fixed top-1/2 px-3 lg:px-4 pt-3 pb-2 text-opacity-25 text-white xl:hover:text-orange-600 transition-all duration-150" :class="sidebarFixed ? 'left-0 xl:left-72' : 'left-0 xl:left-20'">
      <font-awesome-icon :icon="['fas', 'angle-left']" class="h-10" />
    </router-link>
  </div>
  <div
    v-else
    class="w-full h-full flex justify-center items-center bg-zinc-200 dark:bg-zinc-900 text-zinc-600 dark:text-zinc-400"
  >
    <img src="../../assets/loading2.png" alt="loading icon"
      v-if="!serverError"
      class="h-10 animate-spin"
    />
    <div v-else class="fugaz uppercase">
      Error trying to connect to the server.
      <a
        @click="$router.go()"
        class="text-blue-600 fugaz uppercase cursor-pointer"
        >Reload</a
      >
      page to try again.
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { getFileExtension } from "@/helpers/getFileExtension.js";
import draggable from 'vuedraggable'
import Gallery from "../../components/Gallery.vue";
import request from "../../services/axios";
import Multiselect from '@vueform/multiselect';

export default {
  name: "Game",
  props: {
    id: String,
  },
  components: {
    draggable,
    Gallery,
    Multiselect
  },
  data() {
    return {
      game: {},
      mediaGallery: [],
      actualImage: {
        index: 0,
        url: "",
      },
      isFixed: false,
      headerHeight: 0,
      ready: false,
      showNavbar: false,
      serverError: false,
      openDemo: false,
      demoUrl: '',
      bannerPrimaryColor: '',
      finalGallery: [],
      changeGalleryOrder: false,
      savingOrder: false,
      openCertificates: false,
      certificates: {
        gameFiles: [],
        integrations: {}
      },
      selectedIntegration: null,
      showDropdown: false,
    };
  },
  beforeUnmount() {
    if (this.showGallery || this.changeGalleryOrder) {
      this.$store.dispatch('game/setShowGalleryAction', false)
      this.changeGalleryOrder = false
      document.body.style.overflowY = "auto";
    }
  },
  watch: {
    async id() {
      this.ready = false
      this.openDemo = false
      await this.initialize(this.id)
    },
    showGallery(newValue) {
      if (newValue) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    },
    changeGalleryOrder(newValue) {
      if (newValue) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    },
  },
  async mounted() {
    // const items = JSON.parse(atob(this.$route.query.items))
    // console.log(items)
    // if (!this.ready) this.initialize(this.id);
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  async created() {
    await this.initialize(this.id);
  },
  computed: {
    appLanguage() {
      return this.$store.getters['getLanguage']
    },
    screenHeight() {
      return this.$store.getters['getScreenHeight']
    },
    screenWidth() {
      return this.$store.getters['getScreenWidth']
    },
    isLandscape() {
      return this.screenWidth > this.screenHeight;
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRoles() {
      return this.user.localRoles;
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
    showGallery() {
      return this.$store.getters["game/showGallery"];
    },
    integrationOnGame() {
      if (this.user.attributes?.integration?.length) {
        return this.game.integrations.find(
          (integration) => integration.name === this.user.attributes?.integration[0]
        );
      } else return {};
    },
    showOnWishlist() {
      if (this.integrationOnGame?.integration_games) {
        if (
          this.integrationOnGame.integration_games.is_active === null &&
          this.integrationOnGame.integration_games.is_requested !== null
        )
          return true;
        else return false;
      } else return false;
    },
    mainImage() {
      const mainImage = this.game.game_media?.find(
        (image) => image.isMainImage === true
      );
      return mainImage
        ? mainImage.url
        : require(`@/assets/no-image-available.jpg`);
    },
    bannerImage() {
      const bannerImage = this.game.game_media?.find(
        (image) => image.isBanner === true
      );
      return bannerImage
        ? bannerImage.url
        : require(`@/assets/no-image-available.jpg`);
    },
    fullCircle() {
      return Math.floor(this.game.volatility / 2);
    },
    hasHalfCircle() {
      return this.game.volatility % 2 !== 0;
    },
    emptyCircles() {
      const maxCircles = 5;
      return Math.floor(maxCircles - this.game.volatility / 2);
    },
    gamesOrder() {
      return this.$store.getters['catalog/gamesOrder']
    },
    nextGameId() {
      const index = this.gamesOrder.indexOf(parseInt(this.id))
      return index !== this.gamesOrder.length - 1 ? this.gamesOrder[index + 1] : this.gamesOrder[0];
    },
    previousGameId() {
      const index = this.gamesOrder.indexOf(parseInt(this.id))
      return index === 0 ? this.gamesOrder[this.gamesOrder.length - 1] : this.gamesOrder[index - 1];
    }
  },
  methods: {
    showFileIcon(fileUrl = null) {
      const extension = getFileExtension(fileUrl)

      switch (extension) {
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
          return 'image'
        case 'zip':
          return 'zip';
        case 'pdf':
          return 'pdf'
        case 'xlsx':
        case 'xls':
          return 'excel'
        case 'txt':
        case 'docx':
        case 'doc':
          return 'word'
        case 'mp4':
        case 'mov':
        case 'avi':
        case 'webm':
          return 'video'
        default:
          return 'file'
      }
    },
    downloadFile(s3Url) {
      const link = document.createElement('a');
      link.href = s3Url;
      link.setAttribute('download', '');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async saveOrder(){
      this.savingOrder = true
      const galleryWithNoUrl = this.finalGallery.map((media, index) => {
        const { url, ...mediaWithoutUrl } = media;
        mediaWithoutUrl.order = index
        return mediaWithoutUrl;
      });
      // console.log(galleryWithNoUrl);
      await request.put('/games/media/order', galleryWithNoUrl)

      // console.log(this.finalGallery);
      this.mediaGallery = JSON.parse(JSON.stringify(this.finalGallery))
      this.$toast.success('Gallery order updated', {
        position: "top-right",
        autoClose: 3000,
      });
      this.changeGalleryOrder = false
      this.savingOrder = false
    },
    openGalleryOder(){
      let gallery = []
      gallery = this.mediaGallery.map(media => ({
        ...media,
        mediaFormat: (media.url.includes('jpg') || media.url.includes('png') || media.url.includes('webp')) ? 'image' : 'video'
      }))
      this.finalGallery = JSON.parse(JSON.stringify(gallery))
      this.changeGalleryOrder = true
    },
    playDemo(){
      this.openDemo = true
      const iframe = this.$refs.demo
      // console.log(iframe);
      nextTick(() => {
        iframe.scrollIntoView({ behavior: 'smooth', block: "center" })
      })
    },
    mobilePlayDemo() {
      window.open(this.demoUrl, '_blank').focus();
    },
    async initialize(gameId) {
      try {
        const response = await request.get(`/games/${gameId}?with=game_media&with=integrations&with=certificates`);
        this.game = response.data;
        this.certificates = {
          gameFiles: [],
          integrations: {}
        }
        
        if (this.game.certificates && !!this.game.certificates.length) {
          this.certificates.gameFiles = this.game.certificates.filter(cert => {
            if (cert.integration !== null && cert.integration.name) {
              if (!this.certificates.integrations[cert.integration.name]) {
                this.certificates.integrations[cert.integration.name] = [];
              } 
              this.certificates.integrations[cert.integration.name].push(cert);
            } else {
              return cert
            }
          })
        }

        if(!this.game.isCatalog) { // block not catalog games 
          this.$router.push({name: 'Catalog'})
        }
        // separate gallery images anf files
        if (this.game.game_media) {
          let gameMedia = this.game.game_media.filter(
            (image) => image.mediaType === "gallery"
          );
          this.game.docs = this.game.game_media.find(
            (image) => image.mediaType === "docs"
          );
          this.game.assets = this.game.game_media.find(
            (image) => image.mediaType === "assets"
          );
          this.game.game_media = gameMedia;
          this.mediaGallery = gameMedia.filter(
            (img) =>
              img.isBanner === false &&
              img.isMainImage === false &&
              img.isMiniBanner === false
          );
          this.mediaGallery.sort(function(a, b) {
            return a.order - b.order;
          });

          this.actualImage.url = this.mediaGallery[0]
            ? this.mediaGallery[0].url
            : require(`@/assets/no-image-available.jpg`);
          this.actualImage.index = 0;
        }
        this.demoUrl = `${process.env.VUE_APP_DEMO_URL}/${this.game.slug}?fullBtn=false`;
        this.ready = true;
      } catch (error) {
        console.log(error);
        this.serverError = true;
      }
    },
    goToEditGame() {
      this.ready = false;
      this.$router.push({ name: "EditGame", params: { id: this.id } });
    },
    openGallery(image, index) {
      this.$store.dispatch("game/setShowGalleryAction", true);
      this.$store.dispatch("game/setActualImageAction", {
        url: image.url,
        index,
      });
    },
    // downloadDocs() {
    //   window.open(this.game.docs.url);
    // },
    downloadAssets() {
      window.open(this.game.assets.url);
    },
    handleScroll() {
      if (this.ready) {
        const originalHeader = this.$refs.originalHeader;
        const rect = originalHeader.getBoundingClientRect();
        if (rect.bottom <= 0) {
          this.showNavbar = true;
        } else {
          this.showNavbar = false;
        }
      }
    },
    getReleaseDate(releaseDate) {
      if (releaseDate) {
        const date = new Date(releaseDate);
        const options = { year: "numeric", month: "long", day: "numeric" };
        return date.toLocaleDateString(this.appLanguage.long, options);
      } else return "N/A";
    },
    clearDate(date) {
        const options = {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
        return new Date(date).toLocaleDateString(this.appLanguage.long, options)
      },
    async addToInterested() {
      try {
        await request.post(
          `/games/interested?integrationName=${this.user.attributes.integration[0]}&gameId=${this.game.id}&isInterested=true`
        );
        this.$toast.success("Game added to wishlist", {
          position: "bottom-right",
          autoClose: 3000,
        });
        this.initialize();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
 /* , .multiselect-placeholder */
.multiselect, .multiselect-dropdown, .multiselect-placeholder {
  z-index: 101 !important;
}
</style>
