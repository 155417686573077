<template>
  <div v-if="ready" class="w-full bg-zinc-950 relative min-h-full flex flex-col">
    <button
      class="hidden xl:block fixed bottom-[50px] xl:bottom-0 bg-zinc-950 text-white xl:hover:text-orange-600 transition-all duration-100 fugaz uppercase px-2.5 pt-1 rounded-tr-lg z-[99] border-t border-r border-zinc-700"
      :class="sidebarFixed ? 'left-0 xl:left-72' : 'left-0 xl:left-20'"
      @click="$router.push({ name: 'Integrations' })"
    >
      <font-awesome-icon
        :icon="['fas', 'backward']"
        class="h-4 pr-1"
      />
      {{ $t('integrations.title') }}
    </button>
    <div class="h-max w-full rounded-md flex flex-col gap-y-3 text-white">
      <!-- <h1 class="text-4xl fugaz uppercase">New integration</h1> -->
      <div class="h-max flex justify-between items-center rounded-t-md bg-zinc-950 w-full pt-4 pb-4">
        <h2 class="fugaz uppercase text-3xl xl:text-4xl dark:text-white pt-1">
          {{ $t('newIntegration.title') }}
        </h2>
      </div>
      <div class="flex flex-col gap-y-3 pb-6 md:pb-0">
        <div class="w-full rounded-sm p-3 flex flex-c ol gap-y-2 bg-zinc-900">
          <!-- <h2 class="text-xl">Basic info:</h2> -->
          <label for="name">{{ $t('newIntegration.name') }}
            <input type="text" v-model="name" class="w-64 bg-zinc-900 border-b border-zinc-700 outline-none pl-1 ml-1">
          </label>
        </div>
        <div class="w-64">
          <Multiselect
              mode="single"
              v-model="newVersion"
              :options="versions"
              label="version"
              :hide-selected="false"
          />
        </div>
        <div class="w-full rounded-sm p-3 flex flex-col gap-y-2 bg-zinc-900">
          <h2 class="text-xl">{{ $t('newIntegration.services') }}</h2>
          <div class="flex flex-wrap justify-center gap-3">
            <div class="relative px-3 py-2 rounded-sm flex flex-col gap-1 bg-zinc-800 w-full md:w-[49%]">
              <h3 class="pr-2 text-center text-lg fugaz uppercase">{{ $t('newIntegration.gamingService') }}</h3>
              <div class="flex items-center">
                <h6>URL:</h6>
                <input @blur="blurInput('gaming')" type="text" v-model="gamingServiceFields.url" class="bg-zinc-900 outline-none pl-1 ml-1.5 rounded-sm w-full">
              </div>
              <div>
                <h6>Token:</h6>
                <textarea @blur="blurInput('gaming')" name="" v-model="gamingServiceFields.token" 
                  class="bg-zinc-900 outline-none px-1 resize-none w-full"
                  style="min-height: 100px;"
                />
              </div>
              <div v-if="loadingGamingService !== null" class="flex items-center justify-center gap-2">
                {{ $t('newIntegration.status') }}
                <font-awesome-icon  :icon="['fas', 'circle-check']" v-if="!loadingGamingService && gamingServiceStatus === true" class="text-green-500 h-5" />
                <font-awesome-icon :icon="['fas', 'circle-xmark']" v-if="!loadingGamingService && gamingServiceStatus === false" class="text-red-500 h-5" />
                <img src="../../assets/loading2.png" alt="loading icon" v-if="loadingGamingService === true" class="h-6 animate-spin" />
              </div>
            </div>
            <div class="relative px-3 py-2 rounded-sm flex flex-col gap-1 bg-zinc-800 w-full md:w-[49%]">
              <h3 class="pr-2 text-center text-lg fugaz uppercase">{{ $t('newIntegration.dataService') }}</h3>
              <div class="flex items-center">
                <h6>URL:</h6>
                <input @blur="blurInput('data')" type="text" v-model="dataServiceFields.url" class="bg-zinc-900 outline-none pl-1 ml-1.5 rounded-sm w-full">
              </div>
              <div>
                <h6>Token:</h6>
                <textarea name="" v-model="dataServiceFields.token"
                  @blur="blurInput('data')"
                  class="bg-zinc-900 outline-none px-1 resize-none w-full"
                  style="min-height: 100px;"
                />
              </div>
              <div v-if="loadingDataService !== null" class="flex items-center justify-center gap-2">
                {{ $t('newIntegration.status') }}
                <font-awesome-icon v-if="!loadingDataService && dataServiceStatus === true" :icon="['fas', 'circle-check']" class="text-green-500 h-5" />
                <font-awesome-icon v-if="!loadingDataService && dataServiceStatus === false" :icon="['fas', 'circle-xmark']" class="text-red-500 h-5" />
                <img src="../../assets/loading2.png" alt="loading icon" v-if="loadingDataService === true" class="h-6 animate-spin" />
              </div>
            </div>
            <div class="relative px-3 py-2 rounded-sm flex flex-col gap-1 bg-zinc-800 w-full md:w-[49%]">
              <h3 class="pr-2 text-center text-lg fugaz uppercase">{{ $t('newIntegration.playService') }}</h3>
              <div class="flex items-center">
                <h6>URL:</h6>
                <input @blur="blurInput('play')" type="text" v-model="playServiceFields.url" class="bg-zinc-900 outline-none pl-1 ml-1.5 rounded-sm w-full">
              </div>
              <div>
                <h6>Token:</h6>
                <textarea @blur="blurInput('play')" name="" v-model="playServiceFields.token" 
                  class="bg-zinc-900 outline-none px-1 resize-none w-full"
                  style="min-height: 100px;"
                />
              </div>
              <div v-if="loadingPlayService !== null" class="flex items-center justify-center gap-2">
                {{ $t('newIntegration.status') }}
                <font-awesome-icon v-if="!loadingPlayService && playServiceStatus === true" :icon="['fas', 'circle-check']" class="text-green-500 h-5" />
                <font-awesome-icon v-if="!loadingPlayService && playServiceStatus === false" :icon="['fas', 'circle-xmark']" class="text-red-500 h-5" />
                <img src="../../assets/loading2.png" alt="loading icon" v-if="loadingPlayService === true" class="h-6 animate-spin" />
              </div>
            </div>
            <div class="relative px-3 py-2 rounded-sm flex flex-col gap-1 bg-zinc-800 w-full md:w-[49%]">
              <h3 class="pr-2 text-center text-lg fugaz uppercase">{{ $t('newIntegration.launchService') }}</h3>
              <div class="flex items-center">
                <h6>URL:</h6>
                <input @blur="blurInput('launch')" type="text" v-model="launchServiceFields.url" class="bg-zinc-900 outline-none pl-1 ml-1.5 rounded-sm w-full">
              </div>
              <div>
                <h6>Token:</h6>
                <textarea @blur="blurInput('launch')" name="" v-model="launchServiceFields.token" 
                  class="bg-zinc-900 outline-none px-1 resize-none w-full"
                  style="min-height: 100px;"
                />
              </div>
              <div v-if="loadingLaunchService !== null" class="flex items-center justify-center gap-2">
                {{ $t('newIntegration.status') }}
                <font-awesome-icon v-if="!loadingLaunchService && launchServiceStatus === true" :icon="['fas', 'circle-check']" class="text-green-500 h-5" />
                <font-awesome-icon v-if="!loadingLaunchService && launchServiceStatus === false" :icon="['fas', 'circle-xmark']" class="text-red-500 h-5" />
                <img src="../../assets/loading2.png" alt="loading icon" v-if="loadingLaunchService === true" class="h-6 animate-spin" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center mt-2">
          <button @click="submit" class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50">
            {{ $t('newIntegration.submit') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import axios from 'axios';
import request from '../../services/axios';
export default {
  name: 'NewIntegration',
  components: {
    Multiselect
  },
  data() {
    return {
      ready: true,
      name: '',
      gamingServiceFields: {
        url: '',
        token: '',
      },
      gamingServiceStatus: null,
      loadingGamingService: null,
      dataServiceFields: {
        url: '',
        token: '',
      },
      dataServiceStatus: null,
      loadingDataService: null,
      playServiceFields: {
        url: '',
        token: '',
      },
      playServiceStatus: null,
      loadingPlayService: null,
      launchServiceFields: {
        url: '',
        token: '',
      },
      launchServiceStatus: null,
      loadingLaunchService: null,
      newVersion: "",
      versions: [
        'GAI2',
        'GAI-INTERNATIONAL',
      ],
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/user']
    },
    sidebarFixed() {
      return this.$store.getters["sidebar/getSidebarFixed"];
    },
  },
  created(){
    
  },
  methods: {
    async blurInput(type) {
      if(type == 'gaming') {
        if(this.gamingServiceFields.url && this.gamingServiceFields.token) {
          this.loadingGamingService = true
          this.gamingServiceStatus = await this.checkService(this.gamingServiceFields.url, this.gamingServiceFields.token) == 200 ? true : false
          this.loadingGamingService = false
        }
      }
      if(type == 'data') {
        if(this.dataServiceFields.url && this.dataServiceFields.token) {
          this.loadingDataService = true
          this.dataServiceStatus = await this.checkService(this.dataServiceFields.url, this.dataServiceFields.token) == 200 ? true : false
          this.loadingDataService = false
        }
      }
      if(type == 'play') {
        if(this.playServiceFields.url && this.playServiceFields.token) {
          this.loadingPlayService = true
          this.playServiceStatus = await this.checkService(this.playServiceFields.url, this.playServiceFields.token) == 200 ? true : false
          this.loadingPlayService = false
        }
      }
      if(type == 'launch') {
        if(this.launchServiceFields.url && this.launchServiceFields.token) {
          this.loadingLaunchService = true
          this.launchServiceStatus = await this.checkService(this.launchServiceFields.url, this.launchServiceFields.token) == 200 ? true : false
          this.loadingLaunchService = false
        }
      }
    },
    async checkService(url, token) {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `${url}`,
            'x-access-token': `${token}`,
          },
        })
        return response.status
      } catch (e) {
        console.log(e);
      }
    },
    async submit() {
      try {
        const response = await request.post('/integrations', {
          name: this.name,
          version: this.newVersion,
          gamingServiceURL: this.gamingServiceFields.url,
          gamingServiceToken: this.gamingServiceFields.token,
          dataServiceURL: this.dataServiceFields.url,
          dataServiceToken: this.dataServiceFields.token,
          playServiceURL: this.playServiceFields.url,
          playServiceToken: this.playServiceFields.token,
          launchServiceURL: this.launchServiceFields.url,
          launchServiceToken: this.launchServiceFields.token,
        }, 
        { 
          headers: { 'Content-Type': 'application/json' } 
        })
        // request.post('/logs/create', {
        //   type: 2,
        //   description: `${this.user.username} CREATED the integration "${response.data[0].name}" (integrationId: ${response.data[0].id})`
        // })
        this.$router.push({ name: 'Integration', params: { id: response.data[0].id }})
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style>

</style>