<template>
  <div
    class="flex flex-col items-center rounded-md pt-6 pb-4 xl:pb-10 px-3 xl:px-7 shadow-md gap-y-1 create w-full"
  >
    <div class="flex justify-center w-full">
      <h2
        class="text-3xl text-black dark:text-white pl-4 pb-8 fugaz uppercase"
      >
        {{ name ? name : "New game" }} <span class="text-xl">info</span>
      </h2>
    </div>
    <!-- <div class="flex flex-col items-center">
      <h2 class="font-semibold text-lg text-black dark:text-zinc-300 mb-3">
        Game info:
      </h2>
    </div> -->
    <div class="flex flex-wrap justify-center gap-3 px-4">
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <label class="capitalize font-semibold text-white" for="rtp"
          >RTP</label
        >
        <input
          v-model="rtp"
          name="rtp"
          type="number"
          max="100"
          min="0"
          class="w-16 py-1 rounded-md pl-2 outline-none text-white bg-zinc-950"
        />
      </div>
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 px-2 py-2 rounded-md shadow-md h-max" style="min-height: 80px;"
      >
        <label
          class="capitalize font-semibold text-white"
          for="volatility"
          >Volatility</label
        >
        <select v-model="volatility" class="py-1.5 rounded-md text-white bg-zinc-950 pl-1">
          <option disabled value="0">0</option>
          <option
            v-for="i in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
            :key="i"
            :value="i"
          >
            {{ i }}
          </option>
        </select>
      </div>
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <label
          class="capitalize font-semibold text-white"
          for="hit_frequency"
          >Hit frequency</label
        >
        <input
          v-model="hit_frequency"
          name="hit_frequency"
          type="number"
          min="0"
          class="w-20 py-1 rounded-md pl-2 outline-none text-white bg-zinc-950"
        />
      </div>
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <label class="capitalize font-semibold text-white" for="max_win"
          >Max win</label
        >
        <input
          v-model="max_multiplier_win"
          name="max_multiplier_win"
          type="number"
          min="0"
          step="0.1"
          class="w-16 py-1 rounded-md pl-2 outline-none text-white bg-zinc-950"
        />
      </div>
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <label class="capitalize font-semibold text-white" for="type"
          >Type</label
        >
        <select v-model="type" class="py-1.5 rounded-md text-white bg-zinc-950 pl-1">
          <option value="SLOT MACHINE">SLOT MACHINE</option>
          <option v-for="i in ['ROULETTE', 'UNKNOW']" :key="i" :value="i">
            {{ i }}
          </option>
        </select>
      </div>
      <div
      class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <label class="capitalize font-semibold text-white" for="format"
          >Format</label
        >
        <input
          v-model="format"
          name="format"
          class="w-20 py-1 rounded-md pl-2 outline-none text-white bg-zinc-950"
        />
      </div>
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <label class="capitalize font-semibold text-white" for="max_bet"
          >Max bet</label
        >
        <input
          v-model="max_bet"
          name="max_bet"
          type="number"
          min="0"
          class="w-16 py-1 rounded-md pl-2 outline-none text-white bg-zinc-950"
        />
      </div>
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <label class="capitalize font-semibold text-white" for="min_bet"
          >Min bet</label
        >
        <input
        v-model="min_bet"
          name="min_bet"
          type="number"
          min="0"
          step="0.1"
          class="w-16 py-1 rounded-md pl-2 outline-none text-white bg-zinc-950"
          />
      </div>
      <div class="flex flex-col items-center bg-zinc-800 px-2 rounded-md shadow-md">
        <label
          class="capitalize font-semibold text-white my-1.5"
          for="payMethod"
          >Pay method</label
        >
        <select v-model="payMethod" class="py-2 rounded-md text-white bg-zinc-950 pl-1 outline-none">
          <option value="Paylines">Paylines</option>
          <option v-for="i in [ 'Scatter', 'Ways', 'Tumble']" :key="i" :value="i">
            {{ i }}
          </option>
        </select>
      </div>
      <div
        v-if="payMethod !== 'Scatter' && payMethod !== 'Tumble'"
        class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
      <label class="capitalize font-semibold text-white" for="lines">
        Lines
      </label>
      <input
        v-model="lines"
        name="lines"
        type="number"
        min="0"
        class="w-20 py-1 rounded-md pl-2 outline-none text-white bg-zinc-950"
      />
      </div>
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <label class="capitalize font-semibold text-white" for="reels"
          >Reels</label
        >
        <input
          v-model="reels"
          name="reels"
          type="number"
          min="0"
          class="w-14 py-1 rounded-md pl-2 outline-none text-white bg-zinc-950"
        />
      </div>
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <label class="capitalize font-semibold text-white" for="gameRows">
          Rows
        </label>
        <input
          v-model="gameRows"
          name="gameRows"
          type="number"
          min="0"
          class="w-14 py-1 rounded-md pl-2 outline-none text-white bg-zinc-950"
        />
      </div>
      <div
        class="flex flex-col items-center gap-1.5 justify-between bg-zinc-800 p-3 rounded-md shadow-md h-max"
      >
        <label
          class="capitalize font-semibold text-white"
          for="volatility"
          >Supported integrations:
        </label>
        <div class="flex justify-between gap-x-2">
          <label class="flex items-center checkbox">
            <div
              class="flex items-center truncate text-black dark:text-white"
              :class="
                supportedPlatforms.mobile ? 'font-semibold' : 'font-normal'
              "
            >
              Mobile
            </div>
            <input
              type="checkbox"
              @click="supportedPlatforms.mobile = !supportedPlatforms.mobile"
              :checked="supportedPlatforms.mobile"
            />
            <span class="checkmark"></span>
          </label>

          <label class="flex items-center checkbox">
            <div
              class="flex items-center truncate text-black dark:text-white"
              :class="
                supportedPlatforms.desktop ? 'font-semibold' : 'font-normal'
              "
            >
              Desktop
            </div>
            <input
              type="checkbox"
              @click="supportedPlatforms.desktop = !supportedPlatforms.desktop"
              :checked="supportedPlatforms.desktop"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <label class="flex flex-col gap-1.5 py-2 items-center cursor-pointer relative bg-zinc-800 px-2 rounded-md h-max">
        <div class="font-medium text-white">Buy bonus</div>
        <input type="checkbox" class="sr-only" v-model="isBuyBonus">
        <div :class="['block w-14 h-8 rounded-full relative transition-all duration-300', { 'bg-green-500': isBuyBonus, 'bg-red-500': !isBuyBonus }]">
          <div
            class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
            :class="{ 'translate-x-6': isBuyBonus }"
          ></div>
        </div>
      </label>
      <label class="flex flex-col gap-1.5 py-2 items-center cursor-pointer relative bg-zinc-800 px-2 rounded-md h-max">
        <div class="font-medium text-white">Is in Dev</div>
        <input type="checkbox" class="sr-only" v-model="isDev">
        <div :class="['block w-14 h-8 rounded-full relative transition-all duration-300', { 'bg-green-500': isDev, 'bg-red-500': !isDev }]">
          <div
            class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
            :class="{ 'translate-x-6': isDev }"
          ></div>
        </div>
      </label>
      <label class="flex flex-col gap-1.5 py-2 items-center cursor-pointer relative bg-zinc-800 px-2 rounded-md h-max">
        <div class="font-medium text-white">Has Demo</div>
        <input type="checkbox" class="sr-only" v-model="isDemo">
        <div :class="['block w-14 h-8 rounded-full relative transition-all duration-300', { 'bg-green-500': isDemo, 'bg-red-500': !isDemo }]">
          <div
            class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
            :class="{ 'translate-x-6': isDemo }"
          ></div>
        </div>
      </label>
      <div
        class="flex flex-col items-center gap-1.5 bg-zinc-800 px-2 py-2 rounded-md shadow-md w-32 h-max"
      >
        <div class="flex justify-between">
          <span
            class="font-medium text-white"
            >Release date
          </span>
        </div>
        <v-date-picker v-model="release_date" is-dark color="pink" :locale="appLanguage.long">
          <template #default="{ inputValue, inputEvents }">
            <input :value="inputValue" v-on="inputEvents" class="w-[95%] rounded-md py-1 text-center text-white bg-zinc-950" />
          </template>
        </v-date-picker>
      </div>
    </div>
    <div class="w-full flex flex-col items-center gap-1.5 mb-2 mt-3">
      <span class="text-white font-semibold text-lg">Description</span>
      <div class="w-full flex items-center gap-1.5 justify-center">
        <button 
          v-for="language of Object.keys(descriptions)" 
          @click="selectedLanguage = language" 
          :disabled="language !== 'english' && language !== 'italian'"
          class="px-2.5 pb-0.5 pt-1 rounded-md bg-zinc-800 fugaz uppercase xl:hover:text-orange-600 disabled:pointer-events-none transition-all duration-150 disabled:opacity-50"
          :class="selectedLanguage === language ? 'text-orange-600' : 'text-white'"
        >
          {{ language }}
        </button>
      </div>
    </div>
    <RichTextInput :key="selectedLanguage" :description="`${selectedLanguage} description`" :value="descriptions[selectedLanguage]" @input="updateParentValue"/>
    <!-- <div
      class="flex flex-col items-center max-w-full mb-2 w-full"
    >
      <h4 class="capitalize font-semibold text-white text-lg">
        Description English
      </h4>
      <ckeditor :editor="editor" v-model="descriptionTextEn" :config="editorConfig"></ckeditor>
    </div> -->
    <!-- <div
      class="flex flex-col items-center max-w-full my-2 text-white w-full"
    >
      <h4 class="capitalize font-semibold text-white text-lg">
        Description Italian
      </h4>
      <ckeditor :editor="editor" v-model="descriptionTextIt" :config="editorConfig"></ckeditor>
    </div> -->

    <h4 class="font-semibold text-lg text-white mt-4 mb-2">
      Optional info
    </h4>
    <div class="flex flex-wrap gap-3 justify-center" style="max-width: 1100px">
      <div
        v-for="(field, index) in dataFields"
        :key="index"
        class="flex gap-x-2 items-end bg-zinc-800 p-2 rounded-md shadow-md h-max"
      >
        <div class="flex flex-col">
          <label class="capitalize text-white mb-1.5 flex justify-between" :for="field.key"
            >{{ field.key }}:
            <button
              class="bg-red-500 text-white px-2 rounded-sm"
              @click="removeField(index)"
            >
              Remove
            </button>
          </label
          >
          <input
            v-model="field.value"
            :name="field.key"
            type="text"
            class="w-96 rounded-sm px-2 outline-none border-b"
          />
        </div>
      </div>
    </div>
    <form v-if="addingNewField" @submit="addField($event)">
      <input
        v-model="newField"
        type="text"
        placeholder="Field name..."
        class="w-64 px-2 mt-10 rounded-sm mr-2 outline-none border-b"
      />
      <button @click="addField($event)" class="px-2 rounded-sm fugaz uppercase text-white xl:hover:text-orange-600">
        Add Field
      </button>
    </form>
    <button
      v-else
      @click="addingNewField = true"
      class="px-3 text-xl rounded-md my-2 fugaz uppercase text-white xl:hover:text-orange-600"
    >
      Add new field
    </button>
  </div>
</template>

<script>
import RichTextInput from "./RichTextInput.vue"

export default {
  name: "StepThree",
  data() {
    return {
      // game fields
      rtp: 0,
      volatility: 0,
      hit_frequency: 0,
      max_multiplier_win: 0.0,
      type: "SLOT MACHINE",
      release_date: null,
      isDev: false,
      isDemo: false,
      supportedPlatforms: {
        mobile: false,
        desktop: false,
      },
      selectedLanguage: "english",
      descriptionTextEn: "",
      descriptionTextIt: "",
      descriptions: {
        english: "",
        italian: "",
        chinese: "",
        japanese: "",
        korean: "",
        spanish: "",
        french: "",
      },
      format: "HTML5",
      max_bet: 0.0,
      min_bet: 0.0,
      lines: 0,
      reels: 0,
      gameRows: 0,
      isBuyBonus: false,
      // max_exposure_ratio: 0,
      // end of game fields
      dataFields: [
        // Default dataFields
        // { key: "Max paylines", value: "" },
      ],
      propertiesData: {},
      newField: "",
      addingNewField: false,
      payMethod: "Paylines",
    };
  },
  mounted() {
    this.emitter.on("stepNumber3Called", this.updateGameDetails);
    if(Object.keys(this.gameDetails).length){
      this.dataFields = this.gameDetails.dataFields;
      this.descriptionTextEn = this.gameDetails.descriptionTextEn;
      this.descriptionTextIt = this.gameDetails.descriptionTextIt;
      this.format = this.gameDetails.format;
      this.hit_frequency = this.gameDetails.hit_frequency;
      this.isBuyBonus = this.gameDetails.isBuyBonus;
      this.lines = this.gameDetails.lines;
      this.payMethod = this.gameDetails.payMethod;
      this.max_bet = this.gameDetails.max_bet;
      this.max_multiplier_win = this.gameDetails.max_multiplier_win;
      this.min_bet = this.gameDetails.min_bet;
      this.reels = this.gameDetails.reels;
      this.release_date = this.gameDetails.release_date;
      this.isDev = this.gameDetails.isDev;
      this.isDemo = this.gameDetails.isDemo;
      this.gameRows = this.gameDetails.gameRows;
      this.rtp = this.gameDetails.rtp;
      this.supportedPlatforms = this.gameDetails.supportedPlatforms;
      this.type = this.gameDetails.type;
      this.volatility = this.gameDetails.volatility;
    }
  },
  watch: {
    payMethod(value) {
      if (value == 'Scatter' || value == 'Tumble') {
        this.lines = 0
      }
    }
  },
  components: {
    RichTextInput,
  },
  computed: {
    appLanguage() {
      return this.$store.getters['getLanguage']
    },
    name() {
      return this.$store.getters["newGame/basicInfo"].name;
    },
    gameDetails() {
      return this.$store.getters["newGame/gameDetails"];
    }
  },
  methods: {
    updateParentValue(value) {
      this.descriptions[this.selectedLanguage] = value;
    },
    updateGameDetails() {
      this.$store.dispatch("newGame/stepThreeAction", {
        gameDetails: {
          rtp: this.rtp,
          volatility: this.volatility,
          hit_frequency: this.hit_frequency,
          max_multiplier_win: this.max_multiplier_win,
          type: this.type,
          release_date: this.release_date,
          isDev: this.isDev,
          isDemo: this.isDemo,
          supportedPlatforms: this.supportedPlatforms,
          // descriptionTextEn: this.descriptionTextEn,
          // descriptionTextIt: this.descriptionTextIt,
          descriptions: this.descriptions,
          format: this.format,
          max_bet: this.max_bet,
          min_bet: this.min_bet,
          lines: this.lines,
          payMethod: this.payMethod,
          reels: this.reels,
          gameRows: this.gameRows,
          isBuyBonus: this.isBuyBonus,
          dataFields: this.dataFields,
        },
      });
    },
    addField(e) {
      e.preventDefault();
      const newField = {
        key: `${this.newField}`,
        value: "",
      };
      this.dataFields.push(newField);
      this.newField = "";
      this.addingNewField = false;
    },
    removeField(index) {
      this.dataFields.splice(index, 1);
    },
  },
};
</script>

<style>
.ck-editor {
  min-width: 100%; 
  width: 100%; 
  max-width: 100%;
}
.ck-content {
  min-height: 150px;
}
.ck-powered-by {
  display: none;
}
.create .ql-toolbar {
  width: 100%;
  max-width: 100%;
  background-color: white;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.datePicker button {
  padding-bottom: 3px;
  display: flex;
  justify-items: center;
  align-items: center;
}
</style>
