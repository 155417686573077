<template>
  <div class="text-white" v-if="ready">
    <h1 class="title">
      {{ $t('manageGames.certificates') }}
    </h1>
    <div class="flex flex-col bg-zinc-800 rounded-md p-4" :class="{'mt-4': (userRoles.isLudusAdmin || userRoles.isLudusManager)}">
      <h2 class="fugaz uppercase text-lg dark:text-white mb-2" v-if="(userRoles.isLudusAdmin || userRoles.isLudusManager)">
        {{$t('documentation.integration')}}
      </h2>
      <div class="w-full" v-if="(userRoles.isLudusAdmin || userRoles.isLudusManager)">
        <Multiselect
          mode="single"
          v-model="selectedIntegration"
          :options="allIntegrations"
          :close-on-select="true"
          :searchable="true"
          label="name"
          value-prop="id"
          :placeholder="$t('bets.selectIntegration')"
          :loading="loading"
          ref="multiselectPlat"
        />
      </div>
      <div  class="h-fit" v-if="selectedIntegration !== null">
        <h2 class="fugaz uppercase text-lg dark:text-white mb-1" :class="{'mt-6': (userRoles.isLudusAdmin || userRoles.isLudusManager)}">
          General
        </h2>
        <div v-if="generalCertificates.length > 0" class="flex flex-wrap gap-1.5" :class="{'mb-4': generalCertificates.length > 0}">
          <div v-for="(cert, index) in generalCertificates" class="flex flex-col gap-1.5 h-fit">
               <div v-if="!loadingDelete" class="pl-2 pr-1.5 py-1 rounded-md bg-zinc-900 flex items-start justify-between gap-3 relative h-full w-[18.2rem]">
                 <div class="flex flex-col justify-between gap-1.5 w-full">
                   <div class="flex items-center gap-4 justify-between group">
                     <div class="flex tems-center gap-1.5 cursor-pointer w-[87%]">
                      <img :src="require(`@/assets/${showFileIcon(cert.url)}.png`)" width="24" height="24" class="pl-0.5"/>
                       <!-- <font-awesome-icon :icon="['fas', 'file']" class="pl-0.5 pt-0.5" /> -->
                       <p class="truncate">{{ cert.name ? cert.name : '-' }}</p>
                       <div v-if="cert.games && cert.games.length > 0" class="tooltip rounded-full w-4 h-4 text-center mt-0.5 bg-zinc-700" style="aspect-ratio: 1">
                        <font-awesome-icon :icon="['fas', 'info']" class="text-zinc-400 text-xs top-[0.1rem] left-0 w-full absolute font-bold"/>
                          <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="rgb(161, 161, 170)" class="w-5 h-5 mt-0.5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z" />
                          </svg> -->
                          <span class="tooltiptext space-y-0.5">
                            <!-- <p class="text-sm font-light text-zinc-400">This file is part of:</p> -->
                            <p v-for="game in cert.games" class="text-sm font-light text-zinc-400 w-full truncate"> {{ game.name }}</p>
                            <!-- <p v-for="game in cert.games"> {{ game.name }}</p> -->
                          </span>
                          
                        </div>
                       
                     </div>
                     <button @click="downloadFile(cert.url)" class="p-2 -mr-2 -mt-1 xl:hover:text-orange-600 pb-px">
                       <font-awesome-icon :icon="['fas', 'download']" class="cursor-pointer h-3.5 pb-0.5" />
                     </button>
                   </div>
                   <div class="flex items-center gap-4 justify-between">
                     <p class="text-sm font-light text-zinc-400">
                       {{ $t('manageGames.uploadedOn') }} {{ clearDate(cert.updatedAt) }}
                     </p>
                     <button @click="openDelete(index)" class="xl:hover:text-red-500" :class="cert.deleting && 'text-orange-600'" v-if="userRoles.isLudusAdmin || userRoles.isLudusManager">
                       <font-awesome-icon :icon="['fas', 'trash']" class="cursor-pointer h-3.5 pl-px" />
                     </button>
                   </div>
                 </div>
               </div>
               <div v-if="cert.deleting && !loadingDelete" class="flex items-center gap-1.5 pl-2 text-sm">
                 <p class="w-[12rem]">{{ $t('manageGames.sure') }}</p>
                 <button @click="deleteCertificate(cert.id)" class="px-2 pt-0.5 rounded-sm bg-red-500 xl:hover:bg-red-600 fugaz uppercase text-sm">
                   {{ $t('manageGames.yes') }}
                 </button>
                 <button @click="cert.deleting = false" class="px-2 pt-0.5 rounded-sm bg-white xl:hover:bg-zinc-200 text-black fugaz uppercase text-sm">
                   {{ $t('manageGames.no') }}
                 </button>
               </div>
          </div>
        </div>
        <p v-else class="roboto text-sm text-zinc-400 mb-2.5">No files found</p>

           
        <button @click="addingDoc = true" v-if="!addingDoc && (userRoles.isLudusAdmin || userRoles.isLudusManager)" class="py-1 h-16 rounded-md bg-zinc-700 flex items-center justify-center gap-3 px-5 group xl:hover:text-orange-600 mb-4">
          <font-awesome-icon :icon="['fas', 'plus']" class="h-5 transition-all duration-150 xl:group-hover:scale-125" />
        </button>

        <div v-else-if="addingDoc && (userRoles.isLudusAdmin || userRoles.isLudusManager)" class="pl-2 pr-1.5 py-1.5 rounded-md bg-zinc-900 flex items-center gap-2 w-80">
          <div class="flex flex-col justify-between flex-1 w-[74%]">
            <div class="flex items-center gap-1">
              <label for="fileName">{{ $t('manageGames.name') }}:</label>
              <input v-model="fileToUpload.name" type="text" id="fileName" :placeholder="$t('manageGames.certName')" class="w-full bg-zinc-950 outline-none px-1.5 rounded-md" @input="docNameHadUserInput = true">
            </div>
            <label
              :for="'newFile_'+ 'general'"
              class="px-3 py-1 w-full flex justify-center items-center gap-1.5 bg-zinc-800 group cursor-pointer xl:hover:brightness-90"
              >
              <!-- @dragover.prevent="handleDragOver"
              @drop.prevent="handleFileDrop(newGameFields.id)" -->
              <span v-if="(!fileToUpload.file)">{{ $t('manageGames.uploadFile') }}</span>
              <font-awesome-icon
                v-if="(!fileToUpload.file)"
                class="text-orange-600 h-4 group-hover:scale-110 transition-all delay-50"
                icon="fa-solid fa-plus"
              />
              <span class="truncate" v-else>
                {{ fileToUpload.file.name }}
              </span>
              <input
                class="hidden"
                type="file"
                :id="'newFile_'+ 'general'"
                :ref="'newFile_'+ 'general'"
                @change="handleFileUpload()"
                />
              </label>
              <label class="flex items-center checkbox mt-2">
                <div
                  class="flex items-center truncate text-black dark:text-white"
                >
                  <!-- :class="
                    supportedPlatforms.desktop ? 'font-semibold' : 'font-normal'
                  " -->
                  Apply to multiple games
                </div>
                <!-- @click="supportedPlatforms.desktop = !supportedPlatforms.desktop" -->
                <input
                  type="checkbox"
                  v-model="multipleGames"
                />
                <span class="checkmark"></span>
              </label>
              <div v-if="multipleGames" class="w-full md:w-max whitespace-nowrap rounded-md mt-2"
                  :class="{
                    'min-w-[225px]': appLanguage.long == 'en-GB',
                    'min-w-[270px]': appLanguage.long == 'it-IT',
                  }">
                
              
              <Multiselect
                  v-model="multipleGamesIds"
                  mode="multiple"
                  :options="allGames"
                  label="name"
                  value-prop="id"
                  :placeholder="$t(`Select games`)"
                  :hide-selected="false"
                  :caret="false"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :multiple-label="(values) => `${values.length} games selected`"
                  :loading="!ready"
                />
              
              </div>
              <!-- @click="$event.target.value = null" -->
          </div>
          <div class="flex flex-col justify-between gap-1">
            <button @click="cancelAddingDoc" class="px-2 pt-[3px] pb-0.5 rounded-sm bg-red-600 xl:hover:bg-red-500 fugaz uppercase text-sm">
              {{ $t('manageGames.cancel') }}
            </button>
            <!-- <button @click="uploadCertificate" :disabled="!fileToUpload.file"  -->
              <!-- :class="fileToUpload.file && 'xl:hover:bg-green-500'"> -->
            <button
              @click="uploadCertificate"
              class="px-2 pt-[3px] pb-0.5 rounded-sm bg-green-600 fugaz uppercase text-sm disabled:pointer-events-none disabled:opacity-50" >
              {{ $t('manageGames.save') }}
            </button>
          </div>
        </div>
        <h2 class="fugaz uppercase text-lg dark:text-white mb-1" :class="{'mt-6': (userRoles.isLudusAdmin || userRoles.isLudusManager)}">
          {{$t('documentation.game')}}
        </h2>
        <div @click.self="selectedGame === game.id ? selectedGame = null : selectedGame = game.id" class="p-4 rounded-md bg-zinc-850 mb-2"  v-for="game in allGames">
          <!-- <button class=" items-center bg-zinc-900 w-full"> -->
            <div @click="selectedGame === game.id ? selectedGame = null : selectedGame = game.id"  class="flex justify-between ">
              <p class="roboto">{{game.name}}</p>
              <font-awesome-icon v-if="selectedGame === game.id" :icon="['fas', 'sort-up']" class="text-sm text-zinc-400"/>
              <font-awesome-icon v-else :icon="['fas', 'sort-down']" class="text-sm text-zinc-400"/>
            </div>
          <!-- </button> -->
          <div v-if="selectedGame !== null && selectedIntegration !== null && !loading && selectedGame === game.id" class="flex flex-col">
          <p v-if="certificates.length > 0" class="roboto text-sm text-zinc-400 mt-4">Files</p>
          <div v-if="certificates.length > 0"class="flex flex-wrap items-center gap-1.5 pt-1">
            <div v-for="(cert, index) in certificates" class="flex flex-col items-center gap-1.5">
              <div v-if="!loadingDelete" class="pl-2 pr-1.5 py-1 rounded-md bg-zinc-900 flex items-end justify-between gap-3 relative h-full w-[18.2rem]">
                <div class="flex flex-col justify-between gap-1.5 w-full">
                  <div class="flex items-center gap-4 justify-between group w-full">
                    <div @click="downloadFile(cert.url)" :title="cert.name" class="flex tems-center gap-1.5 cursor-pointer w-[87%]">
                      <img :src="require(`@/assets/${showFileIcon(cert.url)}.png`)" width="24" height="24" class="pl-0.5"/>
                      <!-- <font-awesome-icon :icon="['fas', 'file']" class="pl-0.5 pt-0.5" /> -->
                      <span class="truncate">{{ cert.name ? cert.name : '-' }}</span>
                    </div>
                    <button @click="downloadFile(cert.url)" class="xl:group-hover:text-orange-600 pb-px">
                      <font-awesome-icon :icon="['fas', 'download']" class="cursor-pointer h-3.5 pb-0.5" />
                    </button>
                  </div>
                  <div class="flex items-center gap-4 justify-between">
                    <p class="text-sm font-light text-zinc-400">
                      {{ $t('manageGames.uploadedOn') }} {{ clearDate(cert.updatedAt) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <button @click="creating = true" v-if="!creating" class="py-1 h-16 rounded-md bg-zinc-900 flex items-center justify-center gap-3 px-5 group xl:hover:text-orange-600">
              <font-awesome-icon :icon="['fas', 'plus']" class="h-5 transition-all duration-150 xl:group-hover:scale-125" />
            </button>
            <div v-if="creating && !loadingCreate" class="pl-2 pr-1.5 py-1.5 rounded-md bg-zinc-900 flex items-center gap-2 w-80">
              <div class="flex flex-col justify-between">
                <div class="flex items-center gap-1">
                  <label for="fileName">{{ $t('manageGames.name') }}:</label>
                  <input v-model="fileToUpload.name" type="text" id="fileName" :placeholder="$t('manageGames.certName')" class="w-full bg-zinc-950 outline-none px-1.5 rounded-md">
                </div>
                <label
                  :for="'newFile_'+ game.id"
                  class="px-3 py-1 w-full flex justify-center items-center gap-1.5 bg-zinc-800 group cursor-pointer xl:hover:brightness-90"
                  >
                  <!-- @dragover.prevent="handleDragOver"
                  @drop.prevent="handleFileDrop(game.id)" --
                  <span v-if="!fileToUpload.file">{{ $t('manageGames.uploadFile') }}</span>
                  <font-awesome-icon
                    v-if="!fileToUpload.file"
                    class="text-orange-600 h-4 group-hover:scale-110 transition-all delay-50"
                    icon="fa-solid fa-plus"
                  />
                  <div v-else>
                    {{ fileToUpload.file.name }}
                  </div>
                  <input
                    class="hidden"
                    type="file"
                    :id="'newFile_'+ game.id"
                    :ref="'newFile_'+ game.id"
                    @change="handleFileUpload('mainImage', game.id)"
                    />
                  </label>
                  <!-- @click="$event.target.value = null" --
              </div>
              <div class="flex flex-col justify-between gap-1">
                <button @click="cancelCreating" class="px-2 pt-[3px] pb-0.5 rounded-sm bg-red-600 xl:hover:bg-red-500 fugaz uppercase text-sm">
                  {{ $t('manageGames.cancel') }}
                </button>
                <button @click="uploadCertificate" :disabled="!fileToUpload.file" 
                  class="px-2 pt-[3px] pb-0.5 rounded-sm bg-green-600 fugaz uppercase text-sm disabled:pointer-events-none disabled:opacity-50" 
                  :class="fileToUpload.file && 'xl:hover:bg-green-500'">
                  {{ $t('manageGames.save') }}
                </button>
              </div>
            </div> -->
            <div v-if="loadingCreate" class="py-1.5 rounded-md bg-zinc-900 flex items-center gap-2 w-max h-16 px-4">
              <img src="../../assets/loading2.png" alt="loading icon"
                class="text-zinc-600 dark:text-zinc-400 h-7 animate-spin"
              />
            </div>
          </div>
          <p v-else class="roboto text-sm text-zinc-400 mt-2">No files found</p>
        </div>
        </div>
      </div>
      <!-- <div class="w-full">
        <Multiselect
          mode="single"
          v-model="selectedGame"
          :options="allGames"
          :close-on-select="true"
          :searchable="true"
          label="name"
          value-prop="id"
          :placeholder="$t('bets.selectGame')"
          :loading="loading"
          ref="multiselectGame"
        />
      </div> -->
     
    </div>
  </div>
  <div
      v-else
      class="w-full h-[90vh] flex justify-center items-center gap-3 bg-zinc-950 text-white mt-4"
    >
      <img src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
    </div>

</template>
<script>
  import request from '@/services/axios'
  import Multiselect from '@vueform/multiselect'
  import { getFileExtension } from "@/helpers/getFileExtension.js";

  export default {
    data() {
      return {
        selectedIntegration: null,
        selectedGame: null,
        loading: false,
        certificates: [],
        deleting: {
          open: false,
          certificate: {}
        },
        fileToUpload: {
          name: '',
        },
        creating: false,
        loadingCreate: false,
        loadingDelete: false,
        nothingFound: false,
        ready: false,
        addingDoc: false,
        multipleGames: false,
        multipleGamesIds: [],
        generalCertificates: [],
        docNameHadUserInput: false,
      }
    },
    components: {
      Multiselect,
    },
    props: {
      allGames: Array,
      allIntegrations: Array,
      sortByName: Function,
    },

    async mounted() {
      if (!this.userRoles.isLudusAdmin && !this.userRoles.isLudusManager) {
        try {
          const integration = (await request.get(`/integrations/byName/${this.user.attributes.integration[0]}`)).data
  
          this.selectedIntegration = integration.id
          this.ready = true;
          
        } catch (error) {
          this.ready = true;
          console.log(error)
        }
      } else {
        this.ready = true;
      }
    },
    watch: {
      selectedIntegration(val) {
        if (val !== null) {
          this.loadCertificates(true, val);
          this.selectedGame = null;
        } else {
          this.generalCertificates = []
        }
      },
      selectedGame(val, oldVal) {
        if (this.selectedGame !== null && val !== null) {
          this.loadCertificates()
        } else {
          this.certificates = []
        }
      },
    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
      user() {
        return this.$store.getters['user/user']
      },
      userRoles() {
        return this.user.localRoles
      },
    },
    methods: {
      showFileIcon(fileUrl = null) {
        const extension = getFileExtension(fileUrl)

        switch (extension) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'gif':
            return 'image'
          case 'zip':
            return 'zip';
          case 'pdf':
            return 'pdf'
          case 'xlsx':
          case 'xls':
            return 'excel'
          case 'txt':
          case 'docx':
          case 'doc':
            return 'word'
          case 'mp4':
          case 'mov':
          case 'avi':
          case 'webm':
            return 'video'
          default:
            return 'file'
        }
      },

      cancelAddingDoc() {
      this.addingDoc = false
      this.fileToUpload = {
        name: ''
      }
    },
      cancelCreating() {
        this.creating = false
        this.fileToUpload = {
          name: ''
        }
      },
      handleDragOver(event) {
        event.preventDefault();
      },
      handleFileDrop(gameId) {
        const refName = 'newFile_' + gameId;
        debugger;
        this.$refs[refName].files = dataTransfer.files;
        this.handleFileUpload();
      },
      // async handleFileUpload(_ = null, gameId) {
      //   // TODO: improve this code to be more efficient
      //   const refName = 'newFile_' + gameId;
      //   console.log('this.$refs[refName]', this.$refs[refName]);
        
      //   const file = this.$refs[refName].files[0];
      //   const fileSrc = URL.createObjectURL(file);
      //   if(this.fileToUpload.name === '') {
      //     this.fileToUpload.name = file.name;
      //   }
      //   this.fileToUpload.file = file,
      //   this.fileToUpload.fileName = file.name,
      //   this.fileToUpload.tempFile = fileSrc
      // },
      
      handleFileUpload(gameId = null) {
        this.$nextTick(() => {
          if (!gameId) gameId="general"
            const refName = 'newFile_' + gameId;
            setTimeout(() => {
            const fileInput = this.$refs[refName];
            console.log('Resolved fileInput:', fileInput);

            if (!fileInput) {
                console.error(`Ref '${refName}' not found`);
                return;
            }

            const file = fileInput.files?.[0];

            if (!file) {
                console.error('No file selected');
                return;
            }

            if (this.fileToUpload.name == '' || !this.docNameHadUserInput) {
              this.fileToUpload = {
                  name: file.name,
                  file: file
              };
            } else {
              this.fileToUpload.file = file
            }

            console.log('File successfully uploaded:', this.fileToUpload);
        }, 100);
        });
      },  

      openDelete(index) {
        this.generalCertificates[index].deleting = true;

      },
      downloadFile(s3Url) {
        const link = document.createElement('a');
        link.href = s3Url;
        link.setAttribute('download', '');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      checkBothSelected() {
        if (this.selectedGame !== null && this.selectedIntegration !== null) {
          this.loadCertificates()
        } else return
      },
      clearDate(date) {
        const options = {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        }
        return new Date(date).toLocaleDateString(this.appLanguage.long, options)
      },
      async loadCertificates(isGeneral = false) {
        this.loading = true
        try {
          let certificates = null
          if (!isGeneral) {
            certificates = (await request.get(`/certificates?gameId=${this.selectedGame}&integrationId=${this.selectedIntegration}`)).data
            this.certificates = certificates
          } else {
            certificates = (await request.get(`/certificates?integrationId=${this.selectedIntegration}`)).data
            this.generalCertificates = certificates = certificates
          }
          // if (certificates.length == 0) {
          //   this.nothingFound = true
          // } else {
          //   this.nothingFound = false
          // }
        } catch (e) {
          console.log(e)
        }
        this.loading = false
      },
      async uploadCertificate() {
        this.loadingCreate = true
        const formData = new FormData()
        // ORDER OF append to body is VERY important for SERVER processing; if you have body stuff plz add it first
        formData.append('isGeneral', "true");
        
        
        // EXAMPLE OF MULTI GAME ASSOCIATION FOR A DOCUMENT : 
        // formData.append("gameId[]", 1);
        // formData.append("gameId[]", 16);
        // formData.append("gameId[]", 17);
        console.log('this.multipleGamesIds.length', this.multipleGamesIds.length);
        console.log('this.multipleGamesIds', this.multipleGamesIds);


        if (this.multipleGames && this.multipleGamesIds.length > 0) {
          for (const gameId of this.multipleGamesIds) {
            formData.append("gameId[]", gameId);
          }
        }
        formData.append("integrationId", this.selectedIntegration);

        formData.append('name', this.fileToUpload.name)
        formData.append('file', this.fileToUpload.file)
        try {
          //const response = await request.post(`/certificates/create/${this.selectedGame}/${this.selectedIntegration}`, formData, {
          // const response = await request.post(`/certificates/create/${this.selectedIntegration}`, formData, {  
          const response = await request.post(`/certificates/create`, formData, {  
            headers: {
              'Content-Type':'multipart/form-data'
            }
          })
          this.generalCertificates.push(response.data)

          if (this.multipleGamesIds.find((id => this.selectedGame))) {
            this.certificates.push(response.data)
          }

          this.nothingFound = false
          this.fileToUpload = {
            name: '',
            file: null
          }
          this.$toast.success('General document created')
          this.creating = false,
          this.multipleGames = false;
          this.multipleGamesIds = [];
          this.addingDoc = false;
        } catch (e) {
          console.log(e)
        }
        this.loadingCreate = false
      },
      async deleteCertificate(id) {
        this.loadingDelete = true
        try {
          await request.delete(`/certificates/delete/${id}`)
          this.generalCertificates = this.generalCertificates.filter(cert => cert.id !== id)
          this.certificates = this.certificates.filter(cert => cert.id !== id);
          this.$toast.success('General document deleted')
        } catch (e) {
          console.log(e)
        }
        this.loadingDelete = false
        this.addingDoc = false;
      }
    }
  }

</script>

<style lang="postcss" scoped>

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 175px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  text-align: left;
  padding: 0.5rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

</style>