<template>
  <div>
    <!-- header  -->
    <div class="flex justify-between items-center w-full pb-4">
      <h2 class="title">
        {{ $t('integrations.title') }}
      </h2>
      <div v-if="ready" class="w-full flex justify-end gap-x-2.5">
        <button v-if="!syncing" @click="syncIntegrations" 
          class="md:text-lg fugaz uppercase text-white xl:hover:text-orange-600"
        >
          {{ $t('integrations.syncIntegrations') }}
        </button>
        <div v-else class="md:text-lg fugaz uppercase text-white">
          ...syncing
        </div>
        <router-link :to="{ name: 'New integration'}" 
          class="md:text-lg fugaz uppercase text-white xl:hover:text-orange-600"
        >
          {{ $t('integrations.addIntegration') }}
        </router-link>
      </div>
    </div>
    <div class="flex flex-col h-full w-full">
      <div
        v-if="ready" class="rounded-lg shadow-md flex flex-col pb-[50px] xl:pb-0">
        <!-- search  -->
        <div class="w-full h-max flex justify-between items-center pb-3 shadow-sm z-30">
          <input type="text" v-model="search" :placeholder="$t('integrations.searchIntegration')" class="p-2 rounded-md 1/2 xl:w-1/3 outline-none bg-zinc-900 text-zinc-500 shadow-sm">
        </div>

        <BaseTable  
          :cols="12"
          :header="[
            {text: $t('integrations.name'), colSpan: 6, sortUp: 'nameInverse', sortDown: 'name'}, 
            {text: $t('integrations.operators'), colSpan: 2, sortUp: 'operators.lengthInverse', sortDown: 'operators.length', hideInMobile: true, style: 'justify-center'},
            {text: $t('integrations.users'), colSpan: 2, sortUp: 'users.lengthInverse', sortDown: 'users.length', hideInMobile: true, style: 'justify-center'},
            {text: $t('integrations.games'), colSpan: 2, sortUp: 'games.lengthInverse', sortDown: 'games.length', style: 'justify-center'},
            ]"
          :rowSlot="true"
          :tableSort="tableSort"
          @sort-table="sortTable"
          >
          <template #body>
            <tr v-for="integration in sortedIntegrations" :key="integration.id" 
            class="cursor-pointer text-white hover:brightness-125 px-6 py-2"
            @click="goToIntegration(integration)"
            :class="['grid', `grid-cols-${12}`, {'bg-zinc-850': n % 2 === 0, 'bg-zinc-900': n % 2 !== 0}]">
              <td class="col-span-6 text-left cursor-pointer whitespace-nowrap font-medium">
                  {{ integration.name }}
                  <font-awesome-icon 
                    v-if="integration.version == 'GAI-INTERNATIONAL'"
                    icon="fas fa-bolt"
                    class="h-5" style="color: mediumturquoise"
                    label="international"
                    title="international"
                  />
                <span class="hidden md:block text-orange-600 whitespace-nowrap text-xs font-normal">{{ integration.playServiceURL }}</span>
              </td>
              <td class="hidden md:block col-span-2 text-center content-center font-medium">
                {{ integration.operators.length }}
              </td>
              <td class="hidden md:block col-span-2 content-center text-center font-medium">
                {{ integration.users.length }}
              </td>
              <!-- whitespace-nowrap  -->
              <td class="col-span-2 content-center text-center font-medium">
                {{ integration.games.length }}
              </td>
            </tr>
          </template>
        </BaseTable>

        <!-- <div class="pb-4 align-middle inline-block min-w-full z-20">
          <div class="shadow relative rounded-tl-md rounded-bl-md">
            <table class="table table-auto border-separate border-spacing-y-[4px] w-full">
              <thead class="bg-zinc-800 text-white sticky top-0 fugaz uppercase">
                <tr>
                  <th @click="sortTable('name')" scope="col" class="py-3 text-left text-md font-medium uppercase cursor-pointer w-56">
                    
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                    {{ $t('integrations.name') }}
                    <font-awesome-icon v-if="tableSort == 'name'" :icon="['fas', 'sort-down']" class="pb-1 px-2"/>
                    <font-awesome-icon v-if="tableSort == 'nameInverse'" :icon="['fas', 'sort-up']" class="pt-1 px-2"/>
                    
                    <font-awesome-icon v-if="tableSort !== 'name' && tableSort == 'nameInverse'" :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                  </th>
                  <th scope="col" class="text-left">
                  </th>
                  <th @click="sortTable('operators.length')" scope="col" class="hidden md:table-cell justify-center py-3 text-center text-md font-medium uppercase cursor-pointer w-44">
                    
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                    {{ $t('integrations.operators') }}
                    <font-awesome-icon v-if="tableSort == 'operators.length'" :icon="['fas', 'sort-down']" class="pb-1 px-2"/>
                    <font-awesome-icon v-if="tableSort == 'operators.lengthInverse'" :icon="['fas', 'sort-up']" class="pt-1 px-2"/>
                    
                    <font-awesome-icon v-if="tableSort !== 'operators.length' && tableSort !== 'operators.lengthInverse'" 
                      :icon="['fas', 'sort-down']" 
                      class="pb-1 px-2 invisible"
                    />
                  </th>
                  <th @click="sortTable('users.length')" scope="col" class="hidden md:table-cell py-3 text-center text-md font-medium uppercase cursor-pointer w-44">
                    
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                    {{ $t('integrations.users') }}
                    <font-awesome-icon v-if="tableSort == 'users.length'" :icon="['fas', 'sort-down']" class="pb-1 px-2"/>
                    <font-awesome-icon v-if="tableSort == 'users.lengthInverse'" :icon="['fas', 'sort-up']" class="pt-1 px-2"/>
                    
                    <font-awesome-icon v-if="tableSort !== 'users.length' && tableSort !== 'users.lengthInverse'" 
                      :icon="['fas', 'sort-down']" 
                      class="pb-1 px-2 invisible"
                    />
                  </th>
                  <th @click="sortTable('games.length')" scope="col" class="py-3 text-center text-md font-medium uppercase cursor-pointer w-44">
                    ghost icon 
                    <font-awesome-icon :icon="['fas', 'sort-down']" class="pb-1 px-2 invisible"/>
                    {{ $t('integrations.games') }}
                    <font-awesome-icon v-if="tableSort == 'games.length'" :icon="['fas', 'sort-down']" class="pb-1 px-2"/>
                    <font-awesome-icon v-if="tableSort == 'games.lengthInverse'" :icon="['fas', 'sort-up']" class="pt-1 px-2"/>
                    ghost icon
                    <font-awesome-icon v-if="tableSort !== 'games.length' && tableSort !== 'games.lengthInverse'" 
                      :icon="['fas', 'sort-down']" 
                      class="pb-1 px-2 invisible"
                    />
                  </th>
                </tr>
              </thead>
              <tbody v-if="integrationsFromDB && integrationsFromDB.length" class="fugaz uppercase">
                <tr v-for="(integration, index) in sortedIntegrations" :key="integration.id" 
                  class="hover:bg-opacity-90 cursor-pointer text-white hover:brightness-125"
                  @click="goToIntegration(integration)"
                  :class="index % 2 === 0 ? 'bg-zinc-850' : 'bg-zinc-900'">
                  <td class="pl-7 whitespace-nowrap items-center">
                      {{ integration.name }}
                      <font-awesome-icon 
                        v-if="integration.version == 'GAI-INTERNATIONAL'"
                        icon="fas fa-bolt"
                        class="h-5" style="color: mediumturquoise"
                        label="international"
                        title="international"
                      />
                  </td>
                  <td class="hidden md:table-cell whitespace-nowrap text-base md:text-lg">
                    <span class="text-xs text-orange-600">{{ integration.playServiceURL }}</span>
                  </td>
                  <td class="hidden lg:table-cell px-6 whitespace-nowrap text-base md:text-lg text-zinc-500 dark:text-zinc-200 text-center">
                    0
                  </td>
                  <td class="hidden md:table-cell whitespace-nowrap text-base md:text-lg text-center w-44">
                    {{ integration.operators.length }}
                  </td>
                  <td class="hidden md:table-cell whitespace-nowrap text-base md:text-lg text-center w-44">
                    {{ integration.users.length }}
                  </td>
                  <td class="py-4 whitespace-nowrap text-base md:text-lg text-center w-44">
                    {{ integration.games.length }}
                  </td>
                </tr>
              </tbody>
              <div v-else class="text-white py-2">
                {{ $t('integrations.nothingToShow') }}
              </div>
            </table>
          </div>
        </div> -->
      </div>
      <div
        v-else
        class="w-full h-[90vh] flex justify-center items-center gap-3 bg-zinc-950 text-white"
      >
        <img v-if="!serverError" src="../../assets/hub-full-logo.png" alt="gai hub logo" class="h-24 w-auto animate-pulse">
        <!-- <img
          src="../../assets/loading2.png"
          alt="loading icon"
          v-if="!serverError"
          class="h-12 animate-spin mb-2"
        /> -->
        <div v-else>
          {{ $t('serverError') }}
          <button @click="$router.go()" class="text-blue-600">{{ $t('reload') }}</button> 
          {{ $t('tryAgain') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../../services/axios'
import BaseTable from '@/components/utils/BaseTable.vue';
export default {
  name: 'IntegrationsView',
  data() {
    return {
      integrationsFromDB: [],
      tableSort: 'games.lengthInverse',
      search: '',
      currentPage: 1,
      perPage: 50,
      ready: false,
      serverError: false,
      syncing: false,
      mountTimer: null,
    }
  },

  components: {
    BaseTable
  },

  async created() {
    try {
      const response = await request.get('/integrations?with=users&with=operators&with=games')
      this.integrationsFromDB = response.data.rows
      this.ready = true
    } catch (error) {
      console.log(error);
      this.serverError = true;
    }
  },
  computed: {
    integrations() {
      let searchedIntegrations = this.integrationsFromDB.filter(plat => {
        if (plat.name.toLowerCase().includes(this.search.toLowerCase())) return plat
      })
      return searchedIntegrations.length ? searchedIntegrations : []
    },
    paginatedIntegrations() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.integrations.slice(start, end)
    },
    sortedIntegrations(){
      return this.sortIntegrations(this.integrations)
    }
  },
  methods: {
    goToIntegration(integration) {
      this.$router.push({ name: 'Integration', params: { id: integration.id } })
    },
    sortTable(column) {
      if (this.tableSort === column) {
        this.tableSort += 'Inverse';
      } else {
        this.tableSort = column;
      }
    },
    getPropertyValue(object, propertyString) {
      const properties = propertyString.split('.');
      let value = object;
      for (let i = 0; i < properties.length; i++) {
        value = value[properties[i]];
      }
      return value;
    },
    sortIntegrations(integrations) {
      if (this.tableSort) {
        let sort = this.tableSort;
        let reverse = false;
        if (sort.includes('Inverse')) {
          sort = sort.replace('Inverse', '');
          reverse = true;
        }
        integrations.sort((a, b) => {
          const valueA = this.getPropertyValue(a, sort);
          const valueB = this.getPropertyValue(b, sort);
          let comparison = 0;
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            const upperA = valueA.toUpperCase();
            const upperB = valueB.toUpperCase();
            if (upperA > upperB) {
              comparison = 1;
            } else if (upperA < upperB) {
              comparison = -1;
            }
          } else if (valueA > valueB) {
            comparison = 1;
          } else if (valueA < valueB) {
            comparison = -1;
          }
          return reverse ? comparison * -1 : comparison;
        });
      }
      return integrations;
    },
    async getIntegrations() {
      try {
        const response = await request.get('/integrations?with=users&with=operators&with=games')
        this.integrationsFromDB = response.data.rows
      } catch (error) {
        console.log(error);
        this.serverError = true;
      }
    },
    async syncIntegrations() {
      this.syncing = true;
      try {
        await request.get('/integrations-synch')
        await this.getIntegrations()
        this.$toast.success('Integrations synchronized successfully!', {
          position: 'top-right',
          duration: 2000,
          pauseOnHover: true,
        })
      } catch (error) {
        console.log(error);
        this.$toast.error('Failed to synchronize integrations', {
          position: 'top-right',
          duration: 2000,
          pauseOnHover: true,
        })
      }
      this.syncing = false;
    }
  }
}
</script>

<style>

</style>