<template>
  <table class="base-table table table-auto border-separate border-spacing-y-[4px] w-full border-zinc-900 rounded-xl overflow-hidden">
    <thead class="bg-zinc-800 text-white sticky top-0 fugaz uppercase tracking-widest">
      <!-- HEADER DEFAULT -->
      <tr v-if="!headerSlot" :class="['grid','px-6 py-4', `grid-cols-${cols}`]">
        <th
          v-for="h in header" @click="h.sortDown && h.sortUp ? sortTable(`${h.sortDown}`) : null" scope="col"
          :class="['select-none', h.style, h.colSpan ? `col-span-${h.colSpan}` : '', {
            'hidden md:flex': h.hideInMobile, 
            'flex': !h.hideInMobile ,
            'cursor-pointer gap-x-3': h.sortUp && h.sortDown
        }]">
        {{ h.text }}
        <span v-if="h.sortUp && h.sortDown">
            <font-awesome-icon :icon="['fas', 'sort-up']" :class="['block', {'opacity-30': tableSort !== h.sortUp}]"/>
            <font-awesome-icon :icon="['fas', 'sort-down']" :class="['block -mt-3', {'opacity-30': tableSort !== h.sortDown}]"/>
          </span>
        </th>
      </tr>
      <!-- HEADER SLOT -->
      <slot name="header" v-else></slot>
    </thead>
    <tbody class="roboto uppercase">
      <!-- BODY DEFAULT -->
      <tr v-if="!rowSlot" v-for="(row, index) in body" :key="index" 
        class="cursor-pointer text-white hover:brightness-125 px-6 py-2"
        :class="['grid', `grid-cols-${cols}`, {'bg-zinc-850': index % 2 === 0, 'bg-zinc-900': index % 2 !== 0}]"
        @click="isRowSelectable ? $emit('rowSelected', row) : null">
        <td 
          v-for="cell in rowCells" 
          :class="[cell.style, cell.colSpan ? `col-span-${cell.colSpan}` : '', 'cursor-pointer whitespace-nowrap font-medium', {'text-lg font-semibold': cell.type === 'currency', 'text-green-500': cell.isPAndL && cell.type === 'currency' && Number(row[cell.key]) >= 0, 'text-red-500': cell.isPAndL && cell.type === 'currency' && Number(row[cell.key]) < 0}]"
          v-html="formatCellValue(row[cell.key], cell.type)"
          >
      </td>
      </tr> 
        <!-- BODY SLOT -->
        <slot name="body" v-else></slot>
    </tbody>
    <!-- 
    <div v-else class="text-white py-2">
      {{ $t('platforms.nothingToShow') }}
    </div> -->
  </table>
</template>

<script>
export default {
    name: 'BaseTable',

    props: {
      cols: {
        type: Number,
        default: 12,
      },
      noResultsFound: {
        type: Boolean,
        default: false,
      },
      hasPagination: {
        type: Boolean,
        default: false,
      },
      header: {
        type: Array,
        required: true,
      },
      rowSlot: {
        type: Boolean,
        default: false
      },
      body: {
        type: Array,
        required: false,
      },
      tableSort: {
        type: String,
        required: false,
      },
      rowCells: {
        type: Array,
        required: false,
      },
      isRowSelectable: {
        type: Boolean,
        default: false,
      },
      headerSlot: {
        type:Boolean,
        default: false
      }
    },

    methods: {
        sortTable(column) {
          this.$emit('sortTable', column)
        },

        formatCellValue(value, type) {
          //SE ALGUEM QUISER ADICIONAR MAIS TIPOS DE DADOS, UM SWITCH É ACONSELHADO

          if (typeof value === 'string') {
            return value;
          }

          // if (typeof value === 'array') {
          //   return 
          // }

          if (type === 'currency') {
            if (typeof value === 'string') {
              value = Number(value)
            }
            const formattedValue = this.getCurrencyValue(value); 
            // const match = formattedValue.match(/^(.*?)([.,]\d+\s?\w+)?$/);

            // if (match) {
            //   const integerPart = match[1];
            //   const decimalPart = match[2] || '';
              
            //   return `${integerPart}<small>${decimalPart}</small>`;
            // }

            return formattedValue;
          }

          return value;
        }
    }
}
</script>

<style lang="postcss">
.base-table {
  /* td {
    @apply text-white;
  } */
  
  small {
    font-size: 0.8em; /* Adjust size as needed */
    vertical-align: bottom; /* Align smaller text appropriately */
    font-weight: normal; /*Same as 400*/
    margin-left: 0.1rem;
  }
}

</style>