<template>
    <ManageCertificates v-if="ready && !serverError" :allGames="catalogGames" :allIntegrations="allIntegrations" :sortByName="sortByName"/>
    <div v-else class="w-full h-[90vh] flex justify-center items-center gap-3 bg-zinc-950 text-white mt-4">
      <img v-if="!ready" src="../../assets/hub-full-logo.png" alt="hub logo" class="h-24 w-auto animate-pulse">
      <p v-if="serverError"> {{ $t('serverError') }}</p>
    </div>
</template>

<script>
import request from "@/services/axios";
import ManageCertificates from '../../components/games/ManageCertificates.vue'


export default {
    name: 'Documentation',
    components: {
      ManageCertificates,
    },

    data() {
        return {
            catalogGames: [],
            allIntegrations: [],
            ready: false,
            serverError: false
        }
    },
    

    async created() {
        try {
            this.catalogGames = (await request.get('/catalog?with=integrations')).data?.rows;
            if (this.$store.getters['user/user'].localRoles.isLudusAdmin || this.$store.getters['user/user'].localRoles.isLudusManager) {
                const integrations = (await request.get('/integrations?with=games&active=1')).data.rows
                this.allIntegrations = integrations
                this.allIntegrations.sort(this.sortByName)
                this.ready = true;
            } else {
                this.ready = true;
            }

        } catch (e) {
            console.log(e);
            this.ready = true;
            this.serverError = true;
        }
    },

    methods: {
        sortByName(a, b) {
            return a.name.localeCompare(b.name)
        },
    }
}
</script>

<style>

</style>