<template>
  <div class="grid grid-cols-3 gap-x-1.5 w-fit md:gap-x-2.5 text-white">
    <div class="rounded-sm fugaz uppercase flex flex-col">
      <span class="text-xs md:text-sm">Bet</span> 
      <div v-if="!loading" class="text-base md:text-lg text-sky-600 flex items-end gap-px">
        <span>{{ bet[0] }}</span><span class="text-[10px] md:text-xs -mb-[2px] md:mb-0 md:pb-[5px]">,{{ bet[1] }}</span>
      </div>
      <div v-else class="w-20 h-4 rounded-sm bg-sky-600 bg-opacity-50 animate-pulse"></div>
    </div>
    <div class="rounded-sm fugaz uppercase flex flex-col">
      <span class="text-xs md:text-sm">Win</span> 
      <div v-if="!loading" class="text-base md:text-lg text-orange-600 flex items-end gap-px">
        <span>{{ win[0] }}</span><span class="text-[10px] md:text-xs -mb-[2px] md:mb-0 md:pb-[5px]">,{{ win[1] }}</span>
      </div>
      <div v-else class="w-20 h-4 rounded-sm bg-orange-600 bg-opacity-50 animate-pulse"></div>
    </div>
    <div class="rounded-sm fugaz uppercase flex flex-col">
      <span class="text-xs md:text-sm">Gross win</span> 
      <div v-if="!loading" class="text-base md:text-lg flex items-end gap-px"  :class="gross[0] >= 0 ? 'text-emerald-600' : 'text-red-500'">
        <span>{{ gross[0] }}</span><span class="text-[10px] md:text-xs -mb-[2px] md:mb-0 md:pb-[5px]">,{{ gross[1] }}</span>
      </div>
      <div v-else class="w-20 h-4 rounded-sm bg-emerald-600 bg-opacity-50 animate-pulse"></div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        bet: 0,
        win: 0,
        gross: 0,
      }
    },
    props: {
      allBet: Number,
      allWin: Number,
      grossWin: Number,
      loading: Boolean,
    },
    mounted() {
      if (!this.loading) {
        this.updateValues()
      }
    },
    watch: {
      loading() {
        if (!this.loading) {
          this.updateValues()
        }
      }
    },
    methods: {
      updateValues() {
        this.bet = this.getSplittedCurrencyValue(this.allBet)
        this.win = this.getSplittedCurrencyValue(this.allWin)
        this.gross = this.getSplittedCurrencyValue(this.grossWin)
      },
      getSplittedCurrencyValue(value) {
        const currencyValue =  new Intl.NumberFormat('it-IT', {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'code', // Use the full name of the currency
        }).format(value)
        const currencySplited = currencyValue.split(',')
        return currencySplited
      },
    }
  }
</script>

<style scoped>

</style>