<template>
  <div class="z-20 w-[45%] h-max flex flex-col gap-1 bg-zinc-800 rounded-lg text-white">
    <div class="px-5 pt-4 pb-2.5 shadow-md border-b border-zinc-700 rounded-t-lg flex items-center gap-2.5 relative">
      <button @click.stop="closeSessionsCSV" class="absolute top-2.5 right-2.5 text-white xl:hover:text-orange-600">
        <font-awesome-icon :icon="['fas', 'xmark']" class="h-5" />
      </button>
      <h3 class="fugaz uppercase">Generate Sessions List CSV</h3>
      <font-awesome-icon :icon="['fas', 'file-csv']" class="h-[18px] pb-0.5" />
    </div>
    <div class="flex flex-col px-5 pt-2 pb-4">
      <div class="flex items-center gap-2">
        <div>
          <span class="font-thin">From: </span>
          <div class="pl-0.5">{{ clearDate(dateRange.start) }}</div>
        </div>
        <div>
          <span class="font-thin">To: </span>
          <div class="pl-0.5">{{ clearDate(dateRange.end) }}</div>
        </div>
      </div>
      <div v-if="Object.keys(filters).length > 0" class="flex flex-col">
        <span class="font-thin">Filters</span>
        <div class="flex flex-wrap items-end gap-2">
          <div v-for="(value, key) in filters" class="px-2 py-1 rounded-md bg-zinc-700">
            <div v-if="key !== 'selectedGames' && key !== 'selectedIntegrations'" class="capitalize">
              <span class="font-semibold">{{ key }}:</span> {{ value }}
            </div>
            <div v-if="key === 'selectedGames'">
              <div>Selected games:</div>
              <div class="flex flex-wrap gap-1 mt-0.5">
                <span v-for="game in value" class="font-semibold capitalize px-2 py-0.5 rounded-md bg-zinc-800">{{ game.name }}</span>
              </div>
            </div>
            <div v-if="key === 'selectedIntegrations'">
              <div>Selected integrations:</div>
              <div class="flex flex-wrap gap-1 mt-0.5">
                <span v-for="plat in value" class="font-semibold capitalize px-2 py-0.5 rounded-md bg-zinc-800">{{ plat.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <label for="emails" class="font-thin pb-px mt-1.5">
        Emails to send
      </label>
      <form class="flex items-center gap-2" @submit.prevent="addEmail">
        <input type="text" id="emails" v-model="newEmail" class="bg-zinc-900 rounded-md py-1 px-2 outline-none w-full">
        <button class="px-2.5 pb-0.5 pt-1 bg-green-600 xl:hover:bg-green-700 rounded-sm font-semibold fugaz uppercase">Add</button>
      </form>
      <div v-if="emailsToSend.length > 0" class="flex flex-wrap gap-1.5 justify-center mt-3">
        <button v-for="email in emailsToSend" @click="removeEmail(email)" class="px-2.5 py-0.5 bg-zinc-700 rounded-md xl:hover:bg-red-600">
          {{ email }}
        </button>
      </div>
      <div class="flex items-center justify-center mt-3">
        <button 
          @click="getAllSessions(false, true, emailsToSend)"
          :disabled="emailsToSend.length == 0"
          class="px-2.5 pb-0.5 pt-1 bg-zinc-700 rounded-md xl:hover:text-orange-600 fugaz uppercase disabled:pointer-events-none disabled:opacity-40"
        >
          Send Email
        </button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        emailsToSend: [],
        newEmail: ''
      }
    },
    props: {
      getAllSessions: Function,
      filters: Object,
      dateRange: Object,
      closeSessionsCSV: Function,
    },
    computed: {
      appLanguage() {
        return this.$store.getters['getLanguage']
      },
      user() {
        return this.$store.getters["user/user"]
      },
    },
    mounted() {
      if (this.user.email) this.newEmail = this.user.email
    },
    methods: {
      checkValidEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email)
      },
      addEmail() {
        if (!this.newEmail) {
          return
        }
        if(!this.checkValidEmail(this.newEmail)) {
          this.$toast.warning("Please enter a valid email address.");
          return; // Stop execution if email is invalid
        } else {
          if (!this.emailsToSend.includes(this.newEmail)) {
            this.emailsToSend.push(this.newEmail)
            this.newEmail = ''
          } else {
            this.$toast.error('Email already added')
          }
        }
        
      },
      removeEmail(email) {
        this.emailsToSend.splice(this.emailsToSend.indexOf(email), 1)
      },
      clearDate(date) {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        return new Date(date).toLocaleDateString(this.appLanguage.long, options);
      },
    },
  }

</script>
<style scoped>

</style>