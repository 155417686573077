<template>
    <div class="filter__date-range">
        <p>{{ $t("sessions.dateRange") }}</p>
        <DatePicker type="custom" />
    </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";

export default {
    name: 'FilterDateRange',

    components: {DatePicker}
}
</script>

<style lang="postcss" scoped>

.filter__date-range {
    @apply w-[22.5rem] text-zinc-300 whitespace-nowrap text-sm bg-zinc-850 px-2 py-1 rounded-md;
}

</style>