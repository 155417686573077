<template>
    <div class="maintenances">
      <!-- header  -->
      <h2 class="title">
        {{ $t("maintenances.title") }}
      </h2>
      
      <div v-if="ready" class="xl:flex xl:items-start xl:w-full pb-[50px] xl:pb-0">
        <div
          class="flex xl:hidden items-center justify-start gap-2.5 w-full pb-4"
        >
          <button
            @click="selectedView = 'new'"
            class="fugaz uppercase text-lg border-b-2"
            :class="selectedView == 'new' ? 'text-orange-600 border-orange-600' : 'text-white border-transparent'"
          >
            {{ $t("maintenances.new") }}
          </button>
          <button
            @click="selectedView = 'last'"
            class="fugaz uppercase text-lg border-b-2"
            :class="selectedView == 'last' ? 'text-orange-600 border-orange-600' : 'text-white border-transparent'"
          >
            {{ $t("maintenances.lastMaintenances") }}
          </button>
        </div>
        <div
          v-if="screenWidth > 1280 || selectedView == 'new'"
          class="w-full h-full flex flex-col items-center md:items-start gap-4 xl:pr-2 group"
        >
          <h2
            class="hidden xl:block fugaz uppercase text-3xl text-white group-hover:text-orange-600 transition-all duration-500"
          >
            {{ $t("maintenances.new") }}
          </h2>
          <div class="flex flex-col w-full">
            <label for="description" class="text-zinc-300">
              {{ $t("maintenances.description") }}
            </label>
            <!-- <input name="description" type="text" v-model="description" class="w-80 ml-2 outline-none rounded-sm"> -->
            <textarea
              name="description"
              rows="5"
              cols="33"
              maxlength="255"
              v-model="description"
              class="outline-none rounded-md px-1 text-white bg-zinc-900"
              style="color-scheme: dark"
            ></textarea>
          </div>
          <div
            class="flex items-center md:items-start flex-col md:flex-row gap-4 w-full"
          >
            <div class="flex flex-col z-[30] w-max gap-2">
              <div class="flex justify-between">
                <span class="text-zinc-300 whitespace-nowrap text-base"
                  >{{ $t("maintenances.date") }}
                </span>
              </div>

              <DatePicker type="daily" :inline="true" :minDate="minDate" />

              <!-- <VDatePicker v-model="date" mode="dateTime" is-dark is24hr color="pink" :min-date="minDate" :locale="appLanguage.long"/> -->
            </div>
            <div class="flex flex-col gap-2 w-full">
              <label for="severity" class="text-zinc-300">{{
                $t("maintenances.severity")
              }}</label>
              <div class="w-full">
                <Multiselect
                  mode="single"
                  v-model="severity"
                  :options="severityLevels"
                  :close-on-select="true"
                  label="name"
                  value-prop="value"
                  :searchable="true"
                  :placeholder="$t('maintenances.selectSeverity')"
                  class="bg-zinc-800"
                  ref="multiselectSeverity"
                />
              </div>
              <label for="integrations" class="text-zinc-300">{{
                $t("maintenances.integrationsToNotify")
              }}</label>
              <div class="w-full">
                <Multiselect
                  mode="multiple"
                  v-model="selectedIntegrations"
                  :options="integrations"
                  label="name"
                  value-prop="id"
                  :placeholder="$t('maintenances.filterByIntegration')"
                  :hide-selected="false"
                  :caret="false"
                  :close-on-select="false"
                  :searchable="true"
                  :multiple-label="
                    (values) => `${values.length} Integrations selected`
                  "
                  ref="multiselectPlat"
                />
              </div>
              <div class="flex justify-center mt-4 mb-6 md:mb-0">
                <button
                  v-if="!loading"
                  @click="submit"
                  type="submit"
                  class="px-3 pt-1 pb-[3px] text-white xl:hover:text-orange-600 bg-zinc-700 xl:hover:bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
                >
                  {{ $t("maintenances.submit") }}
                </button>
                <img
                  v-else
                  src="../../assets/loading2.png"
                  alt="loading icon"
                  class="text-zinc-600 dark:text-zinc-400 h-6 animate-spin"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="screenWidth > 1280 || selectedView == 'last'"
          class="w-full h-full flex flex-col gap-4 xl:pl-2 pb-6 xl:pb-0 group"
        >
          <h2
            class="hidden xl:block fugaz uppercase text-3xl text-white group-hover:text-orange-600 transition-all duration-500"
          >
            {{ $t("maintenances.lastMaintenances") }}
          </h2>
          <div
            class="flex flex-col gap-2 xl:max-h-[80vh] xl:overflow-y-auto xl:pr-1.5"
          >
            <div
              v-for="maintenance in lastMaintenances"
              class="pb-2 pt-1 px-2 bg-zinc-900 rounded-sm text-white relative"
            >
              <div class="flex items-center justify-between pt-0.5">
                <span class="fugaz uppercase text-sm">{{
                  clearDate(maintenance.date)
                }}</span>
                <div class="">
                  {{ $t("maintenances.severity") }}
                  <span
                    v-if="maintenance.severity === 1"
                    class="px-3 py-0.5 rounded-sm ml-1 bg-green-600"
                  >
                    {{ $t("maintenances.low") }}
                  </span>
                  <span
                    v-if="maintenance.severity === 2"
                    class="px-3 py-0.5 rounded-sm ml-1 bg-orange-400"
                  >
                    {{ $t("maintenances.medium") }}
                  </span>
                  <span
                    v-if="maintenance.severity === 3"
                    class="px-3 py-0.5 rounded-sm ml-1 bg-red-500"
                  >
                    {{ $t("maintenances.high") }}
                  </span>
                  <span
                    v-if="maintenance.severity === 4"
                    class="px-3 py-0.5 rounded-sm ml-1 bg-red-700"
                  >
                    {{ $t("maintenances.critical") }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col">
                <span class="font-thin">{{
                  $t("maintenances.description")
                }}</span>
                <p class="text-white px-0.5">{{ maintenance.description }}</p>
              </div>
              <div
                v-if="maintenance.integrations.length > 0"
                class="flex flex-col gap-1 mt-1"
              >
                <div class="flex items-center gap-1">
                  <h6 class="font-thin">
                    {{ $t("maintenances.confirmation") }} -
                  </h6>
                  <div
                    v-if="
                      maintenance.integrations.every(
                        (integration) =>
                          integration.maintenance_integrations.confirmed === true
                      )
                    "
                    class="px-2 text-sm cursor-default rounded-sm bg-green-600 font-semibold"
                  >
                    {{ $t("maintenances.confirmed") }}
                  </div>
                  <div
                    v-else-if="
                      maintenance.integrations.some(
                        (integration) =>
                          integration.maintenance_integrations.confirmed === false
                      )
                    "
                    class="px-2 text-sm cursor-default rounded-sm bg-red-500 font-semibold"
                  >
                    {{ $t("maintenances.denied") }}
                  </div>
                  <div
                    v-else
                    class="px-2 text-sm cursor-default rounded-sm bg-zinc-600 font-semibold"
                  >
                    {{ $t("maintenances.pending") }}
                  </div>
                </div>
                <div class="flex flex-wrap items-center gap-2">
                  <div v-for="integration in maintenance.integrations">
                    <div class="flex items-center gap-1.5 rounded-sm px-1">
                      <span class="cursor-default">{{ integration.name }}</span>
                      <div class="flex items-center gap-1">
                        <font-awesome-icon
                          v-if="
                            integration.maintenance_integrations.confirmed === true
                          "
                          :icon="['fas', 'circle-check']"
                          class="text-green-500"
                        />
                        <font-awesome-icon
                          v-if="
                            integration.maintenance_integrations.confirmed === false
                          "
                          :icon="['fas', 'circle-xmark']"
                          class="text-red-500"
                        />
                        <font-awesome-icon
                          v-if="
                            integration.maintenance_integrations.confirmed === null
                          "
                          :icon="['fas', 'circle-minus']"
                          class="text-zinc-300"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="w-full h-[90vh] flex justify-center items-center gap-3 text-zinc-400 bg-zinc-950">
        <img
          v-if="!serverError"
          src="../../assets/hub-full-logo.png"
          alt="gai hub logo"
          class="h-24 w-auto animate-pulse"
        />
        <div v-else>
          {{ $t("serverError") }}
          <button @click="$router.go()" class="text-blue-600">
            {{ $t("reload") }}
          </button>
          {{ $t("tryAgain") }}
        </div>
      </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import request from "../../services/axios";
import DatePicker from "@/components/DatePicker";

export default {
  name: "NewMaintenance",
  data() {
    return {
      ready: false,
      serverError: false,
      description: "",
      severity: null,
      originalIntegrations: [],
      integrations: [],
      selectedIntegrations: [],
      lastMaintenances: [],
      severityLevels: [
        {
          name: "Low",
          value: 1,
        },
        {
          name: "Medium",
          value: 2,
        },
        {
          name: "High",
          value: 3,
        },
        {
          name: "Critical",
          value: 4,
        },
      ],
      loading: false,
      minDate: this.getMinDate(),
      opened: [],
      selectedView: "new",
      mountTimer: null,
    };
  },
  components: {
    Multiselect,
    DatePicker,
  },
  async mounted() {
    this.mountTimer = setTimeout(async () => {
      try {
        this.ready = false;
        await this.getIntegrations();
        await this.getLastMaintenances();
        this.ready = true;
      } catch (error) {
        console.log(error);
        this.ready = true;
      }
    }, 500);
  },
  beforeUnmount() {
    clearTimeout(this.mountTimer);
  },
  watch: {
    severity() {
      this.$refs.multiselectSeverity.blur();
    },
    selectedIntegrations() {
      this.$refs.multiselectPlat.blur();
    },
  },
  computed: {
    date() {
      return this.$store.state.filters.date;
    },
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    screenWidth() {
      return this.$store.getters["getScreenWidth"];
    },
  },
  methods: {
    clearDate(date) {
      const options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(date).toLocaleDateString("en-GB", options);
    },
    getMinDate() {
      const today = new Date();
      const maxDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 14
      );
      return maxDate;
    },
    selectAll(e) {
      e.preventDefault();
      for (const integration of this.integrations) {
        this.selectedIntegrations.push(integration);
      }
      this.integrations = [];
    },
    unselectAll(e) {
      e.preventDefault();
      this.selectedIntegrations = [];
      this.integrations = JSON.parse(JSON.stringify(this.originalIntegrations));
    },
    selectIntegration() {
      const selectedIntegration = this.integrations.find(
        (p) => p.name === this.selectedIntegration
      );
      this.selectedIntegrations.push(selectedIntegration);
      const index = this.integrations
        .map((p) => p.name)
        .indexOf(this.selectedIntegration);
      this.integrations.splice(index, 1);
      this.selectedIntegration = "default";
    },
    unselectIntegration(integrationName) {
      const originalIntegration = this.originalIntegrations.find(
        (integration) => integration.name === integrationName
      );
      this.integrations.push(originalIntegration);
      this.integrations.sort((a, b) => a.name.localeCompare(b.name));
      const index = this.selectedIntegrations
        .map((p) => p.name)
        .indexOf(integrationName);
      this.selectedIntegrations.splice(index, 1);
    },
    async getIntegrations() {
      const response = await request.get("/integrations?with=users&active=1");
      const integrations = response.data.rows.map((integration) => {
        return {
          name: integration.name,
          id: integration.id,
          disabled: integration.users.length === 0,
        };
      });
      this.integrations = integrations;
      this.integrations.sort((a, b) => a.name.localeCompare(b.name));
      this.originalIntegrations = JSON.parse(JSON.stringify(integrations));
    },
    openMaintenance(id) {
      if (!this.opened.includes(id)) {
        this.opened.push(id);
      } else {
        const index = this.opened.indexOf(id);
        this.opened.splice(index, 1);
      }
    },
    async getLastMaintenances() {
      try {
        const maintenances = (await request.get("/maintenances")).data;
        this.lastMaintenances = maintenances;
      } catch (e) {
        this.$toast.error("Something went wrong");
        console.log(e);
      }
    },
    checkErrors() {
      let errors = [];
      if (this.description.length === 0) {
        errors.push("Description is required");
      }
      if (this.date === "") {
        errors.push("Date is required");
      }
      if (this.severity === null) {
        errors.push("Severity is required");
      }
      if (this.selectedIntegrations.length === 0) {
        errors.push("At least one integration is required");
      }
      if (errors.length > 0) {
        for (const error of errors) {
          this.$toast.error(error);
        }
        return true;
      } else return false;
    },
    async submit() {
      if (this.checkErrors()) {
        return;
      } else {
        try {
          this.loading = true;
          await request.post(
            "/maintenances/create",
            {
              args: {
                description: this.description,
                date: this.date,
                severity: this.severity,
              },
              integrations: this.selectedIntegrations,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          this.$toast.success("Maintenance create", {
            position: "bottom-right",
            autoClose: 3000,
          });
          this.loading = false;
          this.description = "";
          this.date = "";
          this.severity = null;
          this.integrations = this.originalIntegrations;
          this.selectedIntegrations = [];
          this.getLastMaintenances();
        } catch (e) {
          this.$toast.error("Something went wrong");
          console.log(e);
        }
      }
    },
  },
};
</script>

<style>
</style>