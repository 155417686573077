import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import mitt from "mitt";
import io from "socket.io-client";
import { createApp } from "vue";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-bootstrap.css";
import App from "./App.vue";
import "./assets/tailwind.css";
import { keycloak, keycloakConfig } from "./keycloak.config.js";
import { router } from "./router";
import store from "./store";
import library from "./config/icons"; // this has to be here
import VCalendar from "v-calendar";
import CKEditor from "@ckeditor/ckeditor5-vue";
import { createI18n } from "vue-i18n";

keycloak
  .init({ onLoad: keycloakConfig.onLoad })
  .then(async (auth) => {
    // keycloak.onAuthSuccess(response => {console.Console.log(response)})

    if (!auth) {
      alert("Something went wrong, please try again");
      keycloak.logout();
    } else {
      // console.info("Authenticated");
      // console.log('process.env.NODE_ENV', process.env.NODE_ENV)
      // console.log('process.env.VUE_APP_KEYCLOAK_CLIENTID', process.env.VUE_APP_KEYCLOAK_CLIENTID)
    }

    // Fetch user profile
    await keycloak
      .loadUserProfile()
      .then(async (profile) => {
        // console.log(profile);
        // Access user information
        const userId = profile.id;
        const username = profile.username;
        const email = profile.email;
        const attributes = profile.attributes;
        const roles =
          keycloak.tokenParsed.resource_access[
            process.env.VUE_APP_KEYCLOAK_CLIENTID
          ].roles;
        const token = keycloak.token;
        // console.log(token);

        // Dispatch Vuex action to store user information
        store.dispatch("user/setUserAction", {
          userId,
          username,
          email,
          roles,
          attributes,
          token,
        });
        // await store.dispatch("user/setTokenAction");
        // store.dispatch('user/setNotificationsAction')

        // console.log('app is mounted');
        // console.log(keycloak.token);
        localStorage.setItem("vue-token", keycloak.token);
        localStorage.setItem("vue-refresh-token", keycloak.refreshToken);

        setInterval(() => {
          keycloak
            .updateToken(70)
            .then((refreshed) => {
              if (refreshed) {
                console.info("Token refreshed" + refreshed);
                store.dispatch("user/updateToken", keycloak.token);
              } else {
                console.warn(
                  "Token not refreshed, valid for " +
                    Math.round(
                      keycloak.tokenParsed.exp +
                        keycloak.timeSkew -
                        new Date().getTime() / 1000
                    ) +
                    " seconds"
                );
              }
            })
            .catch(() => {
              console.error("Failed to refresh token");
            });
        }, 60000);
      })
      .catch((e) => {
        console.error("Failed to load user profile");
        console.error(e);
        keycloak.logout();
      });

    // vue stuff
    const emitter = mitt();
    const app = createApp(App);
    app.config.globalProperties.getCurrencyValue = (value) => {
      return new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "code", // Use the full name of the currency
      }).format(value);
    };

    app.component("font-awesome-icon", FontAwesomeIcon);
    app.use(router);
    app.use(store);
    app.use(ToastPlugin);
    // console.log(roles);
    // console.log(keycloak.tokenParsed.integration);
    app.config.globalProperties.emitter = emitter;

    const socket = io(process.env.VUE_APP_BACKEND_ENDPOINT);
    socket.on("connect", () => {
    //  console.log("Connected to WebSocket server.");
    });
    socket.on("disconnect", () => {
    //  console.log("Disconnected from WebSocket server.");
    });
    app.config.globalProperties.socket = socket;
    app.config.devtools = process.env.NODE_ENV !== "production";
    // console.log('main.js booting up');
    // console.log('node env');
    // console.log(process.env.NODE_ENV);
    // console.log('devtools');
    // console.log(app.config.devtools);

    app.use(VCalendar);
    app.use(CKEditor);

    const i18n = createI18n({
      // locale: 'en', // default locale
      locale: store.getters["getLanguage"].short
        ? store.getters["getLanguage"].short
        : "en",
      fallbackLocale: "en", // fallback locale
      messages: {
        en: require("./locales/en.json"), // English translations
        it: require("./locales/it.json"), // Italian translations
        // Add more languages as needed
      },
    });

    app.use(i18n);

    app.mount("#app");
  })
  .catch((e) => {
    console.log(e);
    console.error("Authenticated Failed");
  });
