<template>
  <div class="relative text-white flex flex-col gap-3 pt-5 w-full">
    <!-- <div class="flex flex-col gap-2 bg-zinc-800 rounded-sm p-3">
      <div class="flex items-center text-xl">
        Basic info:
        <button @click="editingBasicInfo = !editingBasicInfo" class="pl-1.5 xl:hover:scale-105 text-green-500">
          <font-awesome-icon :icon="['fas', 'pen-to-square']" />
        </button>
      </div>
      <div class="flex items-center gap-x-2">
        <div v-if="!editingBasicInfo" class="bg-zinc-700 px-3 py-1 rounded-sm">
          <h3 class="pr-2">Name:</h3>
          <h2 class="text-lg font-semibold">{{ integration.name }}</h2>
        </div>
        <div v-else class="bg-zinc-700 px-3 py-1 rounded-sm">
          <h3 class="pr-2">Name:</h3>
          <div class="flex items-center gap-2">
            <input type="text" v-model="newName" class="bg-zinc-900 text-white text-lg outline-none">
            <button @click="updateIntegration" class="bg-zinc-500 xl:hover:bg-opacity-50 px-3 rounded-sm text-white">
              Save
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="flex flex-col gap-2 bg-zinc-900 rounded-sm p-3">
      <div class="flex items-center text-xl fugaz uppercase pl-2">Services</div>
      <div class="flex items-start justify-center flex-wrap gap-2">
        <IntegrationService
          :integration="integration"
          :serviceName="'gaming'"
          :originalFields="gamingServiceFields"
        />
        <IntegrationService
          :integration="integration"
          :serviceName="'data'"
          :originalFields="dataServiceFields"
        />
        <IntegrationService
          :integration="integration"
          :serviceName="'play'"
          :originalFields="playServiceFields"
        />
        <IntegrationService
          :integration="integration"
          :serviceName="'launch'"
          :originalFields="launchServiceFields"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationService from "./IntegrationService.vue";

export default {
  name: "IntegrationInfo",
  data() {
    return {
      newName: this.integration.name,
      editingBasicInfo: false,
      gamingServiceFields: {
        url: this.integration.gamingServiceURL,
        token: this.integration.gamingServiceToken,
      },
      dataServiceFields: {
        url: this.integration.dataServiceURL,
        token: this.integration.dataServiceToken,
      },
      playServiceFields: {
        url: this.integration.playServiceURL,
        token: this.integration.playServiceToken,
        bookkeepingToken: this.integration.bookkeepingToken,
        operatorToken: this.integration.operatorToken,
        maintenanceToken: this.integration.maintenanceToken,
      },
      launchServiceFields: {
        url: this.integration.launchServiceURL ?? '',
        token: this.integration.launchServiceToken ?? '',
      },
    };
  },
  props: {
    integration: Object,
    updateIntegration: Function,
  },
  components: {
    IntegrationService,
  },
  created() {},
  methods: {

  },
};
</script>

<style></style>
